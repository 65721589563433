import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { client } from 'graphql/client';
import {
  UPLOAD_EHS_DOCUMENT_MUTATION,
  UploadEhsDocumentResponse,
  UploadEhsDocumentVariables,
} from 'graphql/mutation/ehs';
import { GET_EHS_CHECKLIST_BY_ID } from 'graphql/query/ehs';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EHSDocument } from 'types';

import Metadata from 'components/Inputs/Metadata';
import UploadFile from 'components/Inputs/UploadFile';

const UploadEhsDocumentForm: React.FC<{
  ehsDocument: EHSDocument;
  onCancel: () => void;
}> = ({ ehsDocument, onCancel }) => {
  const [formState, setFormState] = useState<Record<string, any>>({});
  const { ehsChecklistId = '' } = useParams<{ ehsChecklistId: string }>();

  const [uploadEhsDocument, { loading: uploadingEhsDocument }] = useMutation<
    UploadEhsDocumentResponse,
    UploadEhsDocumentVariables
  >(UPLOAD_EHS_DOCUMENT_MUTATION);

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    const metadata = {};
    (formState.metadata ?? []).forEach(d => {
      if (!!d.key) {
        metadata[d.key] = d.value;
      }
    });

    uploadEhsDocument({
      variables: {
        input: {
          _id: ehsDocument._id,
          file: formState.file,
          metadata: JSON.stringify(metadata),
        },
      },
      onCompleted: res => {
        if (!res.uploadEhsDocument.success) {
          toast.error(res.uploadEhsDocument.message);
        } else {
          deletePendingDocumentCache(ehsChecklistId, res.uploadEhsDocument.document._id);
        }
      },
    });
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columnSpacing={1.5} rowGap={2}>
        <Grid item xs={12}>
          <UploadFile
            buttonProps={{
              size: 'small',
              sx: {
                fontSize: 10,
              },
            }}
            values={formState.file ?? ''}
            onChange={val => handleChange('file', val)}
            label="Select File *"
            required
          />
        </Grid>
        <Grid item xs={12} mt={-1}>
          <Metadata
            metadata={formState.metadata ?? [{ key: ``, value: `` }]}
            setMetadata={md =>
              setFormState(prev => ({
                ...prev,
                metadata: md,
              }))
            }
          />
        </Grid>

        <Grid item xs={12} container columnGap={1}>
          <Button
            variant="text"
            size="small"
            sx={{
              fontSize: 10,
              fontWeight: 600,
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={uploadingEhsDocument}
            type="submit"
            size="small"
            sx={{
              fontSize: 10,
              fontWeight: 600,
            }}
          >
            Upload
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export const deletePendingDocumentCache = (ehsChecklistId: string, recordId: string) => {
  const oldData = client.readQuery({
    query: GET_EHS_CHECKLIST_BY_ID,
    variables: {
      id: ehsChecklistId,
    },
  }) ?? { getEhsChecklistDetailsById: {} };

  client.writeQuery({
    query: GET_EHS_CHECKLIST_BY_ID,
    variables: {
      id: ehsChecklistId,
    },
    data: {
      getEhsChecklistDetailsById: {
        ...oldData,
        pendingDocuments: oldData.getEhsChecklistDetailsById.pendingDocuments.filter(
          pd => pd._id !== recordId
        ),
      },
    },
  });
};

export default UploadEhsDocumentForm;

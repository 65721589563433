import { DownloadRounded, PublishRounded } from '@mui/icons-material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Button, Grid, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DocStatusEnum,
  EHSChecklist,
  EHSIncidentBodyPartAffected,
  EHSIncidentReportedTo,
  RootCause,
} from 'types';

import Section, { SectionDataProps } from 'components/Section';

import { getEnumKeyFromValue } from 'utils/transformFn';

const INCIDENT_TEMPLATE_URL =
  'https://d2e15iehwvdm1i.cloudfront.net/templates/EHS%20Incident%20Report.docx';
const INVESTIGATION_TEMPLATE_URL =
  'https://d2e15iehwvdm1i.cloudfront.net/templates/EHS+Investigation+Report.csv';

const EHSChecklistIncidentSection: React.FC<{ ehsChecklist: EHSChecklist }> = ({
  ehsChecklist,
}) => {
  const navigate = useNavigate();
  const isIncidentReport = ehsChecklist.incidentReports !== null;

  const sectionData: SectionDataProps[] = useMemo(() => {
    const investigationR = ehsChecklist.investigationReports ?? {};
    const incidentR = ehsChecklist.incidentReports ?? {};

    const data: SectionDataProps[] = [
      {
        label: 'ReferenceNo',
        value: isIncidentReport ? incidentR.referenceNo : investigationR.referenceNo,
      },
      {
        label: 'Location',
        value: isIncidentReport ? incidentR.location : investigationR.location,
      },
      {
        label: 'IncidentDate',
        value: isIncidentReport ? incidentR.incidentDate : investigationR.incidentDate,
        type: 'DATE',
      },
      {
        label: 'IncidentTime',
        value: isIncidentReport ? incidentR.incidentTime : investigationR.incidentTime,
        type: 'TIME',
      },
    ];

    if (isIncidentReport) {
      data.push(
        {
          label: 'Incident Name',
          value: incidentR.incidentHeading,
        },
        {
          label: 'Incident Type',
          value: incidentR.incidentType,
        },

        {
          label: 'Name',
          value: incidentR.name,
        },
        {
          label: 'ID Number',
          value: incidentR.idNumber,
        },
        {
          label: 'Contractor / Agency/ Organization',
          value: incidentR.organizationName,
        },
        {
          label: 'Age',
          value: incidentR.age + ' Yrs',
        },
        {
          label: 'Gender',
          value: incidentR.gender,
        },
        {
          label: 'Address',
          value: incidentR.address,
        },
        {
          label: 'Date of Return from Hospital',
          value: incidentR.returnFromHospitalDate,
          type: 'DATE',
        },
        {
          label: 'Time of Return from Hospital',
          value: incidentR.returnFromHospitalTime,
          type: 'TIME',
        },
        {
          label: 'Work Started Time',
          value: incidentR.workStartedTime,
          type: 'TIME',
        },
        {
          label: 'Date of Return to Work',
          value: incidentR.returnToWorkDate,
          type: 'DATE',
        },
        {
          label: 'Incident Details',
          value: incidentR.incidentDetails,
        },
        {
          label: 'Immediate Actions',
          value: incidentR.immediateActions,
        },
        {
          label: 'Was wearing PPE?',
          value: incidentR.isWearingPPE,
          type: 'BOOLEAN',
        },
        {
          label: 'Was PPE being in condition?',
          value: incidentR.isPpeCondition,
          type: 'BOOLEAN',
        },
        {
          label: 'Was it an Unsafe act?',
          value: incidentR.isUnsafeAct,
          type: 'BOOLEAN',
        }
      );
    } else {
      data.push(
        {
          label: 'PDS Role',
          value: investigationR.pdsRole,
        },
        {
          label: 'Person(s) involved',
          value: investigationR.namesOfPersonsInvolved?.join(', '),
        },
        {
          label: 'Names of Witnesses',
          value: investigationR.nameOfWitnesses?.join(', '),
        },
        {
          label: 'Incident Type',
          value: investigationR.incidentType,
        },
        {
          label: 'Body Part Affected',
          value: (investigationR.bodyPartAffected ?? [])
            .map(b => getEnumKeyFromValue(EHSIncidentBodyPartAffected, b))
            .join(', '),
        },
        {
          label: 'Reported To',
          value: getEnumKeyFromValue(EHSIncidentReportedTo, investigationR.reportedTo),
        },
        {
          label: 'Observation / Initial Cause',
          value: investigationR.observationOrInitialCause,
        },
        {
          label: 'Immediate Action',
          value: investigationR.immediateAction,
        },
        {
          label: 'Background Information',
          value: investigationR.backgroundInformation,
        },
        {
          label: 'Root Cause',
          value: getEnumKeyFromValue(RootCause, investigationR.rootCause),
        },
        {
          label: 'Follow Up',
          value: investigationR.followUp,
        },
        {
          label: 'Reviewed by Local HSE Advisor',
          value: investigationR.reviewedBy?.localHSEAdvisor,
          type: 'BOOLEAN',
        },
        {
          label: 'Reviewed by National / Regional Safety Manager',
          value: investigationR.reviewedBy?.nationalSafetyManager,
          type: 'BOOLEAN',
        },
        {
          label: 'Reviewed by Location Manager',
          value: investigationR.reviewedBy?.locationManager,
          type: 'BOOLEAN',
        },
        {
          label: 'Safety / Environmental Alert',
          value: investigationR.reviewedBy?.safetyAlertReleased,
          type: 'BOOLEAN',
        },
        {
          label: 'Amendment to PDS HSE System required',
          value: investigationR.reviewedBy?.amendmentToHSESystem,
          type: 'BOOLEAN',
        }
      );
    }
    return data;
  }, [ehsChecklist]);

  if (ehsChecklist.incidentReports === null && ehsChecklist.investigationReports === null) {
    return (
      <Grid item container direction="column" rowGap={1.5}>
        <Grid item container columnGap={1.5} rowGap={1.5}>
          <Grid container columnGap={1} alignItems="center">
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              startIcon={<PublishRounded />}
              onClick={() =>
                navigate(`upload-report?type=incident&projectName=${ehsChecklist.project.name}`)
              }
            >
              Upload Incident Report
            </Button>
            <Tooltip title="Download Incident Template">
              <DownloadRounded
                color="secondary"
                sx={{ cursor: 'pointer' }}
                onClick={() => window.open(INCIDENT_TEMPLATE_URL, '_blank')}
              />
            </Tooltip>
          </Grid>
          <Grid container columnGap={1} alignItems="center">
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              startIcon={<PublishRounded />}
              onClick={() =>
                navigate(
                  `upload-report?type=investigation&projectName=${ehsChecklist.project.name}`
                )
              }
            >
              Upload Investigation Report
            </Button>
            <Tooltip title="Download Investigation Template">
              <DownloadRounded
                color="secondary"
                sx={{ cursor: 'pointer' }}
                onClick={() => window.open(INVESTIGATION_TEMPLATE_URL, '_blank')}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Section
      title="Report Details"
      collapsible
      data={sectionData}
      action={
        <EditNoteIcon
          sx={{ cursor: 'pointer' }}
          onClick={() =>
            navigate(
              `edit-report?type=${isIncidentReport ? 'incident' : 'investigation'}&projectName=${
                ehsChecklist.project.name
              }`
            )
          }
        />
      }
    />
  );
};

export default EHSChecklistIncidentSection;

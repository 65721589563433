import { Link } from 'react-router-dom';
import { COMPLIANCE_DOC_NAMES, ComplianceCurrentStatusEnum } from 'types';

import DataGridTable from 'components/Table';

import { getEnumKeyFromValue } from 'utils/transformFn';

import theme from './theme.module.scss';

const OneTimeComplianceChecklistTable: React.FC<{
  oneTimeComplianceDocs: any[];
  loading: boolean;
}> = ({ oneTimeComplianceDocs, loading }) => {
  return (
    <DataGridTable
      sx={{
        maxWidth: '60vw',
        height: '450px',
        minHeight: '400px',
        mx: 'auto',
        mt: 0.5,
      }}
      loading={loading}
      columns={[
        {
          field: 'referenceId',
          headerName: 'Reference Id',
          minWidth: 200,
          renderCell: params =>
            params.row.currentStatus === ComplianceCurrentStatusEnum.CREATED ? (
              params.value
            ) : (
              <Link to={params.row._id}>{params.value}</Link>
            ),
        },
        {
          field: 'docType',
          headerName: 'Doc Type',
          minWidth: 200,
        },
        {
          field: 'docName',
          headerName: 'Doc Name',
          minWidth: 250,
          renderCell: params => getEnumKeyFromValue(COMPLIANCE_DOC_NAMES, params.value),
        },
        {
          field: 'currentStatus',
          headerName: 'Status',
          minWidth: 200,
          renderCell: params => getEnumKeyFromValue(ComplianceCurrentStatusEnum, params.value),
        },
      ]}
      getRowId={row => row._id}
      rows={oneTimeComplianceDocs}
      hideFooterPagination
      getRowClassName={params => {
        switch (params.row.currentStatus) {
          case ComplianceCurrentStatusEnum.CREATED:
            return theme['row-created'];
          case ComplianceCurrentStatusEnum.STARTED:
            return theme['row-started'];
          case ComplianceCurrentStatusEnum.APPLIED:
            return theme['row-applied'];
          case ComplianceCurrentStatusEnum.COMPLETED:
            return theme['row-completed'];
          default:
            return theme['row-default'];
        }
      }}
    />
  );
};

export default OneTimeComplianceChecklistTable;

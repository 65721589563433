import { useLazyQuery, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, MenuItem, Select, TextField } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {
  CREATE_EHS_CHECKLIST_MUTATION,
  CreateEHSChecklistMutationResponse,
  CreateEHSChecklistMutationVariables,
} from 'graphql/mutation/ehs';
import {
  USERS_NAMES_QUERY,
  UserNamesQueryResponse,
  UserNamesQueryVariables,
} from 'graphql/query/area';
import {
  GET_FOLDERS_QUERY_FOR_AC,
  GetFoldersQueryResponse,
  GetFoldersQueryVariables,
} from 'graphql/query/folder';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AutoCompleteOption,
  DepartmentEnum,
  EHSChecklist,
  Folder,
  FolderTypeEnum,
  FrequencyEnum,
} from 'types';

import AutocompleteWithFetch, {
  MultiSelectAutocomplete,
} from 'components/FormPanel/AutoCompleteWithFetch';
import { FolderNameEnum } from 'components/PQs/Sections/FolderSection';
import DataGridTable from 'components/Table';

const StandardEHSDocumentsListTable: React.FC<{
  initialRows: {
    _id: string;
    docName: string;
    docType: Folder;
    frequency: FrequencyEnum;
  }[];
  cb: (arg: EHSChecklist[]) => void;
}> = ({ initialRows, cb }) => {
  const [rows, setRows] = useState(initialRows);
  const { projectId = '' } = useParams<{ projectId: string }>();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedRowsMap, setSelectedRowsMap] = useState<Record<string, boolean>>({});
  const [formState, setFormState] = useState<Record<string, any>>(
    rows.reduce((prev, curr) => {
      prev[curr._id] = curr;
      return prev;
    }, {})
  );

  const INCIDENT_REPORT = 'Incident Report';

  const [getUsers, { data: users }] = useLazyQuery<UserNamesQueryResponse, UserNamesQueryVariables>(
    USERS_NAMES_QUERY,
    {
      variables: {
        filter: {
          department: [DepartmentEnum.EHS],
        },
      },
    }
  );

  const [createEHSChecklist, { loading: creatingEHSChecklist }] = useMutation<
    CreateEHSChecklistMutationResponse,
    CreateEHSChecklistMutationVariables
  >(CREATE_EHS_CHECKLIST_MUTATION);

  const [getEHSFolders, { data: ehsFolders }] = useLazyQuery<
    GetFoldersQueryResponse,
    GetFoldersQueryVariables
  >(GET_FOLDERS_QUERY_FOR_AC, {
    variables: {
      type: FolderTypeEnum.PROJECT,
      folderName: FolderNameEnum['SITE EHS'],
      projectOrLeadId: projectId,
    },
  });

  const handleChange = (rowId: string, fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [rowId]: {
        ...(prev[rowId] ?? {}),
        [fieldName]: value,
      },
    }));
  };

  const handleSubmit = () => {
    const newErrors: Record<string, string> = {};
    selectedRows.forEach(rowId => {
      if (!formState[rowId]?.docName) {
        newErrors[rowId] = 'Doc Name is required';
      }
    });

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    const selectedData = selectedRows.map(id => {
      const data = formState[id];
      delete data.__typename;
      delete data._id;

      return {
        ...data,
        assignTo: data.assignTo.map((o: AutoCompleteOption) => o._id),
        docType: data.docType._id,
        project: projectId,
        scheduleEnds:
          data.frequency === FrequencyEnum['ONE TIME'] ? data.scheduleStarts : data.scheduleEnds,
      };
    });

    createEHSChecklist({
      variables: {
        input: selectedData,
      },
      onCompleted: res => cb(res.createEhsChecklist),
    });
  };

  const handleProcessRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    setFormState(prev => ({
      ...prev,
      [newRow._id]: {
        ...prev[newRow._id],
        docName: newRow.docName,
      },
    }));

    return newRow;
  };

  const handleFrequencyChange = (rowId: string, value: string) => {
    handleChange(rowId, 'frequency', value);
    if (value === FrequencyEnum['ONE TIME'] || value === FrequencyEnum.DAILY) {
      handleChange(rowId, 'scheduleStarts', null);
      handleChange(rowId, 'scheduleEnds', null);
    }
  };

  const handleAddRow = () => {
    const newRow = {
      _id: dayjs().unix(),
      docName: '',
      docType: { _id: '', name: '' },
      isStandardRow: true,
    };
    // @ts-ignore
    setRows([...rows, newRow]);
    setFormState({ ...formState, [newRow._id]: newRow });
  };

  const handleDocTypeChange = (rowId: string, val: AutoCompleteOption | null) => {
    if (val?.name === INCIDENT_REPORT) {
      setFormState(prev => ({
        ...prev,
        [rowId]: {
          ...(prev[rowId] ?? {}),
          docType: val,
          frequency: FrequencyEnum['ONE TIME'],
        },
      }));
    } else {
      handleChange(rowId, 'docType', val);
    }
  };

  return (
    <form
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <DataGridTable
        sx={{
          maxWidth: '80vw',
          mx: 'auto',
          mt: 0.5,
        }}
        checkboxSelection
        columns={[
          {
            field: 'docType',
            headerName: 'Sub Folder *',
            minWidth: 250,
            renderCell: params => (
              <Box
                sx={{
                  height: 50,
                  my: 'auto',
                  pt: 2,
                }}
              >
                <AutocompleteWithFetch
                  fetch={getEHSFolders}
                  handleChange={val => handleDocTypeChange(params.row._id, val)}
                  label=""
                  loading={false}
                  value={formState[params.row._id]?.docType}
                  options={ehsFolders?.getFolders?.folders ?? [formState[params.row._id]?.docType]}
                  required
                  variant="standard"
                  size="small"
                  disabled={!selectedRowsMap[params.row._id]}
                />
              </Box>
            ),
          },
          {
            field: 'docName',
            headerName: 'Doc Name *',
            minWidth: 200,
            editable: true,
            disableColumnMenu: true,
            disableReorder: true,
            renderCell: params => (
              <TextField
                value={formState[params.row._id]?.docName ?? ''}
                onChange={e => handleChange(params.row._id, 'docName', e.target.value)}
                size="small"
                sx={{ width: '100%', mt: 1.2 }}
                required={!!selectedRowsMap[params.row._id]}
              />
            ),
          },
          {
            field: 'frequency',
            headerName: 'Frequency *',
            minWidth: 150,
            disableColumnMenu: true,
            disableReorder: true,
            sortable: false,
            renderCell: params => (
              <Select
                size="small"
                label="Frequency"
                variant="standard"
                disabled={
                  !!!selectedRowsMap[params.row._id] ||
                  formState[params.row._id]?.docType?.name === INCIDENT_REPORT
                }
                required={!!selectedRowsMap[params.row._id]}
                sx={{ my: 'auto', width: '100%' }}
                value={formState[params.row._id]?.frequency ?? ''}
                onChange={e => handleFrequencyChange(params.row._id, e.target.value)}
              >
                {Object.keys(FrequencyEnum).map(k => (
                  <MenuItem key={k} value={FrequencyEnum[k]}>
                    {k}
                  </MenuItem>
                ))}
              </Select>
            ),
          },
          {
            field: 'scheduleStarts',
            headerName: 'Start Date *',
            minWidth: 165,
            disableColumnMenu: true,
            disableReorder: true,
            sortable: false,
            renderCell: params => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={
                    !!formState[params.row._id]?.scheduleStarts
                      ? dayjs(formState[params.row._id]?.scheduleStarts)
                      : null
                  }
                  format="DD-MM-YYYY"
                  onChange={newValue =>
                    handleChange(
                      params.row._id,
                      'scheduleStarts',
                      dayjs(newValue as any).format('YYYY-MM-DD')
                    )
                  }
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      fullWidth: true,
                      required: !!selectedRowsMap[params.row._id],
                      size: 'small',
                    },
                  }}
                  disableFuture={
                    formState[params.row._id].docType?.name === INCIDENT_REPORT &&
                    formState[params.row._id].frequency === FrequencyEnum['ONE TIME']
                  }
                  maxDate={
                    !!formState[params.row._id]?.scheduleEnds
                      ? dayjs(formState[params.row._id].scheduleEnds)
                      : undefined
                  }
                  disabled={!!!formState[params.row._id]?.frequency}
                  sx={{
                    mt: 1.2,
                  }}
                />
              </LocalizationProvider>
            ),
          },
          {
            field: 'scheduleEnds',
            headerName: 'End Date *',
            disableColumnMenu: true,
            disableReorder: true,
            sortable: false,
            minWidth: 165,
            renderCell: params =>
              formState[params.row._id].frequency !== FrequencyEnum['ONE TIME'] ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled={
                      !!!formState[params.row._id].scheduleStarts ||
                      formState[params.row._id].frequency === FrequencyEnum['ONE TIME']
                    }
                    value={
                      !!formState[params.row._id]?.scheduleEnds
                        ? dayjs(formState[params.row._id]?.scheduleEnds)
                        : null
                    }
                    format="DD-MM-YYYY"
                    onChange={newValue =>
                      handleChange(
                        params.row._id,
                        'scheduleEnds',
                        dayjs(newValue as any).format('YYYY-MM-DD')
                      )
                    }
                    minDate={
                      !!formState[params.row._id]?.scheduleStarts
                        ? dayjs(formState[params.row._id].scheduleStarts)
                        : undefined
                    }
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                        fullWidth: true,
                        required: !!selectedRowsMap[params.row._id],
                        size: 'small',
                      },
                    }}
                    sx={{
                      mt: 1.2,
                    }}
                  />
                </LocalizationProvider>
              ) : (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled
                    value={
                      !!formState[params.row._id]?.scheduleStarts
                        ? dayjs(formState[params.row._id]?.scheduleStarts)
                        : null
                    }
                    format="DD-MM-YYYY"
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                        fullWidth: true,
                        size: 'small',
                      },
                    }}
                    sx={{
                      mt: 1.2,
                    }}
                  />
                </LocalizationProvider>
              ),
          },
          {
            field: 'assignTo',
            headerName: 'Assign To *',
            disableColumnMenu: true,
            disableReorder: true,
            sortable: false,
            minWidth: 250,
            renderCell: params => (
              <Box
                sx={{
                  height: 50,
                  overflowY: 'auto',
                  display: 'flex',
                  flexWrap: 'wrap',
                  my: 'auto',
                  pt: 1.5,
                }}
              >
                <MultiSelectAutocomplete
                  values={formState[params.row._id].assignTo ?? []}
                  fetch={getUsers}
                  handleChange={val => handleChange(params.row._id, 'assignTo', val)}
                  label=""
                  loading={false}
                  disabled={!selectedRowsMap[params.row._id]}
                  options={
                    users?.getUsers?.map(usr => ({
                      _id: usr._id,
                      name: usr.firstName + ' ' + (usr.lastName ?? ''),
                      referenceId: usr.empId,
                    })) ?? []
                  }
                  variables={{}}
                  required={
                    !!selectedRowsMap[params.row._id] &&
                    !!!formState[params.row._id].assignTo?.length
                  }
                  variant="standard"
                />
              </Box>
            ),
          },
        ]}
        getRowId={row => row._id}
        rows={rows}
        onRowSelectionModelChange={newSelection => {
          setSelectedRowsMap(
            newSelection.reduce((prev, curr) => {
              prev[curr] = true;
              return prev;
            }, {})
          );
          setSelectedRows(newSelection as string[]);
        }}
        rowSelectionModel={selectedRows}
        processRowUpdate={handleProcessRowUpdate}
        hideFooterPagination
      />
      <Grid container position="sticky" bottom={0} right={10} pt={2} bgcolor={'white'}>
        <Grid item sx={{ ml: 'auto' }} container justifyContent="flex-end" columnGap={1}>
          <Button onClick={handleAddRow} variant="outlined">
            add new checklist
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={!!!selectedRows.length || creatingEHSChecklist}
          >
            Create
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default StandardEHSDocumentsListTable;

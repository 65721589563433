import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { Grid, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { Project } from 'types';

import EHSProjectsTemplate from 'components/EHS/EHSProjectsTemplate';
import Filters from 'components/Filters';
import { Sort } from 'components/Leads/Sort/Sort';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

import { transformFilters } from 'utils/transformFn';

const GET_PROJECTS_QUERY = gql`
  query GetEhs($filter: EhsFilter) {
    getEhs(filter: $filter) {
      _id
      name
      referenceId
      lead {
        _id
        name
        segment
        scope
        areaOrPower
        areaOrPowerUOM
        approxValue
        leadStatus
      }
      status
    }
  }
`;

type GetProjectsQueryResponse = {
  getEhs: Project[];
};

type GetProjectsQueryVariables = {
  filter?: {
    searchTerm?: string;
    status?: string[];
  };
};

const EHSProjectsPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});

  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const { data: projects, networkStatus: projectNetworkStatus } = useQuery<
    GetProjectsQueryResponse,
    GetProjectsQueryVariables
  >(GET_PROJECTS_QUERY, {
    variables: { filter: filters },
    notifyOnNetworkStatusChange: true,
  });

  const loadingProjects =
    projectNetworkStatus === NetworkStatus.loading ||
    projectNetworkStatus === NetworkStatus.setVariables ||
    !!!projects;

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
  };
  return (
    <Navbar
      title="EHS"
      searchInputConfig={{
        enable: true,
        name: 'EHS search',
        placeholder: 'ID / Name',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
    >
      <Grid
        container
        justifyContent="flex-end"
        columnSpacing={2}
        mb={2}
        pr={isMobileScreen ? 0 : 2}
        pt={isMobileScreen ? 0.5 : 0}
      >
        <Grid item xs={6} md={1.8} xl={1}>
          <Filters
            type="ehs"
            refetch={data => {
              applyFilters(transformFilters(data, ['city', 'company']));
            }}
          />
        </Grid>
        <Grid item xs={6} md={1.5} xl={1}>
          <Sort
            sort={filters.sortOption}
            setSort={newSortVal =>
              applyFilters({
                ...filters,
                sortOption: newSortVal,
              })
            }
          />
        </Grid>
      </Grid>
      {loadingProjects ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <EHSProjectsTemplate projects={projects.getEhs} />
      )}
    </Navbar>
  );
};

export default EHSProjectsPage;

import {
  COMPLIANCE_DOC_NAMES,
  ComplianceCurrentStatusEnum,
  ComplianceLicenceApprovalStatusEnum,
} from 'types';

import Section, { SectionDataProps } from 'components/Section';

import { getEnumKeyFromValue } from 'utils/transformFn';

const OneTimeComplianceDetailsSection: React.FC<{
  complianceDetails: any;
}> = ({ complianceDetails }) => {
  const isDocumentAvailable = !!(
    complianceDetails?.applicationDocScan || complianceDetails?.finalDocScan
  );

  const sectionData: SectionDataProps[] = [
    {
      label: 'Reference ID',
      value: complianceDetails.referenceId,
      type: 'STRING',
    },
    {
      label: 'Project',
      value: complianceDetails.project.name,
      type: 'STRING',
    },
    {
      label: 'Doc Name',
      value: getEnumKeyFromValue(COMPLIANCE_DOC_NAMES, complianceDetails.docName),
      type: 'STRING',
    },
    {
      label: 'Doc Type',
      value: complianceDetails.docType,
      type: 'STRING',
    },
    {
      label: 'Folder',
      value:
        complianceDetails?.applicationDocScan?.folder?.name ??
        complianceDetails?.finalDocScan?.folder?.name ??
        'N/A',
      type: isDocumentAvailable ? 'LINK' : 'STRING',
      navigateTo: isDocumentAvailable
        ? `/documents/PROJECT/${complianceDetails?.project?._id}/${
            complianceDetails?.applicationDocScan?.folder?.parentFolder?._id ??
            complianceDetails?.finalDocScan?.folder?.parentFolder?._id
          }/${
            complianceDetails?.applicationDocScan?.folder?._id ??
            complianceDetails?.finalDocScan?.folder?._id
          }`
        : ' ',
    },
    {
      label: 'Start Date',
      value: complianceDetails.startDate,
      type: 'DATE',
    },
    {
      label: 'Applied Date',
      value: complianceDetails.appliedDate,
      type: 'DATE',
    },
    {
      label: 'End Date',
      value: complianceDetails.endDate,
      type: 'DATE',
    },
    {
      label: 'Status',
      value: getEnumKeyFromValue(ComplianceCurrentStatusEnum, complianceDetails.currentStatus),
      type: 'STRING',
    },
  ];

  if (complianceDetails?.licEffectiveDate) {
    sectionData.push(
      {
        label: 'License Effective from',
        value: complianceDetails.licEffectiveDate,
        type: 'DATE',
      },
      {
        label: 'License Expiry On',
        value: complianceDetails.licExpiryDate,
        type: 'DATE',
      },
      {
        label: 'License Approved On ',
        value: complianceDetails.dateLicApprovedByCust,
        type: 'DATE',
      },
      {
        label: 'License Sent On',
        value: complianceDetails.dateLicSentForCustApproval,
        type: 'DATE',
      },
      {
        label: 'License Approval Status',
        value: getEnumKeyFromValue(
          ComplianceLicenceApprovalStatusEnum,
          complianceDetails.licApprovalStatus
        ),
        type: 'STRING',
      }
    );
  }

  if (complianceDetails?.latestCompletedRevalidation) {
    sectionData.push(
      {
        label: 'Revalidated License Effective from',
        value: complianceDetails.latestCompletedRevalidation?.licEffectiveDate,
        type: 'DATE',
      },
      {
        label: 'Revalidated License Expiry On',
        value: complianceDetails.latestCompletedRevalidation?.licExpiryDate,
        type: 'DATE',
      },
      {
        label: 'Revalidated License Approved On ',
        value: complianceDetails.latestCompletedRevalidation?.dateLicApprovedByCust,
        type: 'DATE',
      },
      {
        label: 'Revalidated License Sent On',
        value: complianceDetails.latestCompletedRevalidation?.dateLicSentForCustApproval,
        type: 'DATE',
      },
      {
        label: 'Revalidated License Approval Status',
        value: getEnumKeyFromValue(
          ComplianceLicenceApprovalStatusEnum,
          complianceDetails.latestCompletedRevalidation?.licApprovalStatus
        ),
        type: 'STRING',
      },
      {
        label: 'Revalidation Status',
        value: getEnumKeyFromValue(
          ComplianceLicenceApprovalStatusEnum,
          complianceDetails.latestCompletedRevalidation?.revalidationStatus
        ),
        type: 'STRING',
      }
    );
  }
  return <Section title="Compliance Details" collapsible data={sectionData} />;
};

export default OneTimeComplianceDetailsSection;

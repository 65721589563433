import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { client } from 'graphql/client';
import { UPLOAD_COMPLIANCE_SUB_CONTRACTOR_DOC_MUTATION } from 'graphql/mutation/_compliance';
import { GET_SUBCONTRACTOR_MONTHLY_COMPLIANCE_DOC_DETAILS_QUERY } from 'graphql/query/compliance';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Metadata from 'components/Inputs/Metadata';
import UploadFile from 'components/Inputs/UploadFile';

const UploadComplianceDocumentForm: React.FC<{
  complianceDocument: any;
  onCancel: () => void;
  btnTitle: string;
}> = ({ complianceDocument, onCancel, btnTitle }) => {
  const [formState, setFormState] = useState<Record<string, any>>({});
  const { complianceId = '' } = useParams<{ complianceId: string }>();

  const [uploadSubContractorMonthlyComplianceDoc, { loading }] = useMutation<any, any>(
    UPLOAD_COMPLIANCE_SUB_CONTRACTOR_DOC_MUTATION
  );

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    const metadata: Record<string, string> = {};
    (formState.metadata ?? []).forEach(d => {
      if (!!d.key) {
        metadata[d.key] = d.value;
      }
    });

    uploadSubContractorMonthlyComplianceDoc({
      variables: {
        input: {
          _id: complianceId,
          docName: complianceDocument?.docName,
          file: formState.file as File,
          metadata: JSON.stringify(metadata),
        },
      },
      onCompleted: res => {
        if (!res.uploadSubContractorMonthlyComplianceDoc?.success) {
          toast.error(res.uploadSubContractorMonthlyComplianceDoc?.message);
        } else {
          onCancel();
          deleteDocumentCacheForSubcontractor(complianceId);
        }
      },
    });
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columnSpacing={1.5} rowGap={2}>
        <Grid item xs={12}>
          <UploadFile
            buttonProps={{
              size: 'small',
              sx: {
                fontSize: 10,
              },
            }}
            values={formState.file ?? ''}
            onChange={val => handleChange('file', val)}
            label="Select File *"
            required
          />
        </Grid>
        <Grid item xs={12} mt={-1}>
          <Metadata
            metadata={formState.metadata ?? [{ key: '', value: '' }]}
            setMetadata={md =>
              setFormState(prev => ({
                ...prev,
                metadata: md,
              }))
            }
          />
        </Grid>
        <Grid item xs={12} container columnGap={1}>
          <Button
            variant="text"
            size="small"
            sx={{
              fontSize: 10,
              fontWeight: 600,
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            type="submit"
            size="small"
            sx={{
              fontSize: 10,
              fontWeight: 600,
            }}
          >
            Upload {btnTitle}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export const deleteDocumentCacheForSubcontractor = (complianceId: string) => {
  const oldData = client.readQuery({
    query: GET_SUBCONTRACTOR_MONTHLY_COMPLIANCE_DOC_DETAILS_QUERY,
    variables: {
      id: complianceId,
    },
  }) ?? { getSubcontractorMonthlyComplianceDocById: {} };

  client.writeQuery({
    query: GET_SUBCONTRACTOR_MONTHLY_COMPLIANCE_DOC_DETAILS_QUERY,
    variables: {
      id: complianceId,
    },
    data: {
      getSubcontractorMonthlyComplianceDocById: {
        ...oldData,
      },
    },
  });
};

export default UploadComplianceDocumentForm;

import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import LoadingIndicator from 'components/LoadingIndicator';
import Section from 'components/Section';

import { SubcontractorCard } from '../../ContactsSection/Subcontractors/Card';

const SubcontractorsMonthlyTasks = ({ subContractorInfo, loading }) => {
  const navigate = useNavigate();

  return (
    <Grid container direction="column">
      <Section title="Subcontractors" collapsible defaultCollapsed>
        {loading ? (
          <Grid>
            <LoadingIndicator size="1.6rem" />
          </Grid>
        ) : (
          <Grid container columns={2} rowSpacing={2} columnSpacing={3} mb={5} alignItems="center">
            {subContractorInfo
              ?.filter((sub: any) => sub?.status === 'ACTIVE')
              ?.map((b: any) => (
                <Grid item key={b.referenceId} xs={2} md={1} lg={1}>
                  <SubcontractorCard
                    onClick={() => {
                      navigate(
                        `subcontractors/${b.name}?workStartDate=${b?.workStartDate}&workEndDate=${b?.workEndDate}&status=${b?.status}`
                      );
                      window.location.reload();
                    }}
                    people={b}
                  />
                </Grid>
              ))}
          </Grid>
        )}
      </Section>
    </Grid>
  );
};

export default SubcontractorsMonthlyTasks;

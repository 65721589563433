import { ApolloQueryResult, NetworkStatus, useMutation, useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import PublishIcon from '@mui/icons-material/Publish';
import ShareIcon from '@mui/icons-material/Share';
import { LoadingButton } from '@mui/lab';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  CREATE_FOLDER_MUTATION,
  CreateFolderMutationResponse,
  CreateFolderMutationVariables,
} from 'graphql/mutation/folder';
import {
  GET_FOLDERS_QUERY_FOR_LV,
  GET_FOLDER_BY_NAME,
  GetFolderByNameResponse,
  GetFolderByNameVariables,
  GetFoldersQueryResponse,
  GetFoldersQueryVariables,
} from 'graphql/query/folder';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { updateFolderCachedList } from 'routes/DocumentsMaster/DocumentFolders';
import { PQQueryResponse, PQQueryVariables } from 'routes/PQs/PQDetails';
import { FolderTypeEnum, Lead, LeadStatusEnum } from 'types';

import FolderCard from 'components/DocumentMaster/Card/FolderCard';
import TextField from 'components/Inputs/TextField';
import LoadingIndicator from 'components/LoadingIndicator';
import SharePQForm from 'components/PQs/Forms/SharePQForm';
import SubmitPQForm from 'components/PQs/Forms/SubmitPQForm';
import { SimplePopup } from 'components/Popup';
import Section from 'components/Section';
import Transition from 'components/Transition';

import { invalidFolderNameCharacters } from 'utils/regexes';

export enum FolderNameEnum {
  PQ = 'PQ',
  RFP = 'RFP',
  COMPLIANCE = 'Compliance',
  'SITE EHS' = 'Site_EHS',
}

const PQFolderSection: React.FC<{
  lead: Lead;
  refetchPQ: (variables?: Partial<PQQueryVariables>) => Promise<ApolloQueryResult<PQQueryResponse>>;
}> = ({ lead, refetchPQ }) => {
  const [enableCreateFolderPopup, toggleCreateFolderPopup] = useState(false);
  const [enableSharePQForm, toggleSharePQForm] = useState(false);
  const [formState, setFormState] = useState<Record<string, any>>({});
  const [enableSubmitForm, toggleSubmitForm] = useState(false);

  const { leadId = '' } = useParams<{ leadId: string }>();

  const { data: pqFolder } = useQuery<GetFolderByNameResponse, GetFolderByNameVariables>(
    GET_FOLDER_BY_NAME,
    {
      variables: {
        name: FolderNameEnum.PQ,
        lead: leadId,
      },
    }
  );

  const { data: folders, networkStatus } = useQuery<
    GetFoldersQueryResponse,
    GetFoldersQueryVariables
  >(GET_FOLDERS_QUERY_FOR_LV, {
    notifyOnNetworkStatusChange: true,
    variables: {
      type: FolderTypeEnum.LEAD,
      folderName: FolderNameEnum.PQ,
      projectOrLeadId: leadId,
    },
  });

  const [createFolder, { loading: creatingFolder }] = useMutation<
    CreateFolderMutationResponse,
    CreateFolderMutationVariables
  >(CREATE_FOLDER_MUTATION);

  const navigate = useNavigate();

  const loadingFolders =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!folders;

  const isPQReceived = lead.leadStatus === LeadStatusEnum['PQ RECEIVED'];

  const pqFoldersActions = useMemo(() => {
    if (isPQReceived) {
      return (
        <Grid
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          columnGap={1.5}
        >
          <Tooltip title="Create new folder">
            <CreateNewFolderIcon
              sx={{ cursor: 'pointer' }}
              onClick={() => toggleCreateFolderPopup(true)}
            />
          </Tooltip>
        </Grid>
      );
    } else {
      return (
        <Grid
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          columnGap={1.5}
        >
          {folders?.getFolders.folders?.length !== 0 && (
            <Box fontSize={17} mt={0.5}>
              <Tooltip title="Share PQ">
                <ShareIcon
                  onClick={() => toggleSharePQForm(true)}
                  sx={{ cursor: 'pointer' }}
                  fontSize="small"
                />
              </Tooltip>
            </Box>
          )}
          <Tooltip title="Create new folder">
            <CreateNewFolderIcon
              sx={{ cursor: 'pointer' }}
              onClick={() => toggleCreateFolderPopup(true)}
            />
          </Tooltip>
        </Grid>
      );
    }
  }, [isPQReceived, folders?.getFolders]);

  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const handleCreateFolder = () => {
    createFolder({
      variables: {
        input: {
          name: (formState.name as string).trim(),
          type: FolderTypeEnum.LEAD,
          parentFolder: pqFolder?.getFolderByName?._id ?? '',
          lead: leadId,
          isStandardFolder: false,
        },
      },
      onCompleted: res => {
        updateFolderCachedList(res.createFolder, {
          type: FolderTypeEnum.LEAD,
          folderName: FolderNameEnum.PQ,
          projectOrLeadId: leadId,
        });
        toggleCreateFolderPopup(false);
        navigate(
          `/documents/${FolderTypeEnum.LEAD}/${leadId}/${res.createFolder.parentFolder?._id}/${res.createFolder._id}`
        );
      },
    });
  };

  const handleFolderNameChange = (fieldName: string, value: string) => {
    if (!invalidFolderNameCharacters.test(value)) {
      setFormState(prev => ({ ...prev, [fieldName]: value }));
    }
  };

  const shouldShowSubmitButton =
    lead.leadStatus === LeadStatusEnum['PQ RECEIVED'] &&
    !!folders?.getFolders &&
    folders.getFolders.folders.length !== 0 &&
    folders.getFolders.folders.filter(f => f.fileCount > 0).length !== 0;

  return (
    <Section title="PQ Folders" collapsible action={pqFoldersActions}>
      <Box px={1} py={1} width="100%">
        {loadingFolders ? (
          <LoadingIndicator size="1.2rem" />
        ) : (
          <Grid container columns={12} rowSpacing={3} columnSpacing={5} alignItems="center">
            {folders.getFolders.folders.map(folder => (
              <Grid item xs={12} sm={6} lg={4} key={folder.referenceId}>
                <FolderCard
                  folder={folder}
                  hideCount
                  onClick={() =>
                    navigate(
                      `/documents/${FolderTypeEnum.LEAD}/${leadId}/${folder.parentFolder?._id}/${folder._id}`
                    )
                  }
                />
              </Grid>
            ))}
            {folders.getFolders.folders.length === 0 && (
              <Grid item xs={12} my="auto" container justifyContent="center" alignItems="center">
                <Typography variant="subtitle2">No folders added yet.</Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
      <SimplePopup
        title="Add New Folder"
        onClose={() => toggleCreateFolderPopup(false)}
        open={enableCreateFolderPopup}
        fullWidth
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            handleCreateFolder();
          }}
        >
          <Grid container direction="column" gap={2} px={2} py={1}>
            <TextField
              label="Folder Name"
              value={formState.name ?? ''}
              onChange={e => handleFolderNameChange('name', e.target.value)}
              helperText={'Name cannot include these characters":  / : * ? " < > |'}
              required
            />
            <LoadingButton
              variant="contained"
              type="submit"
              color="secondary"
              loading={creatingFolder}
            >
              Create
            </LoadingButton>
          </Grid>
        </form>
      </SimplePopup>
      <Dialog
        open={enableSharePQForm}
        TransitionComponent={Transition}
        fullWidth
        fullScreen={isMobileScreen}
        onClose={(_, reason) => {
          if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) return;
          toggleSharePQForm(false);
        }}
      >
        <AppBar sx={{ position: 'relative', bgcolor: theme => theme.palette.secondary.main }}>
          <Toolbar variant={'dense'}>
            <Typography sx={{ flex: 1, fontWeight: 600 }} variant="subtitle1" component="div">
              Mail PQ Folders
            </Typography>
            <CloseIcon onClick={() => toggleSharePQForm(false)} sx={{ cursor: 'pointer' }} />
          </Toolbar>
        </AppBar>

        <DialogContent>
          <SharePQForm lead={lead} cb={() => toggleSharePQForm(false)} />
        </DialogContent>
      </Dialog>
      <SimplePopup
        onClose={() => toggleSubmitForm(false)}
        open={enableSubmitForm}
        title="Submit PQ"
        fullWidth
      >
        <SubmitPQForm
          lead={lead}
          cb={() => {
            refetchPQ({
              id: lead._id,
            });
            toggleSubmitForm(false);
            toggleSharePQForm(true);
          }}
        />
      </SimplePopup>
      {shouldShowSubmitButton && (
        <Grid xs={12} display={'flex'} justifyContent={'flex-end'} pt={1}>
          <Button variant="contained" size="small" onClick={() => toggleSubmitForm(true)}>
            Submit PQ
          </Button>
        </Grid>
      )}
    </Section>
  );
};

export default PQFolderSection;

import { gql } from '@apollo/client';

export const ADD_COMPLIANCE_SUB_CONTRACTOR_MUTATION = gql`
  mutation AddComplianceSubContractorInfo($input: AddComplianceSubContractorInfoInput!) {
    addComplianceSubContractorInfo(input: $input) {
      _id
      referenceId
      workStartDate
      workEndDate
      bocwLicenceProvidedBy
      project {
        _id
        name
        referenceId
      }
    }
  }
`;

export const UPDATE_COMPLIANCE_SUB_CONTRACTOR_INFO_MUTATION = gql`
  mutation UpdateComplianceSubContractorInfo($input: UpdateComplianceSubContractorInfoInput!) {
    updateComplianceSubContractorInfo(input: $input) {
      _id
      referenceId
      workStartDate
      workEndDate
      bocwLicenceProvidedBy
      project {
        referenceId
        _id
        name
      }
    }
  }
`;

export const ADD_WK_SITE_INFO_MUTATION = gql`
  mutation AddWKSiteComplianceContacts($input: AddWKSiteComplianceContactsInput!) {
    addWKSiteComplianceContacts(input: $input) {
      _id
      referenceId
      workStartDate
      workEndDate
      bocwLicenceProvidedBy
    }
  }
`;

export const UPDATE_WK_SITE_INFO_MUTATION = gql`
  mutation UpdateWKSiteComplianceContact($input: UpdateWKSiteComplianceContactInput!) {
    updateWKSiteComplianceContact(input: $input) {
      _id
      referenceId
      workStartDate
      workEndDate
      bocwLicenceProvidedBy
      wkSiteComplianceContacts {
        _id
        name
        email
      }
    }
  }
`;

export const UPLOAD_APPLICATION_ONE_TIME_COMPLIANCE_DOC_MUTATION = gql`
  mutation ApplyOneTimeComplianceDoc($input: OneTimeComplianceDocInput!) {
    applyOneTimeComplianceDoc(input: $input) {
      success
      message
      oneTimeComplianceDoc {
        _id
        referenceId
        docName
        docType
        startDate
        appliedDate
        endDate
        currentStatus
        licEffectiveDate
        licExpiryDate
        dateLicSentForCustApproval
        dateLicApprovedByCust
        licApprovalStatus
      }
      file {
        metadata
        name
        referenceId
        _id
        path
      }
    }
  }
`;

export const UPLOAD_FINAL_ONE_TIME_COMPLIANCE_DOC_MUTATION = gql`
  mutation UploadOneTimeComplianceDoc($input: OneTimeComplianceDocInput!) {
    uploadOneTimeComplianceDoc(input: $input) {
      success
      message
      oneTimeComplianceDoc {
        _id
        referenceId
        docName
        docType
        startDate
        appliedDate
        endDate
        currentStatus
        licEffectiveDate
        licExpiryDate
        dateLicSentForCustApproval
        dateLicApprovedByCust
        licApprovalStatus
      }
      file {
        metadata
        name
        referenceId
        _id
        path
      }
    }
  }
`;

export const APPROVE_ONE_TIME_COMPLIANCE_DOC_MUTATION = gql`
  mutation ApproveOneTimeComplianceDoc($input: OneTimeComplianceDocInput!) {
    approveOneTimeComplianceDoc(input: $input) {
      success
      message
      oneTimeComplianceDoc {
        _id
        referenceId
        docName
        docType
        docDescription
        startDate
        appliedDate
        endDate
        currentStatus
        licEffectiveDate
        licExpiryDate
        dateLicSentForCustApproval
        dateLicApprovedByCust
        licApprovalStatus
      }
      file {
        metadata
        name
        referenceId
        _id
        path
      }
    }
  }
`;

// Revalidation
export const APPLY_ONE_TIME_REVALIDATION_COMPLIANCE_DOC_MUTATION = gql`
  mutation ApplyOneTimeComplianceRevalidation($input: OneTimeComplianceRevalidationInput!) {
    applyOneTimeComplianceRevalidation(input: $input) {
      success
      message
      oneTimeComplianceDoc {
        _id
        referenceId
        docName
        docType
        startDate
        appliedDate
        endDate
        currentStatus
        licEffectiveDate
        licExpiryDate
        dateLicSentForCustApproval
        dateLicApprovedByCust
        licApprovalStatus
      }
      file {
        metadata
        name
        referenceId
        _id
        path
      }
    }
  }
`;

export const UPLOAD_ONE_TIME_REVALIDATION_COMPLIANCE_DOC_MUTATION = gql`
  mutation UploadOneTimeComplianceRevalidation($input: OneTimeComplianceRevalidationInput!) {
    uploadOneTimeComplianceRevalidation(input: $input) {
      success
      message
      oneTimeComplianceDoc {
        _id
        referenceId
        docName
        docType
        startDate
        appliedDate
        endDate
        currentStatus
        licEffectiveDate
        licExpiryDate
        dateLicSentForCustApproval
        dateLicApprovedByCust
        licApprovalStatus
      }
      file {
        metadata
        name
        referenceId
        _id
        path
      }
    }
  }
`;

export const APPROVE_ONE_TIME_REVALIDATION_COMPLIANCE_DOC_MUTATION = gql`
  mutation ApproveOneTimeComplianceRevalidation($input: OneTimeComplianceRevalidationInput!) {
    approveOneTimeComplianceRevalidation(input: $input) {
      success
      message
      oneTimeComplianceDoc {
        _id
        referenceId
        docName
        docType
        startDate
        appliedDate
        endDate
        currentStatus
        licEffectiveDate
        licExpiryDate
        dateLicSentForCustApproval
        dateLicApprovedByCust
        licApprovalStatus
      }
      file {
        metadata
        name
        referenceId
        _id
        path
      }
    }
  }
`;

// Monthly Compliances:
// Woodkraft

export const UPLOAD_WOODKRAFT_APPLICATION_COMPLIANCE_DOC = gql`
  mutation ApplyMonthlyComplianceDoc($input: ComplianceDocInput!) {
    applyMonthlyComplianceDoc(input: $input) {
      success
      message
      file {
        _id
        referenceId
        name
        path
        metadata
        shortPath
        createdAt
      }
      monthlyComplianceDoc {
        _id
        referenceId
        monthYear
        docName
        docType
        paymentRequestDate
        paymentCompletedDate
      }
    }
  }
`;

export const UPDATE_MONTHLY_COMPLIANCE_PAYMENT_STATUS = gql`
  mutation UpdateMonthlyComplianceDocPaymentStatus($id: ID!) {
    updateMonthlyComplianceDocPaymentStatus(_id: $id) {
      success
      message
      monthlyComplianceDoc {
        docName
        docType
        monthYear
        referenceId
        _id
      }
      file {
        referenceId
        _id
        name
        shortPath
        path
      }
    }
  }
`;

export const UPLOAD_WOODKRAFT_FINAL_COMPLIANCE_DOC = gql`
  mutation UploadMonthlyComplianceDoc($input: ComplianceDocInput!) {
    uploadMonthlyComplianceDoc(input: $input) {
      success
      message
      file {
        _id
        referenceId
        name
        path
        shortPath
        createdAt
      }
      monthlyComplianceDoc {
        docName
        docType
        _id
        referenceId
      }
    }
  }
`;

// Subcontractor

export const UPLOAD_COMPLIANCE_SUB_CONTRACTOR_DOC_MUTATION = gql`
  mutation UploadSubContractorMonthlyComplianceDoc($input: ComplianceDocInput!) {
    uploadSubContractorMonthlyComplianceDoc(input: $input) {
      success
      message
      file {
        _id
        name
        project {
          _id
          name
        }
        path
        folder {
          _id
          parentFolder {
            _id
          }
        }
      }
      subContractorComplianceDoc {
        _id
        docName
        docType
        finalDocScan {
          _id
        }
      }
    }
  }
`;

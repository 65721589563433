import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { DocStatusEnum, EHSChecklist, EHSDocument, Folder, FrequencyEnum } from 'types';

import Section from 'components/Section';
import DataGridTable from 'components/Table';

import { getEnumKeyFromValue } from 'utils/transformFn';

const EHSCheckListTemplate: React.FC<{
  ehs: EHSChecklist[];
  toggleStandardChecklistTable: (arg: boolean) => void;
}> = ({ ehs, toggleStandardChecklistTable }) => {
  return (
    <Section
      title="EHS Checklist"
      collapsible
      action={
        <AddIcon onClick={() => toggleStandardChecklistTable(true)} sx={{ cursor: 'pointer' }} />
      }
    >
      <EHSChecklistTable rows={ehs} />
    </Section>
  );
};

const EHSChecklistTable: React.FC<{ rows: EHSChecklist[] }> = ({ rows }) => {
  return (
    <DataGridTable
      rows={rows}
      getRowId={row => row._id}
      columns={[
        {
          field: 'referenceId',
          headerName: 'ID',
          renderCell: params => <Link to={params.row._id}>{params.value}</Link>,
        },
        {
          field: 'docType',
          headerName: 'Doc Type',
          valueGetter: (docType: Folder) => docType.name,
          minWidth: 200,
        },
        {
          field: 'docName',
          headerName: 'Doc Name',
          minWidth: 200,
        },
        {
          field: 'frequency',
          headerName: 'Frequency',
          minWidth: 150,
          valueFormatter: value => getEnumKeyFromValue(FrequencyEnum, value),
        },
        {
          field: 'pendingDocuments',
          headerName: 'Document Status',
          minWidth: 200,
          renderCell: params => <PendingDocStatusCell pendingDocuments={params.value} />,
        },
      ]}
      sx={{ minHeight: '40vh', maxWidth: '80vw', mx: 'auto', mt: 0.5 }}
    />
  );
};
export const PendingDocStatusCell: React.FC<{ pendingDocuments: EHSDocument[] }> = ({
  pendingDocuments,
}) => {
  const numOfPendingDocuments = (pendingDocuments ?? []).filter(
    doc => doc.docStatus === DocStatusEnum.PENDING
  ).length;

  return (
    <Grid display="flex" height={'100%'} alignItems="center" columnGap={1}>
      <Typography
        sx={{
          p: 0.6,
          borderRadius: '50%',
          bgcolor: !!!numOfPendingDocuments ? 'lightgreen' : 'crimson',
        }}
      />
      <Typography variant="caption" letterSpacing={0.9}>
        {!!numOfPendingDocuments ? `You have ${numOfPendingDocuments} Overdues` : 'No Overdues'}
      </Typography>
    </Grid>
  );
};

export default EHSCheckListTemplate;

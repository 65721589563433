import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import {
  UPDATE_EHS_INCIDENT_REPORT,
  UPDATE_EHS_INVESTIGATION_REPORT,
  UpdateEHSInvestigationReportResponse,
  UpdateEHSInvestigationReportVariables,
  UpdateIncidentReportResponse,
  UpdateIncidentReportVariables,
} from 'graphql/mutation/ehs';
import React, { useState } from 'react';
import { EHSChecklist } from 'types';

import Metadata from 'components/Inputs/Metadata';
import UploadFile from 'components/Inputs/UploadFile';

const UploadIncidentFileForm: React.FC<{
  ehsChecklist: EHSChecklist;
  cb: (arg: any) => void;
  isIncidentReport: boolean;
}> = ({ cb, ehsChecklist, isIncidentReport }) => {
  const [formState, setFormState] = useState<Record<string, any>>({});

  const [updateEHSIncidentReport, { loading: updatingEHSIncidentReport }] = useMutation<
    UpdateIncidentReportResponse,
    UpdateIncidentReportVariables
  >(UPDATE_EHS_INCIDENT_REPORT);

  const [updateInvestigationReport, { loading: updatingInvestigationReport }] = useMutation<
    UpdateEHSInvestigationReportResponse,
    UpdateEHSInvestigationReportVariables
  >(UPDATE_EHS_INVESTIGATION_REPORT);

  const handleSubmit = () => {
    const metadata = {};
    (formState.metadata ?? []).forEach(d => {
      if (!!d.key) {
        metadata[d.key] = d.value;
      }
    });

    if (isIncidentReport) {
      updateEHSIncidentReport({
        variables: {
          input: {
            _id: ehsChecklist.incidentReports._id,
            file: formState.file,
            metadata: JSON.stringify(metadata),
          },
        },
        onCompleted: res => cb(res.updateIncidentReport),
      });
    } else {
      updateInvestigationReport({
        variables: {
          input: {
            _id: ehsChecklist.investigationReports._id,
            file: formState.file,
            metadata: JSON.stringify(metadata),
          },
        },
        onCompleted: res => cb(res.updateIncidentInvestigationReport),
      });
    }
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columnSpacing={1.5} rowGap={2} px={2} pb={3} pt={1}>
        <Grid item xs={12}>
          <UploadFile
            label="Add File *"
            onChange={val => setFormState(prev => ({ ...prev, file: val }))}
            accept="*"
            required
            buttonProps={{
              size: 'small',
            }}
            values={formState.file}
          />
        </Grid>
        <Grid item xs={12}>
          <Metadata
            metadata={formState.metadata ?? [{ key: ``, value: `` }]}
            setMetadata={md =>
              setFormState(prev => ({
                ...prev,
                metadata: md,
              }))
            }
          />
        </Grid>
        <Grid container item xs={12}>
          <LoadingButton
            loading={updatingEHSIncidentReport || updatingInvestigationReport}
            variant="contained"
            sx={{ ml: 'auto' }}
            type="submit"
          >
            Add File
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default UploadIncidentFileForm;

import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import {
  UPLOAD_WOODKRAFT_APPLICATION_COMPLIANCE_DOC,
  UPLOAD_WOODKRAFT_FINAL_COMPLIANCE_DOC,
} from 'graphql/mutation/_compliance';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Metadata from 'components/Inputs/Metadata';
import UploadFile from 'components/Inputs/UploadFile';

import { deleteDocumentCache } from './deleteDocumentCache';

const UploadComplianceDocumentForm: React.FC<{
  complianceDocument: any;
  onCancel: () => void;
  btnTitle: string;
}> = ({ complianceDocument, onCancel, btnTitle }) => {
  const [formState, setFormState] = useState<Record<string, any>>({});

  const [applyMonthlyComplianceDoc, { loading: uploadingApplicationDocument }] = useMutation(
    UPLOAD_WOODKRAFT_APPLICATION_COMPLIANCE_DOC
  );

  const [uploadMonthlyComplianceDoc, { loading: uploadingFinalDocument }] = useMutation(
    UPLOAD_WOODKRAFT_FINAL_COMPLIANCE_DOC
  );

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    const metadata = {};
    (formState.metadata ?? []).forEach(d => {
      if (!!d.key) {
        metadata[d.key] = d.value;
      }
    });

    const input = {
      _id: complianceDocument?._id,
      file: formState.file,
      metadata: JSON.stringify(metadata),
      docName: complianceDocument?.docName,
    };

    if (btnTitle === 'Application Document') {
      // Application doc
      applyMonthlyComplianceDoc({
        variables: {
          input,
        },
        onCompleted: res => {
          if (!res.applyMonthlyComplianceDoc.success) {
            toast.error(res.applyMonthlyComplianceDoc.message);
          } else {
            onCancel();
            deleteDocumentCache(input?._id);
          }
        },
      });
    } else {
      // Final doc
      uploadMonthlyComplianceDoc({
        variables: {
          input,
        },
        onCompleted: res => {
          if (!res.uploadMonthlyComplianceDoc.success) {
            toast.error(res.uploadMonthlyComplianceDoc.message);
          } else {
            onCancel();
            deleteDocumentCache(input?._id);
          }
        },
      });
    }
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columnSpacing={1.5} rowGap={2}>
        <Grid item xs={12}>
          <UploadFile
            buttonProps={{
              size: 'small',
              sx: {
                fontSize: 10,
              },
            }}
            values={formState.file ?? ''}
            onChange={val => handleChange('file', val)}
            label="Select File *"
            required
          />
        </Grid>
        <Grid item xs={12} mt={-1}>
          <Metadata
            metadata={formState.metadata ?? [{ key: ``, value: `` }]}
            setMetadata={md =>
              setFormState(prev => ({
                ...prev,
                metadata: md,
              }))
            }
          />
        </Grid>

        <Grid item xs={12} container columnGap={1}>
          <Button
            variant="text"
            size="small"
            sx={{
              fontSize: 10,
              fontWeight: 600,
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={uploadingApplicationDocument || uploadingFinalDocument}
            type="submit"
            size="small"
            sx={{
              fontSize: 10,
              fontWeight: 600,
            }}
          >
            Upload {btnTitle}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default UploadComplianceDocumentForm;

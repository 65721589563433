import { Grid, Card as MuiCard, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Section from 'components/Section';

const WoodkraftMonthlyTasks = ({ woodkraftInfo }) => {
  const navigate = useNavigate();

  return (
    <Section title="Woodkraft" collapsible defaultCollapsed>
      <Grid container columns={2} rowSpacing={2} columnSpacing={3} mb={5} alignItems="center">
        <Grid item xs={2} md={1} lg={1}>
          <MuiCard
            variant="elevation"
            elevation={4}
            sx={{ cursor: 'pointer', width: '100%' }}
            onClick={() => {
              navigate(
                `woodkraft?workStartDate=${woodkraftInfo?.workStartDate}&workEndDate=${woodkraftInfo?.workEndDate}`
              );
              window.location.reload();
            }}
          >
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              sx={{
                py: 0.2,
                px: 2,
              }}
            >
              <Grid item>
                <Typography variant="subtitle1" fontWeight={500} sx={{ color: 'secondary.main' }}>
                  Task Details
                </Typography>
              </Grid>
            </Grid>
          </MuiCard>
        </Grid>
      </Grid>
    </Section>
  );
};

export default WoodkraftMonthlyTasks;

import { NetworkStatus, useQuery } from '@apollo/client';
import {
  GET_EHS_CHECKLIST_BY_ID,
  GetEhsChecklistByIdResponse,
  GetEhsChecklistByIdVariables,
} from 'graphql/query/ehs';
import { useParams } from 'react-router-dom';

import UpdateEhsChecklistForm from 'components/EHS/Forms/UpdateEhsChecklistForm';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

const UpdateEhsDetails = () => {
  const { ehsChecklistId = '' } = useParams<{ ehsChecklistId: string }>();

  const { data: ehsChecklist, networkStatus } = useQuery<
    GetEhsChecklistByIdResponse,
    GetEhsChecklistByIdVariables
  >(GET_EHS_CHECKLIST_BY_ID, {
    variables: {
      id: ehsChecklistId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const loadingEhsChecklist =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!ehsChecklist;

  return (
    <Navbar
      goBackButtonConfig={{
        title: `Update Form for ${ehsChecklist?.getEhsChecklistDetailsById?.docType?.name ?? ''}`,
      }}
    >
      {loadingEhsChecklist ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <UpdateEhsChecklistForm initialData={ehsChecklist.getEhsChecklistDetailsById} />
      )}
    </Navbar>
  );
};

export default UpdateEhsDetails;

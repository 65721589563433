import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';

import Navbar from 'components/Navbar';
import ComplianceDetailsSections from 'components/_Compliances/ComplianceDetailsSections';

function NewComplianceDetailsPage() {
  const queryString = useLocation()?.search;
  const params = new URLSearchParams(queryString);
  const projectName = params?.get('projectName') as string;

  return (
    <Navbar goBackButtonConfig={{ title: `${projectName} Compliances` }}>
      <Grid item container direction={'column'} xs={12} md={11} lg={9} xl={7} mb={5} rowGap={2.5}>
        <ComplianceDetailsSections />
      </Grid>
    </Navbar>
  );
}

export default NewComplianceDetailsPage;

import { NetworkStatus, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { GET_COMPLIANCE_DETAILS_QUERY } from 'graphql/query/compliance';
import { GET_PROJECT_DETAILS_BY_ID_QUERY } from 'graphql/query/project';
import { useLocation, useParams } from 'react-router-dom';

import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

import ProjectDetailsSection from './ProjectDetailsSection';

const ProjectDetailsPage = () => {
  const { projectId = '' } = useParams<{ projectId: string }>();

  const queryString = useLocation()?.search;
  const params = new URLSearchParams(queryString);
  const projectName = params?.get('projectName') as string;

  const { data, networkStatus } = useQuery(GET_PROJECT_DETAILS_BY_ID_QUERY, {
    variables: { id: projectId },
    notifyOnNetworkStatusChange: true,
  });

  const loading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!data;

  return (
    <Navbar goBackButtonConfig={{ title: `${projectName} details` }}>
      {loading ? (
        <Grid>
          <LoadingIndicator size="1.6rem" />
        </Grid>
      ) : (
        <Grid item container direction={'column'} xs={12} md={11} lg={9} xl={7} mb={5} rowGap={2.5}>
          <ProjectDetailsSection projectDetails={data?.getProjectById} />
        </Grid>
      )}
    </Navbar>
  );
};

export default ProjectDetailsPage;

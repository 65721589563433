import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import React from 'react';
import { People } from 'types';

import Section from 'components/Section';

const CustomerDetailsCard: React.FC<{
  people: People;
  action: React.ReactNode | { title: string; onClick: () => void; disabled: boolean }[];
  isDecisionMaker?: boolean;
}> = ({ people, action, isDecisionMaker = false }) => {
  return (
    <>
      <Section
        sx={{ backgroundColor: people.status === 'INACTIVE' ? 'lightgray' : '' }}
        title={people.name}
        action={action}
        variant="outlined"
        endAdornment={isDecisionMaker ? <AccountCircleIcon /> : undefined}
        data={[
          {
            label: 'Name',
            value: people.name,
            type: 'STRING',
          },
          {
            label: 'Email',
            value: people.email,
            type: 'STRING',
          },
        ]}
      />
    </>
  );
};

export default CustomerDetailsCard;

import { gql } from '@apollo/client';
import { Lead } from 'types';

export const GET_LEADS_QUERY = gql`
  query GetLeads($filter: LeadFilter) {
    getLeads(filter: $filter) {
      _id
      referenceId
      name
      leadStatus
    }
  }
`;

export const GET_WON_LEADS_WITHOUT_PROJECT_QUERY = gql`
  query GetWonLeadsWithoutProject {
    getWonLeadsWithoutProject {
      _id
      referenceId
      name
    }
  }
`;

export type GetLeadsWithoutProjectQueryResponse = {
  getWonLeadsWithoutProject: Lead[];
};

export type GetLeadsQueryResponse = {
  getLeads: Lead[];
};

import { COMPLIANCE_DOC_NAMES, ComplianceCurrentStatusEnum } from 'types';

import Section, { SectionDataProps } from 'components/Section';

import { getEnumKeyFromValue } from 'utils/transformFn';

const MonthlyWoodkraftComplianceDetailsSection: React.FC<{
  monthlyComplianceDetails: any;
}> = ({ monthlyComplianceDetails }) => {
  const isDocumentavailable =
    monthlyComplianceDetails?.applicationDocScan != null ||
    monthlyComplianceDetails?.finalDocScan != null;

  const sectionData: SectionDataProps[] = [
    {
      label: 'Reference ID',
      value: monthlyComplianceDetails.referenceId,
      type: 'STRING',
    },
    {
      label: 'Project',
      value: monthlyComplianceDetails.project.name,
      type: 'STRING',
    },
    {
      label: 'Doc Name',
      value: getEnumKeyFromValue(COMPLIANCE_DOC_NAMES, monthlyComplianceDetails.docName),
      type: 'STRING',
    },
    {
      label: 'Doc Type',
      value: monthlyComplianceDetails.docType,
      type: 'STRING',
    },
    {
      label: 'Folder',
      value: monthlyComplianceDetails?.finalDocScan?.folder
        ? monthlyComplianceDetails?.applicationDocScan
          ? monthlyComplianceDetails?.applicationDocScan?.folder?.name
          : monthlyComplianceDetails?.finalDocScan?.folder?.name
        : 'N/A',
      type: isDocumentavailable ? 'LINK' : 'STRING',
      navigateTo: isDocumentavailable
        ? `/documents/PROJECT/${monthlyComplianceDetails?.project?._id}/${
            monthlyComplianceDetails?.applicationDocScan?.folder?.parentFolder?._id ??
            monthlyComplianceDetails?.finalDocScan?.folder?.parentFolder?._id
          }/${
            monthlyComplianceDetails?.applicationDocScan?.folder?._id ??
            monthlyComplianceDetails?.finalDocScan?.folder?._id
          }`
        : '',
    },
    {
      label: 'Month and Year',
      value: monthlyComplianceDetails.monthYear,
      type: 'STRING',
    },
    {
      label: 'Start Date',
      value: monthlyComplianceDetails.startDate,
      type: 'DATE',
    },
    {
      label: 'End Date',
      value: monthlyComplianceDetails.endDate,
      type: 'DATE',
    },
    {
      label: 'Target Date',
      value: monthlyComplianceDetails.targetDate,
      type: 'DATE',
    },
    {
      label: 'Status',
      value: getEnumKeyFromValue(
        ComplianceCurrentStatusEnum,
        monthlyComplianceDetails.currentStatus
      ),

      type: 'STRING',
    },
  ];

  if (monthlyComplianceDetails.providedBy) {
    sectionData?.push({
      label: 'Provided By',
      value: monthlyComplianceDetails.providedBy,
      type: 'STRING',
    });
  }

  if (monthlyComplianceDetails.paymentBy) {
    sectionData?.push(
      {
        label: 'Payment By',
        value: monthlyComplianceDetails.paymentBy,
        type: 'STRING',
      },
      {
        label: 'Payment Request Date',
        value: monthlyComplianceDetails.paymentRequestDate,
        type: 'DATE',
      },
      {
        label: 'Payment Completed Date',
        value: monthlyComplianceDetails.paymentCompletedDate,
        type: 'DATE',
      }
    );
  }

  return <Section title={`Compliance Details`} collapsible data={sectionData} />;
};

export default MonthlyWoodkraftComplianceDetailsSection;

import { Grid, Typography, useMediaQuery } from '@mui/material';
import { grey } from '@mui/material/colors';
import dayjs from 'dayjs';
import React from 'react';

import Section from 'components/Section';

export const SubcontractorCard: React.FC<any> = ({ people, action, onClick }) => {
  const { name, workStartDate, workEndDate, status } = people;
  const isMobileScreen = useMediaQuery('(max-width:600px)');

  return (
    <>
      <Section
        title={name}
        variant="outlined"
        onClick={onClick ? onClick : undefined}
        sx={{
          cursor: onClick ? 'pointer' : 'default',
          backgroundColor: status === 'INACTIVE' ? 'lightgray' : '',
        }}
        action={action}
      >
        <Grid container direction={'column'} rowGap={0.5}>
          <Grid container columns={5} alignItems="center">
            <Grid item xs={2} lg={1}>
              <Typography
                variant="caption"
                fontWeight={600}
                color={grey[700]}
                sx={{ marginRight: { xs: 1, md: 2 } }}
              >
                Start Date
              </Typography>
            </Grid>
            <Grid item xs={3} lg={4}>
              <Typography
                variant="caption"
                fontWeight={600}
                sx={{
                  ml: isMobileScreen ? 5 : 15,
                }}
              >
                {(dayjs(workStartDate).isValid() && dayjs(workStartDate).format('DD/MM/YYYY')) ||
                  workEndDate}
              </Typography>
            </Grid>
          </Grid>
          <Grid container columns={5} alignItems="center">
            <Grid item xs={2} lg={1}>
              <Typography
                variant="caption"
                fontWeight={600}
                color={grey[700]}
                sx={{ marginRight: { xs: 1, md: 2 } }}
              >
                End Date
              </Typography>
            </Grid>
            <Grid item xs={3} lg={4}>
              <Typography
                variant="caption"
                fontWeight={600}
                sx={{
                  ml: isMobileScreen ? 5 : 15,
                }}
              >
                {(dayjs(workEndDate).isValid() && dayjs(workEndDate).format('DD/MM/YYYY')) ||
                  workEndDate}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Section>
    </>
  );
};

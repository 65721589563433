import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';

import { Select } from 'components/Inputs/Select';
import { DynamicTextField } from 'components/Inputs/TextField';

import { emailRegex } from 'utils/regexes';

export const UPDATE_COMPLIANCE_CUSTOMER = gql`
  mutation UpdateComplianceCustomer($input: UpdateComplianceCustomerInput!) {
    updateComplianceCustomer(input: $input) {
      _id
    }
  }
`;

export enum COMPLIANCE_CUSTOMER_STATUS_ENUM {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

type UpdateCustomerFormProps = {
  people?: {
    _id: string;
    name: string;
    email: string;
    status?: string;
  } | null;
  complianceMilestoneId: string | null;
  refetch?: () => void;
  onClose: () => void;
};

export const UpdateCustomerForm = ({
  people,
  complianceMilestoneId,
  refetch,
  onClose,
}: UpdateCustomerFormProps) => {
  const [formState, setFormState] = useState<Record<string, any>>({});
  const [updateCustomer, { loading, error }] = useMutation(UPDATE_COMPLIANCE_CUSTOMER);

  const handleChanges = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const input = {
        complianceMilestoneId,
        customer: {
          _id: formState._id,
          name: formState.name,
          email: formState.email,
          status: formState.status,
        },
      };

      await updateCustomer({ variables: { input } });
      if (typeof refetch === 'function') {
        await refetch();
      }
      onClose();
    } catch (err) {
      console.error('Error updating customer:', err);
    }
  };

  useEffect(() => {
    setFormState({
      _id: people?._id ?? '',
      name: people?.name ?? '',
      email: people?.email ?? '',
      status: people?.status ?? COMPLIANCE_CUSTOMER_STATUS_ENUM.ACTIVE,
    });
  }, [people]);

  return (
    <form
      style={{ maxHeight: '50vh', padding: '0px 20px 20px 20px' }}
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columns={12} columnSpacing={2} rowGap={2} my={2}>
        {people?.status === COMPLIANCE_CUSTOMER_STATUS_ENUM.INACTIVE ? (
          <Grid item xs={12}>
            <Select
              label="Status"
              value={formState?.status ?? ''}
              onChange={val => handleChanges('status', val)}
              options={Object.keys(COMPLIANCE_CUSTOMER_STATUS_ENUM).map(status => ({
                label: status,
                value: COMPLIANCE_CUSTOMER_STATUS_ENUM[status],
              }))}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={6}>
              <DynamicTextField
                value={formState.name}
                onChange={e => handleChanges('name', e.target.value)}
                label="Name"
                fieldKey="name"
              />
            </Grid>
            <Grid item xs={6}>
              <DynamicTextField
                value={formState.email}
                onChange={e => handleChanges('email', e.target.value)}
                label="Email"
                fieldKey="email"
              />
            </Grid>
          </>
        )}
      </Grid>

      {error && (
        <Grid item xs={12}>
          <p style={{ color: 'red' }}>Error: {error.message}</p>
        </Grid>
      )}

      <Grid item xs={12} mt={2} textAlign="right">
        <LoadingButton
          loading={loading}
          variant="contained"
          type="submit"
          disabled={!emailRegex.test(formState.email)}
        >
          Update
        </LoadingButton>
      </Grid>
    </form>
  );
};

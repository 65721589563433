import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid';
import React, { useState } from 'react';

import DataGridTable from 'components/Table';

type EHSIncidentFactorsRowType = {
  _id: number;
  contributingFactor?: string;
  jobOrTask?: string;
  humanFactors?: string;
  organisationalFactor?: string;
};

const emptyRow = {
  _id: '',
  contributingFactor: '',
  jobOrTask: '',
  humanFactors: '',
  organisationalFactor: '',
};
const arrayOf4 = [emptyRow, emptyRow, emptyRow];

const EHSIncidentFactorsTable: React.FC<{
  initialRows?: Omit<EHSIncidentFactorsRowType, '_id'>[];
  onTableUpdate: (rows: EHSIncidentFactorsRowType[]) => void;
}> = ({ onTableUpdate, initialRows = arrayOf4 }) => {
  const [rows, setRows] = useState<EHSIncidentFactorsRowType[]>(
    initialRows.map((r, idx) => ({ ...r, _id: idx }))
  );

  const handleProcessRowUpdate = (newRow: GridRowModel) => {
    const newRows = rows.map(r => {
      if (r._id === newRow._id) return newRow;
      else return r;
    }) as EHSIncidentFactorsRowType[];

    setRows(newRows);
    onTableUpdate(newRows);
    return newRow;
  };

  const handleAddRow = () => {
    const newRow: EHSIncidentFactorsRowType = {
      ...emptyRow,
      _id: rows.length,
    };

    const newRows = [...rows, newRow];

    setRows(newRows);
    onTableUpdate(newRows);
  };

  return (
    <>
      <DataGridTable
        sx={{
          height: '32.5vh',
          overflowY: 'auto',
        }}
        columns={[
          {
            field: '_id',
            headerName: 'No.',
            valueGetter: (row: any) => row + 1,
            width: 5,
          },
          {
            field: 'contributingFactor',
            headerName: 'Contributing Factor',
            editable: true,
            flex: 1,
          },
          {
            field: 'jobOrTask',
            headerName: 'Job / Task',
            editable: true,
            flex: 1,
          },
          {
            field: 'humanFactors',
            headerName: 'Human Factors',
            editable: true,
            flex: 1,
          },
          {
            field: 'organisationalFactor',
            headerName: 'Organisation Factor',
            editable: true,
            flex: 1,
          },
        ]}
        getRowId={row => row._id}
        rows={rows}
        hideFooterPagination
        disableColumnMenu
        hideFooterSelectedRowCount
        processRowUpdate={handleProcessRowUpdate}
        disableColumnSorting
      />
      <Grid container position="absolute" bottom={10} right={10}>
        <Grid item sx={{ ml: 'auto' }} container justifyContent="flex-end" columnGap={1}>
          <Button
            onClick={handleAddRow}
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<AddIcon color="secondary" />}
          >
            New Factor
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default EHSIncidentFactorsTable;

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { FormControl, Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  GET_WON_LEADS_WITHOUT_PROJECT_QUERY,
  GetLeadsWithoutProjectQueryResponse,
} from 'graphql/query/lead';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import TextField from 'components/Inputs/TextField';
import Navbar from 'components/Navbar';

const CREATE_PROJECT_MUTATION = gql`
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      _id
      referenceId
      name
      lead {
        _id
        name
      }
      status
      startDate
      endDate
    }
  }
`;

type CreateProjectMutationResponse = {
  createProject: any;
};

type CreateProjectMutationVariables = {
  input: {
    name: string;
    lead: string;
    startDate: string;
    endDate: string;
  };
};

const CreateProject = () => {
  const [formState, setFormState] = useState<Record<string, any>>({});

  const navigate = useNavigate();

  const [getWonLeadsWithoutProject, { loading: loadingLeads, data }] =
    useLazyQuery<GetLeadsWithoutProjectQueryResponse>(GET_WON_LEADS_WITHOUT_PROJECT_QUERY);

  const [createProject, { loading: creatingProject }] = useMutation<
    CreateProjectMutationResponse,
    CreateProjectMutationVariables
  >(CREATE_PROJECT_MUTATION);

  const handleChange = (fieldName: string, val: any) => {
    setFormState({ ...formState, [fieldName]: val });
  };

  const handleSubmit = () => {
    createProject({
      variables: {
        input: {
          lead: formState.lead._id,
          name: formState.name,
          startDate: formState.startDate,
          endDate: formState.endDate,
        },
      },
      onCompleted: res =>
        navigate(`/projects/${res?.createProject?._id}?projectName=${res?.createProject?.name}`, {
          replace: true,
        }),
    });
  };

  return (
    <Navbar
      goBackButtonConfig={{
        title: 'Create Project',
      }}
    >
      <Grid container mt={2}>
        <Grid item sm={12} md={8} lg={6}>
          <form
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  type="string"
                  required
                  value={formState.name ?? ''}
                  onChange={e => handleChange('name', e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <AutocompleteWithFetch
                  fetch={getWonLeadsWithoutProject}
                  options={data?.getWonLeadsWithoutProject ?? []}
                  loading={loadingLeads}
                  required
                  value={formState.lead ?? ''}
                  handleChange={val => handleChange('lead', val)}
                  label="lead"
                />
              </Grid>
              <Grid container item columns={12} columnSpacing={2} rowGap={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={formState.startDate}
                        format="DD/MM/YYYY"
                        onChange={val => handleChange('startDate', val)}
                        label="Select start date"
                        disableFuture
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            fullWidth: true,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={formState.endDate}
                        format="DD/MM/YYYY"
                        onChange={val => handleChange('endDate', val)}
                        label="Select end date"
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            fullWidth: true,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} alignSelf="center" container justifyContent="end">
                <LoadingButton variant="contained" type="submit" loading={creatingProject}>
                  Create Project
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Navbar>
  );
};

export default CreateProject;

import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { FormControl, Grid, TextField, useMediaQuery } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { ProjectStatusEnum } from 'types';

import { Select } from 'components/Inputs/Select';

import { getUpdatedFields } from 'utils/common';
import { stringToDateFormatter } from 'utils/formatHelper';
import { formatDate } from 'utils/transformFn';

const UPDATE_PROJECT_MUTATION = gql`
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      _id
      name
      endDate
      status
    }
  }
`;

type UpdateProjectMutationResponse = {
  updateProject: any;
};

type UpdateProjectMutationVariables = {
  input: {
    _id: string;
    name: string;
    endDate: string;
    status: string;
  };
};

const UpdateProjectForm: React.FC<{ project: any; cb: () => void }> = ({ cb, project }) => {
  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const [updateProject, { loading }] = useMutation<
    UpdateProjectMutationResponse,
    UpdateProjectMutationVariables
  >(UPDATE_PROJECT_MUTATION);

  const [formState, setFormState] = useState<Record<string, any>>({
    name: project.name,
    status: project.status,
    endDate: dayjs(project.endDate),
  });

  const handleChange = (fieldName: string, val: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  const handleSubmit = () => {
    const endDate = formState.endDate;
    const refinedData = getUpdatedFields(project, formState);

    refinedData.endDate = formatDate(endDate);

    updateProject({
      variables: {
        input: {
          _id: project._id,
          ...(refinedData ?? {}),
        },
      },
      onCompleted: _ => cb(),
      onError: error => {
        console.error('GraphQL error:', error);
      },
    });
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container direction="column" rowGap={2} px={2} py={1.5}>
        <Grid container item xs={12} columnSpacing={2}>
          <Grid item xs={6}>
            <TextField
              value={formState.name ?? ''}
              onChange={e => handleChange('name', e.target.value)}
              label="Name"
              placeholder="Project Name"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={stringToDateFormatter(formState.endDate)}
                  format="DD/MM/YYYY"
                  onChange={val => handleChange('endDate', val)}
                  label="Project End Date"
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      fullWidth: true,
                    },
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Select
            options={Object.keys(ProjectStatusEnum).map(k => ({
              label: k,
              value: ProjectStatusEnum[k],
            }))}
            label="Project Status"
            value={project.status ?? ''}
            onChange={val => handleChange('status', val)}
          />
        </Grid>

        <Grid item xs={12} container justifyContent="end">
          <LoadingButton
            type="submit"
            fullWidth={isMobileScreen}
            loading={loading}
            disabled={loading || !!!Object.keys(getUpdatedFields(project, formState)).length}
            size={isMobileScreen ? 'medium' : 'large'}
            variant="contained"
          >
            Update
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default UpdateProjectForm;

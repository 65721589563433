import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  ComplianceChecklist,
  ComplianceDocument,
  DocStatusEnum,
  Folder,
  FrequencyEnum,
} from 'types';

import Section from 'components/Section';
import DataGridTable from 'components/Table';

import { getEnumKeyFromValue } from 'utils/transformFn';

const ComplianceChecklistTemplate: React.FC<{
  compliances: ComplianceChecklist[];
  toggleStandardChecklistTable: (arg: boolean) => void;
}> = ({ compliances, toggleStandardChecklistTable }) => {
  return (
    <Section
      title="Compliances Checklist"
      collapsible
      action={
        <AddIcon onClick={() => toggleStandardChecklistTable(true)} sx={{ cursor: 'pointer' }} />
      }
    >
      <ComplianceChecklistTable compliances={compliances} />
    </Section>
  );
};

const ComplianceChecklistTable: React.FC<{ compliances: ComplianceChecklist[] }> = ({
  compliances,
}) => {
  return (
    <DataGridTable
      columns={[
        {
          field: 'referenceId',
          headerName: 'ID',
          minWidth: 100,
          renderCell: params => <Link to={params.row._id}>{params.value}</Link>,
        },
        {
          field: 'docType',
          headerName: 'Acts (Subfolder)',
          minWidth: 170,
          valueGetter: (value: Folder) => value.name,
        },
        {
          field: 'docName',
          headerName: 'Doc Name',
          minWidth: 200,
        },

        {
          field: 'frequency',
          headerName: 'Frequency',
          minWidth: 150,
          valueFormatter: (value: FrequencyEnum) => getEnumKeyFromValue(FrequencyEnum, value),
        },
        {
          field: 'status',
          headerName: 'Status',
          minWidth: 150,
        },
        {
          field: 'pendingDocuments',
          headerName: 'Document Status',
          minWidth: 200,
          renderCell: params => <PendingDocStatusCell pendingDocuments={params.value} />,
        },
      ]}
      rows={compliances}
      getRowId={row => row._id}
      hideFooterPagination
      sx={{
        height: '80vh',
        maxWidth: '80vw',
        mx: 'auto',
        mt: 0.5,
      }}
    />
  );
};

export const PendingDocStatusCell: React.FC<{ pendingDocuments: ComplianceDocument[] }> = ({
  pendingDocuments,
}) => {
  const numOfPendingDocuments = pendingDocuments.filter(
    doc => doc.docStatus === DocStatusEnum.PENDING
  ).length;

  return (
    <Grid display="flex" height={'100%'} alignItems="center" columnGap={1}>
      <Typography
        sx={{
          p: 0.6,
          borderRadius: '50%',
          bgcolor: !!!numOfPendingDocuments ? 'lightgreen' : 'crimson',
        }}
      />
      <Typography variant="caption" letterSpacing={0.9}>
        {!!numOfPendingDocuments ? `You have ${numOfPendingDocuments} Overdues` : 'No Overdues'}
      </Typography>
    </Grid>
  );
};

export default ComplianceChecklistTemplate;

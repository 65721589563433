import SortIcon from '@mui/icons-material/Sort';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import { Button, Grid } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import extendedTheme from 'styles/theme';
import { LeadSortOptionEnum } from 'types';

import { SimplePopup } from 'components/Popup';

import { SortItem } from './Sort';

type SortType = LeadSortOptionEnum | undefined;

export const ComplianceSort: React.FC<{
  sort: SortType;
  setSort: (newSortValue: SortType) => void;
}> = ({ sort, setSort }) => {
  const [sortPopUp, toggleSortPopUp] = useState(false);

  return (
    <>
      <Button
        startIcon={<SortIcon />}
        variant="outlined"
        sx={{ backgroundColor: extendedTheme.palette.common.white }}
        onClick={() => toggleSortPopUp(true)}
        color="secondary"
        fullWidth
        size="small"
      >
        Sort
      </Button>
      <SimplePopup
        title="Sort By"
        open={sortPopUp}
        onClose={() => toggleSortPopUp(false)}
        fullWidth
      >
        <Grid container display={'flex'} direction={'column'} px={2} pb={3}>
          <SortItem
            icon={<SortByAlphaIcon fontSize="small" />}
            label="Alpabetically"
            states={[LeadSortOptionEnum.NameAToZ, LeadSortOptionEnum.NameZToA]}
            sort={sort}
            setSort={setSort}
            isLastItem
          />
        </Grid>
      </SimplePopup>
    </>
  );
};

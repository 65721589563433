import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { Box, Button, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { EHSChecklist, EHSDocument } from 'types';

import theme from 'components/Compliances/Sections/theme.module.scss';
import Fieldset from 'components/Fieldset';
import Section from 'components/Section';

import EhsMarkAsNoOverdueForm from '../Forms/EhsMarkAsNoOverdueForm';
import UploadEhsDocumentForm, { deletePendingDocumentCache } from '../Forms/UploadEHsDocument';

const EhsPendingDocumentsSection: React.FC<{
  ehsChecklist: EHSChecklist;
}> = ({ ehsChecklist }) => {
  return (
    <Section title="Pending Tasks" collapsible>
      {ehsChecklist.pendingDocuments.length === 0 ? (
        <Grid item minHeight="10vh" container justifyContent="center" alignItems="center" xs={12}>
          <Typography variant="caption">No Pending Documents</Typography>
        </Grid>
      ) : (
        <Grid container direction="column" rowGap={1} mb={5} pr={2}>
          {ehsChecklist.pendingDocuments.map(pd => (
            <DueDateItem ehsDocument={pd} key={pd._id} />
          ))}
        </Grid>
      )}
    </Section>
  );
};

const DueDateItem: React.FC<{ ehsDocument: EHSDocument }> = ({ ehsDocument }) => {
  const [showUploadForm, toggleUploadForm] = useState(false);
  const [showNoOverdueForm, toggleNoOverdueForm] = useState(false);
  const { ehsChecklistId = '' } = useParams<{ ehsChecklistId: string }>();

  if (showUploadForm || showNoOverdueForm)
    return (
      <Grid pt={1.3} md={10} lg={8}>
        <div className={theme.div1}>
          <Fieldset
            label={`For Due Date ${dayjs(ehsDocument.dueDate).format('DD/MM/YYYY')}`}
            variant="small"
          >
            {showUploadForm && (
              <Box px={0.6} py={0.5}>
                <UploadEhsDocumentForm
                  ehsDocument={ehsDocument}
                  onCancel={() => toggleUploadForm(false)}
                />
              </Box>
            )}
            {showNoOverdueForm && (
              <Box px={0.6} pt={1.3} pb={0.5}>
                <EhsMarkAsNoOverdueForm
                  ehsDocument={ehsDocument}
                  onCancel={() => toggleNoOverdueForm(false)}
                  cb={() => deletePendingDocumentCache(ehsChecklistId, ehsDocument._id)}
                />
              </Box>
            )}
          </Fieldset>
        </div>
      </Grid>
    );

  return (
    <Grid
      container
      md={10}
      lg={8}
      direction="column"
      rowGap={1}
      sx={{
        border: '1px solid lightgrey',
        borderRadius: '4px',
        px: 1,
        py: 1.3,
      }}
    >
      <Grid item container xs={12} fontSize={15} alignItems="center" columnGap={0.5}>
        <WatchLaterIcon fontSize="inherit" color="error" />
        <Typography fontSize={12} fontWeight={600} letterSpacing={1}>
          Due Date: {dayjs(ehsDocument.dueDate).format('DD/MM/YYYY')}
        </Typography>
      </Grid>
      <Grid item xs={12} container columnGap={1}>
        <Button
          size="small"
          sx={{
            fontSize: 10,
            fontWeight: 600,
          }}
          variant="outlined"
          onClick={() => toggleNoOverdueForm(true)}
        >
          Not Required
        </Button>
        <Button
          size="small"
          color="secondary"
          sx={{
            fontSize: 10,
            fontWeight: 600,
          }}
          variant="contained"
          onClick={() => toggleUploadForm(true)}
        >
          Upload Document
        </Button>
      </Grid>
    </Grid>
  );
};

export default EhsPendingDocumentsSection;

import { NetworkStatus, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { GET_WOODKRAFT_MONTHLY_COMPLIANCE_DOC_DETAILS_QUERY } from 'graphql/query/compliance';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import MonthlyWoodkraftComplianceDetailsSection from 'components/_Compliances/FileSections/WoodkraftMonthly/MonthlyWoodkraftComplianceDetailsSection';
import PendingDocumentsSection from 'components/_Compliances/FileSections/WoodkraftMonthly/PendingDocumentsSection';
import UploadedComplianceDocumentsSection from 'components/_Compliances/FileSections/WoodkraftMonthly/UploadedComplianceDocuments';

function WoodkraftMonthlyComplianceFileDetailsPage() {
  const { complianceId = '' } = useParams<{ complianceId: string }>();

  const { data, networkStatus } = useQuery(GET_WOODKRAFT_MONTHLY_COMPLIANCE_DOC_DETAILS_QUERY, {
    variables: {
      id: complianceId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const loadingComplianceDetails =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!data;

  return (
    <Navbar goBackButtonConfig={{ title: 'Compliance details' }}>
      {loadingComplianceDetails ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid item container direction={'column'} xs={12} md={11} lg={9} xl={7} mb={5} rowGap={2.5}>
          <MonthlyWoodkraftComplianceDetailsSection
            monthlyComplianceDetails={data.getMonthlyComplianceDocById}
          />

          <PendingDocumentsSection monthlyComplianceDetails={data.getMonthlyComplianceDocById} />

          <UploadedComplianceDocumentsSection
            monthlyComplianceDetails={data.getMonthlyComplianceDocById}
          />
        </Grid>
      )}
    </Navbar>
  );
}

export default WoodkraftMonthlyComplianceFileDetailsPage;

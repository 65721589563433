import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import LoadingIndicator from 'components/LoadingIndicator';

import { emailRegex, phoneNumberRegex, textOnlyRegex } from 'utils/regexes';

type TextFieldProps = {
  loading?: boolean;
  setError?: (arg: any) => void;
} & MuiTextFieldProps;

type NumberInputFieldProps = {
  loading?: boolean;
  isPercentage?: boolean;
  showErrorBgColor?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setError?: (error: boolean) => void;
} & MuiTextFieldProps;

const TextField: React.FC<TextFieldProps> = ({ loading = false, ...props }) => {
  return (
    <FormControl fullWidth>
      <MuiTextField
        FormHelperTextProps={{
          sx: {
            ml: 0.5,
          },
        }}
        {...props}
        InputProps={
          loading
            ? {
                endAdornment: (
                  <InputAdornment position="start">
                    <LoadingIndicator size="0.9rem" />
                  </InputAdornment>
                ),
              }
            : props.InputProps
        }
      />
    </FormControl>
  );
};

export const ContactTextField: React.FC<TextFieldProps> = ({
  loading = false,
  onChange: handleChange,
  setError,
  ...props
}) => {
  const [isInvalid, toggleInvalid] = useState(false);

  const checkVal = (val: string) => {
    if (!!val && !phoneNumberRegex.test(String(val))) {
      toggleInvalid(true);
      setError && setError(true);
    } else {
      toggleInvalid(false);
      setError && setError(false);
    }
  };

  return (
    <FormControl fullWidth>
      <MuiTextField
        {...props}
        onChange={e => {
          if (handleChange) {
            if (/^[0-9]+$/.test(e.target.value)) {
              handleChange(e);
            } else {
              e.target.value = '';
              handleChange(e);
            }
            checkVal(e.target.value);
          }
        }}
        type="tel"
        error={isInvalid}
        InputProps={
          loading
            ? {
                endAdornment: (
                  <InputAdornment position="start">
                    <LoadingIndicator size="0.9rem" />
                  </InputAdornment>
                ),
              }
            : undefined
        }
      />
      {isInvalid && (
        <Typography color="crimson" fontSize={11} ml={0.5}>
          Please enter valid number
        </Typography>
      )}
    </FormControl>
  );
};

export const MultiTextInput: React.FC<
  { values: string[]; setValues: (newArr: string[]) => void } & TextFieldProps
> = ({ values, setValues, ...props }) => {
  const [localValue, setLocalValue] = useState('');

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && localValue.trim() !== '') {
      setValues([...values, localValue]);
      setLocalValue('');
    }
  };

  const handleOnBlur = () => {
    if (localValue.trim() !== '') {
      setValues([...values, localValue]);
      setLocalValue('');
    }
  };

  const handleDeleteName = (valueToDelete: string) => {
    const newValues = values.filter(currName => {
      if (currName === valueToDelete) {
        return false;
      } else {
        return true;
      }
    });

    setValues(newValues);
  };

  return (
    <Grid container rowGap={1.5}>
      {values.length !== 0 && (
        <Box display="flex" columnGap={1}>
          {values.map((name, index) => (
            <Chip
              key={index}
              label={name}
              onDelete={() => handleDeleteName(name)}
              color="default"
              size="small"
            />
          ))}
        </Box>
      )}
      <TextField
        value={localValue}
        onBlur={handleOnBlur}
        onChange={e => setLocalValue(e.target.value)}
        onKeyDown={e => handleKeyDown(e)}
        {...props}
      />
    </Grid>
  );
};

export const NumberInputField: React.FC<NumberInputFieldProps> = ({
  loading = false,
  isPercentage = false,
  showErrorBgColor = false,
  onChange: handleChange,
  setError,
  ...props
}) => {
  const [isInvalid, toggleInvalid] = useState(false);

  // Regex for valid number input
  const numberRegex = isPercentage
    ? /^(100(\.0{1,2})?|[0-9]{1,2}(\.[0-9]{1,2})?)$/
    : /^[0-9]+(\.[0-9]{1,2})?$/;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;

    // Check if the value is valid so far (e.g., allow intermediate states like "." or "0.")
    if (!/^[0-9]*\.?[0-9]*$/.test(val)) {
      return; // Ignore invalid characters without clearing the field
    }

    // Validate the complete value based on the regex
    if (handleChange) {
      handleChange(e); // Trigger parent change handler
    }

    if (val === '' || numberRegex.test(val)) {
      toggleInvalid(false);
      setError && setError(false);
    } else {
      toggleInvalid(true);
      setError && setError(true);
    }
  };

  return (
    <FormControl fullWidth>
      <MuiTextField
        {...props}
        onChange={handleInputChange}
        type="text"
        error={isInvalid}
        InputProps={
          loading
            ? {
                endAdornment: (
                  <InputAdornment position="start">
                    <LoadingIndicator size="0.9rem" />
                  </InputAdornment>
                ),
              }
            : props.InputProps
        }
        sx={{
          backgroundColor: showErrorBgColor && isInvalid ? '#db3d3d5e' : undefined,
        }}
      />
      {isInvalid && showErrorBgColor === false && (
        <Typography color="crimson" fontSize={11} ml={0.5}>
          Please enter a valid number
          {isPercentage ? ' (0 to 100 with up to 2 decimals)' : ' (positive with up to 2 decimals)'}
        </Typography>
      )}
    </FormControl>
  );
};

const forms = {
  email: {
    errorMsg: 'Please enter a valid email address',
    regexPattern: emailRegex,
    type: 'email',
  },
  name: {
    errorMsg: 'Please enter a valid name',
    regexPattern: textOnlyRegex,
    type: 'text',
  },
  phone: {
    errorMsg: 'Please enter a valid phone number',
    regexPattern: phoneNumberRegex,
    type: 'tel',
  },
  // dont use the below validation objects, not test yet!
  positiveNumber: {
    errorMsg: 'Please enter a valid number',
    regexPattern: textOnlyRegex,
    type: 'num',
  },
  decimalNumber: {
    errorMsg: 'Please enter a valid number',
    regexPattern: textOnlyRegex,
    type: 'num',
  },
  percentageNumber: {
    errorMsg: 'Please enter a valid number',
    regexPattern: textOnlyRegex,
    type: 'num',
  },
} as const;

// Type to infer all formMsg keys
type FormMsgKeys = keyof typeof forms;

interface DynamicTextFieldProps {
  fieldKey: FormMsgKeys;
  loading?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  [key: string]: any;
}

export const DynamicTextField = ({
  fieldKey,
  loading = false,
  onChange: handleChange,
  ...props
}: DynamicTextFieldProps) => {
  const [isInvalid, toggleInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validationRules = forms[fieldKey];

  const checkVal = (val: string) => {
    if (validationRules && !!val && !validationRules.regexPattern.test(val)) {
      toggleInvalid(true);
      setErrorMessage(validationRules.errorMsg);
    } else {
      toggleInvalid(false);
      setErrorMessage('');
    }
  };

  return (
    <FormControl fullWidth>
      <MuiTextField
        {...props}
        type={validationRules?.type || 'text'}
        onChange={(e: any) => {
          if (handleChange) {
            handleChange(e);
          }
          checkVal(e.target.value);
        }}
        error={isInvalid}
        InputProps={
          loading
            ? {
                endAdornment: (
                  <InputAdornment position="start">
                    <LoadingIndicator size="0.9rem" />
                  </InputAdornment>
                ),
              }
            : undefined
        }
      />
      {isInvalid && (
        <Typography color="crimson" fontSize={11} ml={0.5}>
          {errorMessage}
        </Typography>
      )}
    </FormControl>
  );
};

export default TextField;

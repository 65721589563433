import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { Box, Button, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { ComplianceCurrentStatusEnum } from 'types';

import Fieldset from 'components/Fieldset';
import Section from 'components/Section';

import UploadComplianceDocumentForm from './Forms/UploadComplianceDocumentForm';
import theme from './theme.module.scss';

const PendingDocumentsSection: React.FC<{
  complianceChecklist: any;
  licenseDetails: any;
  currentStatus: any;
}> = ({ complianceChecklist }) => {
  return (
    <Section title="Pending Tasks" collapsible>
      {complianceChecklist?.currentStatus === ComplianceCurrentStatusEnum.COMPLETED && (
        <Grid item minHeight="4vh" container justifyContent="center" alignItems="center" xs={12}>
          <Typography variant="subtitle2">No Pending Tasks</Typography>
        </Grid>
      )}

      <Grid container direction="column" rowGap={1} mb={5} pr={2}>
        {complianceChecklist?.currentStatus === ComplianceCurrentStatusEnum.STARTED && (
          <UploadItem
            complianceDocument={complianceChecklist}
            endDate={complianceChecklist?.endDate}
            btnTitle={'Application Document'}
          />
        )}

        {complianceChecklist?.currentStatus === ComplianceCurrentStatusEnum?.APPLIED && (
          <UploadItem
            complianceDocument={complianceChecklist}
            endDate={complianceChecklist?.endDate}
            btnTitle={'Final Document'}
          />
        )}

        {complianceChecklist?.currentStatus ===
          ComplianceCurrentStatusEnum['SENT TO CUSTOMER FOR APPROVAL'] && (
          <UploadItem
            complianceDocument={complianceChecklist}
            endDate={complianceChecklist?.endDate}
            btnTitle={'License Approval Document'}
          />
        )}

        {/* Revalidations */}

        {complianceChecklist?.latestPendingRevalidation?.revalidationStatus ===
          ComplianceCurrentStatusEnum['STARTED'] && (
          <UploadItem
            complianceDocument={complianceChecklist}
            btnTitle={'Apply License Revalidation'}
          />
        )}

        {complianceChecklist?.latestPendingRevalidation?.revalidationStatus ===
          ComplianceCurrentStatusEnum['APPLIED'] && (
          <UploadItem
            complianceDocument={complianceChecklist}
            btnTitle={'License Revalidation'} // Upload License Revalidation
          />
        )}

        {complianceChecklist?.latestPendingRevalidation?.revalidationStatus ===
          ComplianceCurrentStatusEnum['SENT TO CUSTOMER FOR APPROVAL'] && (
          <UploadItem
            complianceDocument={complianceChecklist}
            btnTitle={'Approve License Revalidation'}
          />
        )}
      </Grid>
    </Section>
  );
};

const UploadItem: React.FC<{ complianceDocument: any; btnTitle: string; endDate?: any }> = ({
  complianceDocument,
  btnTitle,
  endDate,
}) => {
  const [showUploadForm, toggleUploadForm] = useState(false);

  if (showUploadForm)
    return (
      <Grid pt={1.3} md={10} lg={8}>
        <div className={theme.div1}>
          <Fieldset
            label={`For Due Date ${endDate ? dayjs(endDate).format('DD/MM/YYYY') : 'N/A'}`}
            variant="small"
          >
            {showUploadForm && (
              <Box px={0.6} py={0.5}>
                <UploadComplianceDocumentForm
                  complianceDocument={complianceDocument}
                  onCancel={() => toggleUploadForm(false)}
                  btnTitle={btnTitle}
                />
              </Box>
            )}
          </Fieldset>
        </div>
      </Grid>
    );

  return (
    <Grid
      container
      md={10}
      lg={8}
      direction="column"
      rowGap={1}
      sx={{
        border: '1px solid lightgrey',
        borderRadius: '4px',
        px: 1,
        py: 1.3,
      }}
    >
      <Grid item container xs={12} fontSize={15} alignItems="center" columnGap={0.5}>
        <WatchLaterIcon fontSize="inherit" color="error" />
        <Typography fontSize={12} fontWeight={600} letterSpacing={1}>
          End Date: {endDate ? dayjs(endDate).format('DD/MM/YYYY') : 'N/A'}
        </Typography>
      </Grid>

      <Grid item xs={12} container columnGap={1}>
        <Button
          size="small"
          color="secondary"
          sx={{
            fontSize: 10,
            fontWeight: 600,
          }}
          variant="contained"
          onClick={() => toggleUploadForm(true)}
        >
          Upload {btnTitle}
        </Button>
      </Grid>
    </Grid>
  );
};

export default PendingDocumentsSection;

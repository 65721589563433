import { Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Navbar from 'components/Navbar';

const ResponsiveCard = ({ title, onClick }: { title: string; onClick: () => void }) => {
  return (
    <Card
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: '#fff',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '12px',
        padding: '10px',
        margin: '16px',
        cursor: 'pointer',
      }}
    >
      <CardContent>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};

const RateAnalysisPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Navbar title="Rate Analysis ">
      <Grid
        container
        direction="row"
        columns={3}
        rowSpacing={3}
        columnSpacing={5}
        alignItems="center"
        mb={5}
        pr={2}
      >
        <Grid item lg={1} xs={12} width={'100%'}>
          <ResponsiveCard
            onClick={() => navigate(`/rateanalysis/templates`)}
            title="Rate Analysis Templates"
          />
        </Grid>
        <Grid item lg={1} xs={12} width={'100%'}>
          <ResponsiveCard
            onClick={() => navigate(`/rateanalysis/conversion-table`)}
            title="Conversion Table"
          />
        </Grid>
      </Grid>
    </Navbar>
  );
};

export default RateAnalysisPage;

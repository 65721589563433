import { Grid } from '@mui/material';

import ComplianceCustomers from './Customers';
import MilestonesSection from './Milestones';
import ComplianceSubcontractors from './Subcontractors';
import WKSiteCompliance from './WKSiteCompliance';

const ContactsDetailsSections = ({ complianceMilestoneContactDetails, loading, refetch }) => {
  const {
    customerContacts,
    wkSiteComplianceContacts,
    subContractorInfo,
    _id: complianceMilestoneId,
  } = complianceMilestoneContactDetails || {};

  return (
    <Grid item container rowGap={2.5}>
      <MilestonesSection
        milestoneDetails={complianceMilestoneContactDetails}
        loading={loading}
        refetch={refetch}
      />
      <ComplianceCustomers
        customerContacts={customerContacts}
        complianceMilestoneId={complianceMilestoneId}
        loading={loading}
        refetch={refetch}
      />
      <WKSiteCompliance
        complianceMilestoneId={complianceMilestoneId}
        wkSiteComplianceContacts={wkSiteComplianceContacts}
        loading={loading}
        refetch={refetch}
      />
      <ComplianceSubcontractors
        complianceMilestoneId={complianceMilestoneId}
        subContractorInfo={subContractorInfo}
        loading={loading}
        refetch={refetch}
      />
    </Grid>
  );
};

export default ContactsDetailsSections;

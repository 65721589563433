import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Add, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Radio, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MultiSelect } from 'components/Inputs/Select';
import { DynamicTextField } from 'components/Inputs/TextField';

const GET_PROJECT_PEOPLE_QUERY = gql`
  query GetProjectPeople($project: ID!) {
    getProjectPeople(project: $project) {
      _id
      name
      email
    }
  }
`;

const ADD_COMPLIANCE_CUSTOMERS_MUTATION = gql`
  mutation AddComplianceCustomers($input: AddComplianceCustomersInput!) {
    addComplianceCustomers(input: $input) {
      _id
    }
  }
`;

export const CustomerForm = ({
  formTitle,
  refetch,
  onClose,
}: {
  formTitle: { title: string; btnText: string };
  refetch?: () => void;
  onClose: () => void;
}) => {
  const [formState, setFormState] = useState<{
    fieldType: 'existing_customer' | 'new_customer';
    followupContact: string[];
  }>({
    fieldType: 'existing_customer',
    followupContact: [],
  });

  const { projectId = '' } = useParams<{ projectId: string }>();

  const [getProjectPeople, { data: projectPeople, loading: loadingProjectPeople }] = useLazyQuery<{
    getProjectPeople: { _id: string; name: string; email: string }[];
  }>(GET_PROJECT_PEOPLE_QUERY);

  const [addComplianceCustomers, { loading: addingCustomers }] = useMutation(
    ADD_COMPLIANCE_CUSTOMERS_MUTATION,
    {
      onCompleted: () => {
        if (refetch) refetch();
        onClose();
      },
    }
  );

  const [newRowItems, setNewRowItems] = useState<{ name: string; email: string }[]>([
    {
      name: '',
      email: '',
    },
  ]);

  const handleChanges = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleFormChanges = (index: number, key: string, value: any) => {
    const updatedItems = newRowItems.map((item, i) =>
      i === index ? { ...item, [key]: value } : item
    );
    setNewRowItems(updatedItems);
  };

  const handleAdd = () => {
    setNewRowItems([...newRowItems, { name: '', email: '' }]);
  };

  const handleDelete = (index: number) => {
    const updatedItems = newRowItems.filter((_, i) => i !== index);
    setNewRowItems(updatedItems);
  };

  const selectedData = formState.followupContact || [];
  const apiData = projectPeople?.getProjectPeople || [];
  const updatedArray = apiData.filter(item => selectedData.includes(item._id));

  const handleSubmit = () => {
    if (formState.fieldType === 'existing_customer') {
      if (updatedArray.length === 0) {
        return;
      }
      addComplianceCustomers({
        variables: {
          input: {
            project: projectId,
            customers: updatedArray.map(({ name, email }) => ({ name, email })),
          },
        },
      });
    } else if (formState.fieldType === 'new_customer') {
      const hasEmptyFields = newRowItems.some(
        item => item.name.trim() === '' || item.email.trim() === ''
      );
      if (hasEmptyFields) {
        return;
      }
      addComplianceCustomers({
        variables: {
          input: {
            project: projectId,
            customers: newRowItems,
          },
        },
      });
    }
  };

  return (
    <form
      style={{ maxHeight: '50vh', padding: '20px' }}
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container alignItems="center" spacing={2} mt={-3}>
        <Grid item>
          <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <Radio
              checked={formState.fieldType === 'existing_customer'}
              onChange={e => handleChanges('fieldType', e.target.value)}
              value="existing_customer"
              size="small"
            />
            <Typography fontWeight={600}>Select Existing Customer</Typography>
          </label>
        </Grid>
        <Grid item>
          <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <Radio
              checked={formState.fieldType === 'new_customer'}
              onChange={e => handleChanges('fieldType', e.target.value)}
              value="new_customer"
              size="small"
            />
            <Typography fontWeight={600}>Add New</Typography>
          </label>
        </Grid>

        {formState.fieldType === 'existing_customer' && (
          <>
            <Grid item xs={12}>
              <MultiSelect
                label="Select Contact Persons"
                value={formState.followupContact}
                handleChange={val => handleChanges('followupContact', val)}
                options={
                  apiData.map(person => ({
                    label: person.name,
                    value: person._id,
                  })) || []
                }
                fetchConfig={{
                  fetchFn: getProjectPeople,
                  loading: loadingProjectPeople,
                  variables: { project: projectId },
                }}
              />
            </Grid>
            {updatedArray.length > 0 && (
              <Grid item xs={12}>
                <Typography fontWeight={600}>Selected Contacts</Typography>
                {updatedArray.map(item => (
                  <Grid container key={item._id} spacing={2} pt={1}>
                    <Grid item xs={5.5} ml={1}>
                      <Typography>{item.name}</Typography>
                    </Grid>
                    <Grid item xs={5.5} ml={1}>
                      <Typography>{item.email}</Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
          </>
        )}

        {formState.fieldType === 'new_customer' &&
          newRowItems.map((item, index) => (
            <Fragment key={index}>
              <Grid container alignItems="center" spacing={2} ml={1}>
                <Grid item xs={11}>
                  <Typography variant="body2" fontWeight={600} mb={1.5} mt={2}>
                    Person {index + 1}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {index === newRowItems.length - 1 && (
                    <Add onClick={handleAdd} style={{ cursor: 'pointer' }} />
                  )}
                </Grid>
              </Grid>

              <Grid container alignItems="center" spacing={2} ml={1}>
                <Grid item xs={5.5}>
                  <Box sx={{ mb: 1, minHeight: '70px' }}>
                    {' '}
                    <DynamicTextField
                      value={item.name}
                      onChange={e => handleFormChanges(index, 'name', e.target.value)}
                      label="Name"
                      required
                      fullWidth
                      fieldKey="name"
                    />
                  </Box>
                </Grid>

                <Grid item xs={5.5}>
                  <Box sx={{ mb: 1, minHeight: '70px' }}>
                    {' '}
                    <DynamicTextField
                      value={item.email}
                      onChange={e => handleFormChanges(index, 'email', e.target.value)}
                      label="Email"
                      required
                      fullWidth
                      fieldKey="email"
                    />
                  </Box>
                </Grid>

                <Grid item mr={-5}>
                  {newRowItems.length > 1 && (
                    <Delete onClick={() => handleDelete(index)} style={{ cursor: 'pointer' }} />
                  )}
                </Grid>
              </Grid>
            </Fragment>
          ))}

        <Grid item xs={12} textAlign="right">
          <LoadingButton loading={addingCustomers} variant="contained" type="submit">
            {formTitle.btnText}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

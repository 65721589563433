import { useMutation } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import RestoreIcon from '@mui/icons-material/Restore';
import WarningIcon from '@mui/icons-material/Warning';
import { Grid, Typography } from '@mui/material';
import { UPDATE_COMPLIANCE_SUB_CONTRACTOR_INFO_MUTATION } from 'graphql/mutation/_compliance';
import { useState } from 'react';

import LoadingIndicator from 'components/LoadingIndicator';
import { ConfirmationPopup, SimplePopup } from 'components/Popup';
import Section from 'components/Section';

import { SubcontractorCard } from './Card';
import { SubcontractorsComplianceForm } from './SubcontractorsComplianceForm';

const ComplianceSubcontractors = ({
  loading,
  subContractorInfo,
  complianceMilestoneId,
  refetch,
}) => {
  const [toggleForm, setToggleForm] = useState(false);
  const [toggleDeleteForm, setToggleDeleteForm] = useState(false);
  const [toggleRevertForm, setToggleRevertForm] = useState(false);
  const [selectedSubContractor, setSelectedSubContractor] = useState<any | null>(null);

  const [updateComplianceSubContractorInfo, { loading: updatingSubcontractor }] = useMutation(
    UPDATE_COMPLIANCE_SUB_CONTRACTOR_INFO_MUTATION
  );

  const [formTitle, setFormTitle] = useState({
    title: '',
    btnText: '',
  });

  return (
    <>
      <Section
        endAdornment={
          (subContractorInfo?.length === 0 || !subContractorInfo) && (
            <>
              <WarningIcon
                fontSize="small"
                sx={{
                  color: 'yellow',
                }}
              />
              <Typography fontWeight={800}>No Information found</Typography>
            </>
          )
        }
        title="Subcontractors"
        collapsible
        defaultCollapsed
        action={
          <PersonAddIcon
            onClick={() => {
              setToggleForm(true);
              setFormTitle({
                title: 'Add Subcontractor',
                btnText: 'Add',
              });
            }}
            fontSize="small"
            sx={{ cursor: 'pointer' }}
          />
        }
      >
        {loading ? (
          <Grid>
            <LoadingIndicator size="1.6rem" />
          </Grid>
        ) : (
          <Grid
            container
            columns={2}
            rowSpacing={2}
            columnSpacing={3}
            alignItems="center"
            pb={2}
            pr={1}
            overflow="auto"
            mt={-1}
          >
            {subContractorInfo?.map(pp => (
              <Grid item key={pp.referenceId} xs={2} md={1} lg={1}>
                <SubcontractorCard
                  people={pp}
                  onClose={() => {
                    setToggleForm(false);
                    setSelectedSubContractor(null);
                  }}
                  open={toggleForm}
                  formTitle={formTitle}
                  action={
                    <>
                      {pp?.status === 'ACTIVE' || pp?.status === null ? (
                        <>
                          <DeleteIcon
                            fontSize="small"
                            onClick={() => {
                              setSelectedSubContractor(pp);
                              setToggleDeleteForm(true);
                            }}
                            sx={{ cursor: 'pointer' }}
                          />
                          <EditNoteIcon
                            fontSize="small"
                            onClick={() => {
                              setToggleForm(true);
                              setSelectedSubContractor(pp);
                              setFormTitle({
                                title: 'Update WK Site Compliance',
                                btnText: 'Update',
                              });
                            }}
                            sx={{ cursor: 'pointer' }}
                          />
                        </>
                      ) : (
                        <RestoreIcon
                          fontSize="small"
                          onClick={() => {
                            setToggleRevertForm(true);
                            setSelectedSubContractor(pp);
                          }}
                          sx={{ cursor: 'pointer' }}
                        />
                      )}
                    </>
                  }
                />
              </Grid>
            ))}
            {(subContractorInfo?.length === 0 || !subContractorInfo) && (
              <Grid item my="auto" mt={2} container justifyContent="center">
                <Typography variant="subtitle2">No Subcontractor has been added yet.</Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Section>
      <SimplePopup
        onClose={() => {
          setToggleForm(false);
          setSelectedSubContractor(null);
        }}
        open={toggleForm}
        title={formTitle.title}
        fullWidth
      >
        <SubcontractorsComplianceForm
          setToggleForm={setToggleForm}
          formTitle={formTitle}
          subContractorInfo={selectedSubContractor}
          complianceMilestoneId={complianceMilestoneId}
          refetch={refetch}
        />
      </SimplePopup>
      <ConfirmationPopup
        onConfirmation={() => {
          updateComplianceSubContractorInfo({
            variables: {
              input: {
                complianceMilestoneId,
                subContractorInfo: {
                  _id: selectedSubContractor?._id,
                  status: 'INACTIVE',
                },
              },
            },
            onCompleted: _ => {
              setToggleDeleteForm(false);
              setSelectedSubContractor(null);
              refetch();
            },
          });
        }}
        onClose={() => {
          setToggleDeleteForm(false);
          setSelectedSubContractor(null);
        }}
        open={toggleDeleteForm}
        fullWidth
        title="Do you want to delete subcontractor ?"
        confirmationLabel="Confirm"
        loading={updatingSubcontractor}
      />
      <ConfirmationPopup
        onConfirmation={() => {
          updateComplianceSubContractorInfo({
            variables: {
              input: {
                complianceMilestoneId,
                subContractorInfo: {
                  _id: selectedSubContractor?._id,
                  status: 'ACTIVE',
                },
              },
            },
            onCompleted: _ => {
              setToggleRevertForm(false);
              setSelectedSubContractor(null);
              refetch();
            },
          });
        }}
        onClose={() => {
          setToggleRevertForm(false);
          setSelectedSubContractor(null);
        }}
        open={toggleRevertForm}
        fullWidth
        title="Would you like to revert your changes ?"
        confirmationLabel="Confirm"
        loading={updatingSubcontractor}
      />
    </>
  );
};

export default ComplianceSubcontractors;

import { gql } from '@apollo/client';
import { EHSChecklist, FrequencyEnum } from 'types';

export const EHS_CHECKLIST_QUERY = gql`
  query GetEhsChecklist($project: ID!, $filter: EhsChecklistFilter) {
    getEhsChecklist(project: $project, filter: $filter) {
      _id
      assignTo {
        _id
        empId
        lastName
        firstName
      }
      docName
      docType {
        _id
        name
      }
      frequency
      referenceId
      status
      pendingDocuments {
        _id
        referenceId
        docStatus
        status
        remarks
        dueDate
      }
    }
  }
`;

export type EHSChecklistQueryResponse = {
  getEhsChecklist: EHSChecklist[];
};

export type EHSChecklistQueryVariables = {
  project: string;
  filter?: {
    frequency?: FrequencyEnum;
    searchTerm?: string;
    status?: string[];
  };
};

export const GET_EHS_CHECKLIST_BY_ID = gql`
  query GetEhsChecklistDetailsById($id: ID!) {
    getEhsChecklistDetailsById(_id: $id) {
      _id
      referenceId
      docName
      docType {
        _id
        name
      }
      frequency
      notificationLeadDays
      assignTo {
        _id
        empId
        firstName
        lastName
      }
      project {
        _id
        name
      }
      scheduleStarts
      scheduleEnds
      status
      pendingDocuments {
        _id
        referenceId
        docStatus
        status
        remarks
        dueDate
      }
      uploadedDocuments {
        _id
        docStatus
        files {
          _id
          referenceId
          name
          path
          metadata
        }
      }
      incidentReports {
        _id
        address
        age
        referenceNo
        description
        idNumber
        gender
        immediateActions
        incidentDate
        incidentDetails
        incidentHeading
        incidentTime
        incidentType
        isPpeCondition
        isUnsafeAct
        isWearingPPE
        location
        name
        organizationName
        project
        projectHeadName
        projectManagerName
        referenceId
        returnFromHospitalDate
        returnFromHospitalTime
        returnToWorkDate
        safetyHeadName
        safetyOfficerName
        siteEngineerName
        workStartedTime
      }
      investigationReports {
        _id
        referenceNo
        analysis {
          date
          proposedCorrectiveActions
          responsiblePerson
        }
        backgroundInformation
        bodyPartAffected
        details
        ehsDocument {
          _id
        }
        factorType {
          contributingFactor
          humanFactors
          jobOrTask
          organisationalFactor
        }
        followUp
        immediateAction
        incidentDate
        incidentTime
        incidentType
        location
        nameOfWitnesses
        namesOfPersonsInvolved
        observationOrInitialCause
        reportedTo
        reviewedBy {
          amendmentToHSESystem
          localHSEAdvisor
          locationManager
          nationalSafetyManager
          safetyAlertReleased
        }
        rootCause
        pdsRole
      }
    }
  }
`;

export type GetEhsChecklistByIdResponse = {
  getEhsChecklistDetailsById: EHSChecklist;
};

export type GetEhsChecklistByIdVariables = {
  id: string;
};

import { NetworkStatus, gql, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Project } from 'types';

import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import ProjectsTemplate from 'components/Projects/ProjectsTemplate';

const GET_PROJECTS_QUERY = gql`
  query GetProjects($filter: ProjectFilter) {
    getProjects(filter: $filter) {
      _id
      name
      referenceId
      lead {
        _id
        name
        segment
        scope
        areaOrPower
        areaOrPowerUOM
        approxValue
        leadStatus
      }
      status
    }
  }
`;

type GetProjectsQueryResponse = {
  getProjects: Project[];
};

type GetProjectsQueryVariables = {
  filter?: {
    searchTerm?: string;
    status?: string[];
  };
};

const ProjectPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});

  const { data: projects, networkStatus: projectNetworkStatus } = useQuery<
    GetProjectsQueryResponse,
    GetProjectsQueryVariables
  >(GET_PROJECTS_QUERY, {
    variables: { filter: filters },
    notifyOnNetworkStatusChange: true,
  });

  const loadingProjects =
    projectNetworkStatus === NetworkStatus.loading ||
    projectNetworkStatus === NetworkStatus.setVariables ||
    !!!projects;

  const navigate = useNavigate();

  const applyFilters = (newFilters: any) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  return (
    <Navbar
      title="Projects"
      actionChildren={
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => navigate('create-project')}
        >
          <AddIcon fontSize="small" />
        </Button>
      }
      searchInputConfig={{
        enable: true,
        name: 'Search',
        placeholder: 'Search Projects',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
    >
      {loadingProjects ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <ProjectsTemplate projects={projects?.getProjects ?? []} />
      )}

      {Array.isArray(projects?.getProjects) && projects?.getProjects.length === 0 && (
        <Grid
          container
          style={{ height: '50vh', width: '80vw' }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Typography variant="subtitle2" align="center">
              No projects found
            </Typography>
          </Grid>
        </Grid>
      )}
    </Navbar>
  );
};

export default ProjectPage;

import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { FormControl } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { client } from 'graphql/client';
import {
  APPLY_ONE_TIME_REVALIDATION_COMPLIANCE_DOC_MUTATION,
  APPROVE_ONE_TIME_COMPLIANCE_DOC_MUTATION,
  APPROVE_ONE_TIME_REVALIDATION_COMPLIANCE_DOC_MUTATION,
  UPLOAD_APPLICATION_ONE_TIME_COMPLIANCE_DOC_MUTATION,
  UPLOAD_FINAL_ONE_TIME_COMPLIANCE_DOC_MUTATION,
  UPLOAD_ONE_TIME_REVALIDATION_COMPLIANCE_DOC_MUTATION,
} from 'graphql/mutation/_compliance';
import { GET_COMPLIANCE_CHECKLIST_BY_ID } from 'graphql/query/complianceChecklist';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ComplianceCurrentStatusEnum } from 'types';

import Metadata from 'components/Inputs/Metadata';
import UploadFile from 'components/Inputs/UploadFile';
import Note from 'components/Note';

const UploadComplianceDocumentForm: React.FC<{
  complianceDocument: any; // ComplianceDocument;
  onCancel: () => void;
  btnTitle: string;
}> = ({ complianceDocument, onCancel, btnTitle }) => {
  const [formState, setFormState] = useState<Record<string, any>>({});

  const [applyOneTimeComplianceDoc, { loading: uploadingApplicationDocument }] = useMutation(
    UPLOAD_APPLICATION_ONE_TIME_COMPLIANCE_DOC_MUTATION
  );

  const [uploadOneTimeComplianceDoc, { loading: uploadingFinalDocument }] = useMutation(
    UPLOAD_FINAL_ONE_TIME_COMPLIANCE_DOC_MUTATION
  );

  const [approveOneTimeComplianceDoc, { loading: uploadingApprovalDocument }] = useMutation(
    APPROVE_ONE_TIME_COMPLIANCE_DOC_MUTATION
  );

  // Apply Revalidation
  const [
    applyOneTimeComplianceRevalidation,
    { loading: uploadingRevalidationApplicationDocument },
  ] = useMutation(APPLY_ONE_TIME_REVALIDATION_COMPLIANCE_DOC_MUTATION);

  // Upload Revalidation
  const [uploadOneTimeComplianceRevalidation, { loading: uploadingRevalidationUploadDocument }] =
    useMutation(UPLOAD_ONE_TIME_REVALIDATION_COMPLIANCE_DOC_MUTATION);

  // Approve Revalidation
  const [approveOneTimeComplianceRevalidation, { loading: uploadingRevalidationApprovalDocument }] =
    useMutation(APPROVE_ONE_TIME_REVALIDATION_COMPLIANCE_DOC_MUTATION);

  const renderDateForms =
    (btnTitle === 'License Approval Document' ||
      btnTitle === 'Final Document' ||
      btnTitle === 'License Revalidation' ||
      btnTitle === 'Approve License Revalidation') &&
    (complianceDocument?.docName === 'FORM_VI' || complianceDocument?.docName === 'BOCW_LICENSE');

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    if (
      complianceDocument?.latestPendingRevalidation?.revalidationStatus ===
        ComplianceCurrentStatusEnum['SENT TO CUSTOMER FOR APPROVAL'] ||
      complianceDocument?.latestPendingRevalidation?.revalidationStatus ===
        ComplianceCurrentStatusEnum['APPLIED']
    ) {
      setFormState(prevState => ({
        ...prevState,
        licEffectiveDate:
          complianceDocument?.latestPendingRevalidation?.licEffectiveDate ||
          prevState.licEffectiveDate,
        licExpiryDate:
          complianceDocument?.latestPendingRevalidation?.licExpiryDate || prevState.licExpiryDate,
      }));
    } else {
      setFormState(prevState => ({
        ...prevState,
        licEffectiveDate: complianceDocument?.licEffectiveDate || prevState.licEffectiveDate,
        licExpiryDate: complianceDocument?.licExpiryDate || prevState.licExpiryDate,
      }));
    }
  }, [
    complianceDocument?.latestPendingRevalidation?.licEffectiveDate,
    complianceDocument?.latestPendingRevalidation?.licExpiryDate,
    //
    complianceDocument?.licEffectiveDate,
    complianceDocument?.licExpiryDate,
  ]);

  const handleSubmit = () => {
    const metadata = {};
    (formState.metadata ?? []).forEach(d => {
      if (!!d.key) {
        metadata[d.key] = d.value;
      }
    });

    const input: any = {
      _id: complianceDocument?._id,
      file: formState.file,
      metadata: JSON.stringify(metadata),
      docName: complianceDocument?.docName,
    };

    const revalidationInput: any = {
      complianceId: complianceDocument?._id,
      complianceRevalidationId: complianceDocument?.latestPendingRevalidation?._id,
      file: formState.file,
      metadata: JSON.stringify(metadata),
      docName: complianceDocument?.docName,
    };

    if (
      complianceDocument?.latestPendingRevalidation?.revalidationStatus ===
        ComplianceCurrentStatusEnum['APPLIED'] ||
      complianceDocument?.latestPendingRevalidation?.revalidationStatus ===
        ComplianceCurrentStatusEnum['SENT TO CUSTOMER FOR APPROVAL']
    ) {
      revalidationInput.licEffectiveDate = formState.licEffectiveDate;
      revalidationInput.licExpiryDate = formState.licExpiryDate;
    } else {
      input.licEffectiveDate = formState.licEffectiveDate;
      input.licExpiryDate = formState.licExpiryDate;
    }

    // Approval doc
    if (btnTitle === 'License Approval Document') {
      approveOneTimeComplianceDoc({
        variables: {
          input,
        },
        onCompleted: res => {
          if (!res.approveOneTimeComplianceDoc.success) {
            toast.error(res.approveOneTimeComplianceDoc.message);
          } else {
            onCancel();
            window.location.reload();
          }
        },
      });
    }

    // Application doc
    if (btnTitle === 'Application Document') {
      applyOneTimeComplianceDoc({
        variables: {
          input,
        },
        onCompleted: res => {
          if (!res.applyOneTimeComplianceDoc.success) {
            toast.error(res.applyOneTimeComplianceDoc.message);
          } else {
            onCancel();
            window.location.reload();
            // deletePendingDocumentCache(complianceId, res.uploadComplianceDocument.document._id);
          }
        },
      });
    }

    // Final doc
    if (btnTitle === 'Final Document') {
      uploadOneTimeComplianceDoc({
        variables: {
          input,
        },
        onCompleted: res => {
          if (!res.uploadOneTimeComplianceDoc.success) {
            toast.error(res.uploadOneTimeComplianceDoc.message);
          } else {
            onCancel();
            window.location.reload();
          }
        },
      });
    }

    // Apply Revalidation

    if (btnTitle === 'Apply License Revalidation') {
      console.log('revalidationInput', revalidationInput);
      applyOneTimeComplianceRevalidation({
        variables: {
          input: { ...revalidationInput },
        },
        onCompleted: res => {
          if (!res.applyOneTimeComplianceRevalidation.success) {
            toast.error(res.applyOneTimeComplianceRevalidation.message);
          } else {
            onCancel();
            window.location.reload();
          }
        },
      });
    }

    // Upload Revalidation
    if (btnTitle === 'License Revalidation') {
      console.log('revalidationInput', revalidationInput);
      uploadOneTimeComplianceRevalidation({
        variables: {
          input: { ...revalidationInput },
        },
        onCompleted: res => {
          if (!res.uploadOneTimeComplianceRevalidation.success) {
            toast.error(res.uploadOneTimeComplianceRevalidation.message);
          } else {
            onCancel();
            window.location.reload();
          }
        },
      });
    }

    // Approve Revalidation
    if (btnTitle === 'Approve License Revalidation') {
      console.log('revalidationInput', revalidationInput);
      approveOneTimeComplianceRevalidation({
        variables: {
          input: { ...revalidationInput },
        },
        onCompleted: res => {
          if (!res.approveOneTimeComplianceRevalidation.success) {
            toast.error(res.approveOneTimeComplianceRevalidation.message);
          } else {
            onCancel();
            window.location.reload();
          }
        },
      });
    }
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columnSpacing={1.5} rowGap={2}>
        <Grid item xs={12}>
          <UploadFile
            buttonProps={{
              size: 'small',
              sx: {
                fontSize: 10,
              },
            }}
            values={formState.file ?? ''}
            onChange={val => handleChange('file', val)}
            label="Select File *"
            required
          />
        </Grid>

        {renderDateForms && (
          <>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={formState.licEffectiveDate ? dayjs(formState.licEffectiveDate) : null}
                    format="DD/MM/YYYY"
                    onChange={val => handleChange('licEffectiveDate', val)}
                    label="Licence effective date"
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                        fullWidth: true,
                        size: 'small',
                      },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={formState.licExpiryDate ? dayjs(formState.licExpiryDate) : null}
                    format="DD/MM/YYYY"
                    onChange={val => handleChange('licExpiryDate', val)}
                    label="Licence expiry date"
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                        fullWidth: true,
                        size: 'small',
                      },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            {btnTitle === 'License Approval Document' && (
              <Grid item xs={12}>
                <Note title="The provided license date cannot be modified once set." />
              </Grid>
            )}
          </>
        )}
        <Grid item xs={12} mt={-1}>
          <Metadata
            metadata={formState.metadata ?? [{ key: ``, value: `` }]}
            setMetadata={md =>
              setFormState(prev => ({
                ...prev,
                metadata: md,
              }))
            }
          />
        </Grid>

        <Grid item xs={12} container columnGap={1}>
          <Button
            variant="text"
            size="small"
            sx={{
              fontSize: 10,
              fontWeight: 600,
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={
              uploadingApplicationDocument ||
              uploadingFinalDocument ||
              uploadingApprovalDocument ||
              uploadingRevalidationApplicationDocument ||
              uploadingRevalidationUploadDocument ||
              uploadingRevalidationApprovalDocument
            }
            type="submit"
            size="small"
            sx={{
              fontSize: 10,
              fontWeight: 600,
            }}
          >
            Upload {btnTitle}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export const deletePendingDocumentCache = (complianceId: string, recordId: string) => {
  const oldData = client.readQuery({
    query: GET_COMPLIANCE_CHECKLIST_BY_ID,
    variables: {
      id: complianceId,
    },
  }) ?? { getComplianceChecklistDetailsById: {} };

  client.writeQuery({
    query: GET_COMPLIANCE_CHECKLIST_BY_ID,
    variables: {
      id: complianceId,
    },
    data: {
      getComplianceChecklistDetailsById: {
        ...oldData,
        pendingDocuments: oldData.getComplianceChecklistDetailsById.pendingDocuments.filter(
          pd => pd._id !== recordId
        ),
      },
    },
  });
};

export default UploadComplianceDocumentForm;

import EditNoteIcon from '@mui/icons-material/EditNote';
import { useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { SimplePopup } from 'components/Popup';
import Section, { SectionDataProps } from 'components/Section';
import { SubcontractorsComplianceForm } from 'components/_Compliances/ComplianceDetailsSections/ContactsSection/Subcontractors/SubcontractorsComplianceForm';

const formTitle = {
  title: 'Update Compliance Details',
  btnText: 'Update',
};

const SubcontractorsDetailsSection: React.FC<{
  subcontractorId: string;
}> = ({ subcontractorId }) => {
  const [searchParams] = useSearchParams();
  const workStartDate = searchParams.get('workStartDate') || '';
  const workEndDate = searchParams.get('workEndDate') || '';
  const status = searchParams.get('status') || '';

  const sectionData: SectionDataProps[] = [
    {
      label: 'Name',
      value: subcontractorId,
      type: 'STRING',
    },
    {
      label: 'Start Date',
      value: workStartDate,
      type: 'DATE',
    },
    {
      label: 'End Date',
      value: workEndDate,
      type: 'DATE',
    },
    {
      label: 'Status',
      value: status,
      type: 'STRING',
    },
  ];

  return (
    <>
      <Section
        title={subcontractorId?.toUpperCase() + ' ' + 'Details'}
        collapsible
        data={sectionData}
        // action={<EditNoteIcon onClick={() => setTogglePopup(true)} sx={{ cursor: 'pointer' }} />}
      />

      {/* <SimplePopup
        onClose={() => setTogglePopup(false)}
        open={togglePopup}
        title={formTitle.title}
        fullWidth
      >
        <SubcontractorsComplianceForm formTitle={formTitle} />
      </SimplePopup> */}
    </>
  );
};

export default SubcontractorsDetailsSection;

import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { updateCachedList } from 'graphql/cacheUpdate';
import { EHS_CHECKLIST_QUERY } from 'graphql/query/ehs';
import { useParams } from 'react-router-dom';
import { Folder, FrequencyEnum } from 'types';

import LoadingIndicator from 'components/LoadingIndicator';
import Section from 'components/Section';

import StandardEHSDocumentsListTable from './StandardEHSChecklistTable';

const GET_STANDARD_EHS_DOCUMENTS = gql`
  query GetStandardEhsDocuments($project: ID) {
    getStandardEhsDocuments(project: $project) {
      _id
      docName
      docType {
        _id
        referenceId
        name
      }
      frequency
    }
  }
`;

type GetStandardEHSDocumentsResponse = {
  getStandardEhsDocuments: {
    _id: string;
    docName: string;
    docType: Folder;
    frequency: FrequencyEnum;
  }[];
};

type GetStandardEHSDocumentsVariables = {
  project: string;
};

const StandardEHSDocumentsList: React.FC<{ toggleFormView: () => void }> = ({ toggleFormView }) => {
  const { projectId = '' } = useParams<{ projectId: string }>();

  const { data: standardEHSDocuments, networkStatus } = useQuery<
    GetStandardEHSDocumentsResponse,
    GetStandardEHSDocumentsVariables
  >(GET_STANDARD_EHS_DOCUMENTS, {
    variables: {
      project: projectId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const loadingStandardEHSDocuments =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!standardEHSDocuments;

  return (
    <Section title="Add EHS Items" collapsible>
      {loadingStandardEHSDocuments ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <StandardEHSDocumentsListTable
          initialRows={standardEHSDocuments.getStandardEhsDocuments}
          cb={newChecklist => {
            updateCachedList(EHS_CHECKLIST_QUERY, 'getEhsChecklist', newChecklist, {
              project: projectId,
            });
            toggleFormView();
          }}
        />
      )}
    </Section>
  );
};

export default StandardEHSDocumentsList;

import { useQuery } from '@apollo/client';
import {
  GET_EHS_CHECKLIST_BY_ID,
  GetEhsChecklistByIdResponse,
  GetEhsChecklistByIdVariables,
} from 'graphql/query/ehs';
import { useParams, useSearchParams } from 'react-router-dom';

import IncidentReportForm from 'components/EHS/Forms/IncidentReportForm';
import InvestigationReportForm from 'components/EHS/Forms/InvestigationReportForm';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

const UpdateEHSReportPage = () => {
  const { ehsChecklistId = '' } = useParams<{ ehsChecklistId: string }>();
  const [searchParams] = useSearchParams();
  const projectName = searchParams.get('projectName');
  const isIncidentType = searchParams.get('type') === 'incident';

  const { data: ehsChecklist, loading: loadingEHSChecklist } = useQuery<
    GetEhsChecklistByIdResponse,
    GetEhsChecklistByIdVariables
  >(GET_EHS_CHECKLIST_BY_ID, {
    variables: {
      id: ehsChecklistId,
    },
  });

  return (
    <Navbar
      goBackButtonConfig={{
        title: `Upload ${isIncidentType ? 'Incident' : 'Investigation'} Report | ${projectName}`,
        hideSeparator: true,
      }}
    >
      {loadingEHSChecklist ? (
        <LoadingIndicator />
      ) : isIncidentType ? (
        <IncidentReportForm
          ehsChecklist={ehsChecklist?.getEhsChecklistDetailsById}
          initialData={ehsChecklist?.getEhsChecklistDetailsById.incidentReports}
        />
      ) : (
        <InvestigationReportForm
          ehsChecklist={ehsChecklist?.getEhsChecklistDetailsById}
          initialData={ehsChecklist?.getEhsChecklistDetailsById.investigationReports}
        />
      )}
    </Navbar>
  );
};

export default UpdateEHSReportPage;

import { Button, FormControl, Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

export const DateSelectorPopup = ({
  formState,
  handleFormSubmit,
  disableBtn,
  startDate,
  endDate,
}) => {
  // Temporary state for popup date selection
  const [tempFormState, setTempFormState] = useState({
    startDate: formState.startDate,
    endDate: formState.endDate,
  });

  // Reset tempFormState when popup opens
  useEffect(() => {
    setTempFormState({ startDate: formState.startDate, endDate: formState.endDate });
  }, [formState]);

  return (
    <Grid item container direction="row" rowGap={1} mb={2} xs={12} p={2} columnSpacing={2}>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={tempFormState.startDate}
              format="MM/YYYY"
              views={['year', 'month']}
              onChange={val => setTempFormState(prev => ({ ...prev, startDate: val }))}
              label="Start month and year"
              minDate={dayjs(startDate)}
              maxDate={dayjs(endDate)}
              slotProps={{
                textField: {
                  variant: 'outlined',
                  fullWidth: true,
                  required: true,
                },
              }}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={tempFormState.endDate}
              format="MM/YYYY"
              minDate={dayjs(startDate)}
              maxDate={dayjs(endDate)}
              views={['year', 'month']}
              onChange={val => setTempFormState(prev => ({ ...prev, endDate: val }))}
              label="End month and year"
              slotProps={{
                textField: {
                  variant: 'outlined',
                  fullWidth: true,
                  required: true,
                },
              }}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid item container justifyContent="flex-end" mt={2}>
        <Grid item>
          <Button
            variant="contained"
            disabled={disableBtn}
            onClick={() => handleFormSubmit(tempFormState)}
          >
            Filter
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

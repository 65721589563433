import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { DatePicker, LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {
  CREATE_INCIDENT_REPORT,
  CreateIncidentReportResponse,
  CreateIncidentReportVariables,
  UPDATE_EHS_INCIDENT_REPORT,
  UpdateIncidentReportResponse,
  UpdateIncidentReportVariables,
} from 'graphql/mutation/ehs';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EHSChecklist, GenderEnum } from 'types';

import Metadata from 'components/Inputs/Metadata';
import { Select } from 'components/Inputs/Select';
import TextField from 'components/Inputs/TextField';
import UploadFile from 'components/Inputs/UploadFile';

import { getUpdatedFields } from 'utils/common';
import { formatDate } from 'utils/transformFn';

export enum EHSIncidentReportIncidentTypeEnum {
  INJURY = 'INJURY',
  NEAR_MISS = 'NEAR_MISS',
  FATALITY = 'FATALITY',
  'ILL HEALTH' = 'ILL_HEALTH',
}

const IncidentReportForm: React.FC<{
  initialData?: any;
  ehsChecklist: EHSChecklist | undefined;
}> = ({ initialData = {}, ehsChecklist }) => {
  const { ehsChecklistId = '', projectId = '' } = useParams<{
    ehsChecklistId: string;
    projectId: string;
  }>();

  const [formState, setFormState] = useState<Record<string, any>>({
    ...initialData,
    isWearingPPE: initialData.isWearing ?? false,
    isPpeCondition: initialData.isPpeCondition ?? false,
    isUnsafeAct: initialData.isUnsafeAct ?? false,
  });

  const isUpdateForm = !!initialData._id;

  const navigate = useNavigate();

  const [createIncidentReport, { loading: creatingIncidentReport }] = useMutation<
    CreateIncidentReportResponse,
    CreateIncidentReportVariables
  >(CREATE_INCIDENT_REPORT);

  const [updateEHSIncidentReport, { loading: updatingEHSIncidentReport }] = useMutation<
    UpdateIncidentReportResponse,
    UpdateIncidentReportVariables
  >(UPDATE_EHS_INCIDENT_REPORT);

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    const incidentDate = formatDate(formState.incidentDate);
    const returnToWorkDate = formatDate(formState.returnToWorkDate);
    const metadata = {};
    (formState.metadata ?? []).forEach(d => {
      if (!!d.key) {
        metadata[d.key] = d.value;
      }
    });

    if (isUpdateForm) {
      const refinedFields = getUpdatedFields(initialData, formState);
      updateEHSIncidentReport({
        variables: {
          input: {
            _id: initialData._id,
            ...refinedFields,
            immediateActions: formState.immediateActions,
            isPpeCondition: formState.isPpeCondition,
            isUnsafeAct: formState.isUnsafeAct,
            incidentDate,
            returnToWorkDate,
          },
        },
        onCompleted: _ => navigate(-1),
      });
    } else {
      createIncidentReport({
        variables: {
          input: {
            _id: ehsChecklistId,
            ...formState,
            ehsDocumentId: ehsChecklist?.pendingDocuments?.[0]?._id as string,
            project: projectId,
            incidentDate,
            returnToWorkDate,
            metadata: JSON.stringify(metadata),
          },
        },
        onCompleted: _ => navigate(-1),
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container columnSpacing={1} rowGap={2} md={10} lg={8} xl={6}>
        <Grid item xs={6}>
          <TextField
            label="Reference Number"
            value={formState.referenceNo ?? ''}
            onChange={e => handleChange('referenceNo', e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Location"
            value={formState.location ?? ''}
            onChange={e => handleChange('location', e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Incident"
            value={formState.incidentHeading ?? ''}
            onChange={e => handleChange('incidentHeading', e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Incident Type"
            value={formState.incidentType ?? ''}
            onChange={val => handleChange('incidentType', val)}
            options={Object.keys(EHSIncidentReportIncidentTypeEnum).map(k => ({
              label: k,
              value: EHSIncidentReportIncidentTypeEnum[k],
            }))}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Person Name"
            type="text"
            value={formState.name ?? ''}
            onChange={e => handleChange('name', e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="ID No."
            value={formState.idNumber ?? ''}
            onChange={e => handleChange('idNumber', e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Name of the Contractor / Agency / Organization"
            type="text"
            value={formState.organizationName ?? ''}
            onChange={e => handleChange('organizationName', e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Age"
            type="number"
            value={formState.age ?? ''}
            onChange={e =>
              handleChange(
                'age',
                !!e.target.value ? (+e.target.value > 0 ? +e.target.value : formState.age) : ''
              )
            }
            required
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Gender"
            value={formState.gender ?? ''}
            onChange={val => handleChange('gender', val)}
            options={Object.keys(GenderEnum).map(k => ({ label: k, value: GenderEnum[k] }))}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address"
            multiline
            minRows={2}
            value={formState.address ?? ''}
            onChange={e => handleChange('address', e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Incident Date"
              format="DD/MM/YYYY"
              disableFuture
              value={
                dayjs.isDayjs(formState.incidentDate)
                  ? formState.incidentDate
                  : !!formState.incidentDate
                  ? dayjs(formState.incidentDate)
                  : null
              }
              slotProps={{
                textField: {
                  variant: 'outlined',
                  fullWidth: true,
                  required: true,
                },
              }}
              onChange={val => handleChange('incidentDate', val)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileTimePicker
              label="Incident Time"
              value={
                dayjs.isDayjs(formState.incidentTime)
                  ? formState.incidentTime
                  : !!formState.incidentTime
                  ? dayjs(formState.incidentTime)
                  : null
              }
              slotProps={{
                textField: {
                  variant: 'outlined',
                  fullWidth: true,
                  required: true,
                },
              }}
              onChange={val => handleChange('incidentTime', val)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Return from Hospital Date"
              format="DD/MM/YYYY"
              value={
                dayjs.isDayjs(formState.returnFromHospitalDate)
                  ? formState.returnFromHospitalDate
                  : !!formState.returnFromHospitalDate
                  ? dayjs(formState.returnFromHospitalDate)
                  : null
              }
              slotProps={{
                textField: {
                  variant: 'outlined',
                  fullWidth: true,
                },
              }}
              onChange={val => handleChange('returnFromHospitalDate', val)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileTimePicker
              label="Return from Hospital Time"
              value={
                dayjs.isDayjs(formState.returnFromHospitalTime)
                  ? formState.returnFromHospitalTime
                  : !!formState.returnFromHospitalTime
                  ? dayjs(formState.returnFromHospitalTime)
                  : null
              }
              slotProps={{
                textField: {
                  variant: 'outlined',
                  fullWidth: true,
                },
              }}
              onChange={val => handleChange('returnFromHospitalTime', val)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileTimePicker
              label="Work Started Time"
              value={
                dayjs.isDayjs(formState.workStartedTime)
                  ? formState.workStartedTime
                  : !!formState.workStartedTime
                  ? dayjs(formState.workStartedTime)
                  : null
              }
              slotProps={{
                textField: {
                  variant: 'outlined',
                  fullWidth: true,
                },
              }}
              onChange={val => handleChange('workStartedTime', val)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date of Return to Work"
              format="DD/MM/YYYY"
              value={
                dayjs.isDayjs(formState.returnToWorkDate)
                  ? formState.returnToWorkDate
                  : !!formState.returnToWorkDate
                  ? dayjs(formState.returnToWorkDate)
                  : null
              }
              slotProps={{
                textField: {
                  variant: 'outlined',
                  fullWidth: true,
                },
              }}
              onChange={val => handleChange('returnToWorkDate', val)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Incident Details (By in-charge)"
            multiline
            minRows={2}
            value={formState.incidentDetails ?? ''}
            onChange={e => handleChange('incidentDetails', e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Immediate action taken for correction & prevention"
            multiline
            minRows={2}
            value={formState.immediateActions ?? ''}
            onChange={e => handleChange('immediateActions', e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl>
            <FormLabel color="secondary">Was PPE being in condition?</FormLabel>
            <RadioGroup
              row
              value={formState.isPpeCondition ?? false}
              onChange={e => handleChange('isPpeCondition', e.target.value === 'true')}
            >
              <FormControlLabel value={true} control={<Radio color="secondary" />} label="Yes" />
              <FormControlLabel value={false} control={<Radio color="secondary" />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl>
            <FormLabel color="secondary">Was it an Unsafe act?</FormLabel>
            <RadioGroup
              row
              value={formState.isUnsafeAct ?? false}
              onChange={e => handleChange('isUnsafeAct', e.target.value === 'true')}
            >
              <FormControlLabel value={true} control={<Radio color="secondary" />} label="Yes" />
              <FormControlLabel value={false} control={<Radio color="secondary" />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel color="secondary">Whether the person was wearing PPE?</FormLabel>
            <RadioGroup
              row
              value={formState.isWearingPPE ?? false}
              onChange={e => handleChange('isWearingPPE', e.target.value === 'true')}
            >
              <FormControlLabel value="true" control={<Radio color="secondary" />} label="Yes" />
              <FormControlLabel value="false" control={<Radio color="secondary" />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <UploadFile
            buttonProps={{
              size: 'small',
              sx: {
                fontSize: 10,
              },
            }}
            values={formState.file ?? []}
            onChange={val => handleChange('file', val)}
            label={`Select Incident Report ${isUpdateForm ? '' : '*'}`}
            required={!isUpdateForm}
            multiple
          />
        </Grid>
        {/* <Grid item xs={12} mt={-1}>
          <Metadata
            metadata={formState.metadata ?? [{ key: ``, value: `` }]}
            setMetadata={md =>
              setFormState(prev => ({
                ...prev,
                metadata: md,
              }))
            }
          />
        </Grid> */}

        <Grid item xs={12} textAlign="right" pt={1} pb={2}>
          <LoadingButton
            loading={creatingIncidentReport || updatingEHSIncidentReport}
            variant="contained"
            type="submit"
            color="secondary"
          >
            {isUpdateForm ? 'Update' : 'Submit'}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default IncidentReportForm;

import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DatePicker, LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {
  CREATE_INVESTIGATION_REPORT_MUTATION,
  CreateInvestigationReportResponse,
  CreateInvestigationReportVariables,
  UPDATE_EHS_INVESTIGATION_REPORT,
  UpdateEHSInvestigationReportResponse,
  UpdateEHSInvestigationReportVariables,
} from 'graphql/mutation/ehs';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EHSChecklist, EHSIncidentBodyPartAffected, EHSIncidentReportedTo, RootCause } from 'types';

import { MultiSelect, Select } from 'components/Inputs/Select';
import TextField, { MultiTextInput } from 'components/Inputs/TextField';
import UploadFile from 'components/Inputs/UploadFile';

import { formatDate } from 'utils/transformFn';

import EHSIncidentAnalysisTable from '../Tables/EHSIncidentAnalysisTable';
import EHSIncidentFactorsTable from '../Tables/EHSIncidentFactorsTable';

export enum EHSInvestigationReportIncidentTypeEnum {
  INJURY = 'INJURY',
  NEAR_MISS = 'NEAR_MISS',
  ENVIRONMENTAL = 'ENVIRONMENTAL',
  'PROPERTY DAMAGE' = 'PROPERTY_DAMAGE',
}

const InvestigationReportForm: React.FC<{
  initialData?: any;
  ehsChecklist: EHSChecklist | undefined;
}> = ({ initialData = {}, ehsChecklist }) => {
  const [formState, setFormState] = useState<Record<string, any>>({
    ...initialData,
    reviewedBy: {
      localHSEAdvisor: initialData.reviewedBy?.localHSEAdvisor ?? false,
      nationalSafetyManager: initialData.reviewedBy?.nationalSafetyManager ?? false,
      locationManager: initialData.reviewedBy?.locationManager ?? false,
      safetyAlertReleased: initialData.reviewedBy?.safetyAlertReleased ?? false,
      amendmentToHSESystem: initialData.reviewedBy?.amendmentToHSESystem ?? false,
    },
    namesOfPersonsInvolved: initialData.namesOfPersonsInvolved ?? [],
    nameOfWitnesses: initialData.nameOfWitnesses ?? [],
  });

  const { ehsChecklistId = '', projectId = '' } = useParams<{
    ehsChecklistId: string;
    projectId: string;
  }>();

  const isUpdateForm = !!initialData._id;

  const isMobileScreen = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();

  const [createInvestigationReport, { loading: creatingInvestingationReport }] = useMutation<
    CreateInvestigationReportResponse,
    CreateInvestigationReportVariables
  >(CREATE_INVESTIGATION_REPORT_MUTATION);

  const [updateInvestigationReport, { loading: updatingInvestigationReport }] = useMutation<
    UpdateEHSInvestigationReportResponse,
    UpdateEHSInvestigationReportVariables
  >(UPDATE_EHS_INVESTIGATION_REPORT);

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleReviewedByChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      reviewedBy: {
        ...prev.reviewedBy,
        [fieldName]: value,
      },
    }));
  };

  const handleSubmit = () => {
    const factorTypes: any[] = [];
    const analysis: any[] = [];

    if (!!formState.factorType) {
      factorTypes.push(...formState.factorType.map(({ _id, __typename, ...ft }) => ft));
    }

    if (!!formState.analysis) {
      analysis.push(...formState.analysis.map(({ _id, __typename, ...item }) => item));
    }

    if (isUpdateForm) {
      const { ehsDocument, __typename, ...updateFormData } = formState;
      updateInvestigationReport({
        variables: {
          input: {
            ...updateFormData,
            factorType: factorTypes,
            analysis,
            incidentDate: formatDate(updateFormData.incidentDate),
          },
        },
        onCompleted: _ => navigate(-1),
      });
    } else
      createInvestigationReport({
        variables: {
          input: {
            ...formState,
            _id: ehsChecklistId,
            project: projectId,
            factorType: factorTypes,
            ehsDocumentId: ehsChecklist?.pendingDocuments?.[0]?._id,
            analysis,
            incidentDate: formatDate(formState.incidentDate),
          },
        },
        onCompleted: _ => navigate(-1),
      });
  };

  return (
    <form
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid item container columnSpacing={1} rowGap={2} md={10} lg={8} xl={6}>
        <Grid item xs={6}>
          <TextField
            label="Reference Number"
            value={formState.referenceNo ?? ''}
            onChange={e => handleChange('referenceNo', e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Location"
            value={formState.location ?? ''}
            onChange={e => handleChange('location', e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="PDS Role"
            value={formState.pdsRole ?? ''}
            onChange={e => handleChange('pdsRole', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Incident Date"
              format="DD/MM/YYYY"
              disableFuture
              value={
                dayjs.isDayjs(formState.incidentDate)
                  ? formState.incidentDate
                  : !!formState.incidentDate
                  ? dayjs(formState.incidentDate)
                  : null
              }
              slotProps={{
                textField: {
                  variant: 'outlined',
                  fullWidth: true,
                  required: true,
                },
              }}
              onChange={val => handleChange('incidentDate', val)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileTimePicker
              label="Incident Time"
              value={
                dayjs.isDayjs(formState.incidentTime)
                  ? formState.incidentTime
                  : !!formState.incidentTime
                  ? dayjs(formState.incidentTime)
                  : null
              }
              slotProps={{
                textField: {
                  variant: 'outlined',
                  fullWidth: true,
                  required: true,
                },
              }}
              onChange={val => handleChange('incidentTime', val)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Reported To"
            value={formState.reportedTo ?? ''}
            onChange={val => handleChange('reportedTo', val)}
            options={Object.keys(EHSIncidentReportedTo).map(k => ({
              label: k,
              value: EHSIncidentReportedTo[k],
            }))}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <MultiTextInput
            values={formState.namesOfPersonsInvolved ?? []}
            label="Names of Person(s) involved"
            type="text"
            required={formState.namesOfPersonsInvolved?.length === 0}
            fullWidth
            variant="outlined"
            setValues={newArr => handleChange('namesOfPersonsInvolved', newArr)}
          />
        </Grid>
        <Grid item xs={12}>
          <MultiTextInput
            values={formState.nameOfWitnesses ?? []}
            label="Names of Witnesses"
            required={formState.nameOfWitnesses?.length === 0}
            setValues={newArr => handleChange('nameOfWitnesses', newArr)}
            type="text"
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            label="Incident Type"
            value={formState.incidentType ?? ''}
            onChange={val => handleChange('incidentType', val)}
            options={Object.keys(EHSInvestigationReportIncidentTypeEnum).map(k => ({
              label: k,
              value: EHSInvestigationReportIncidentTypeEnum[k],
            }))}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            label="Body Part affected (if available)"
            value={formState.bodyPartAffected ?? []}
            handleChange={val => handleChange('bodyPartAffected', val)}
            options={Object.keys(EHSIncidentBodyPartAffected).map(k => ({
              label: k,
              value: EHSIncidentBodyPartAffected[k],
            }))}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Observation/Initial cause"
            multiline
            minRows={2}
            value={formState.observationOrInitialCause ?? ''}
            onChange={e => handleChange('observationOrInitialCause', e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Immediate Action"
            multiline
            minRows={2}
            value={formState.immediateAction ?? ''}
            onChange={e => handleChange('immediateAction', e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Background Information"
            multiline
            minRows={2}
            value={formState.backgroundInformation ?? ''}
            onChange={e => handleChange('backgroundInformation', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} container rowGap={0.5} position="relative">
          <Typography variant="caption" fontWeight={600}>
            What act(s) and/or omission(s) if any, or any other factors or conditions contributed to
            this incident?
          </Typography>
          <EHSIncidentFactorsTable
            onTableUpdate={newRows => handleChange('factorType', newRows)}
            initialRows={isUpdateForm ? formState.factorType : undefined}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            label="Root Cause"
            value={formState.rootCause ?? ''}
            options={Object.keys(RootCause).map(k => ({ label: k, value: RootCause[k] }))}
            onChange={val => handleChange('rootCause', val)}
            required
          />
        </Grid>
        <Grid item xs={12} position="relative">
          <EHSIncidentAnalysisTable
            onTableUpdate={newRows => handleChange('analysis', newRows)}
            initialRows={isUpdateForm ? formState.analysis : undefined}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Follow Up"
            multiline
            minRows={2}
            value={formState.followUp ?? ''}
            onChange={e => handleChange('followUp', e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel color="secondary">
              Safety / Environmental Alert or other information released to all relevant staff
            </FormLabel>
            <RadioGroup
              row
              name="radio-buttons-group"
              value={formState.reviewedBy.safetyAlertReleased}
              onChange={e =>
                handleReviewedByChange('safetyAlertReleased', e.target.value === 'true')
              }
            >
              <FormControlLabel value={true} control={<Radio color="secondary" />} label="Yes" />
              <FormControlLabel value={false} control={<Radio color="secondary" />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl>
            <FormLabel color="secondary">Reviewed by Local HSE Advisor</FormLabel>
            <RadioGroup
              row
              name="radio-buttons-group"
              value={formState.reviewedBy.localHSEAdvisor}
              onChange={e => handleReviewedByChange('localHSEAdvisor', e.target.value === 'true')}
            >
              <FormControlLabel value={true} control={<Radio color="secondary" />} label="Yes" />
              <FormControlLabel value={false} control={<Radio color="secondary" />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl>
            <FormLabel color="secondary">Reviewed by National / Regional Safety Manager</FormLabel>
            <RadioGroup
              row
              name="radio-buttons-group"
              value={formState.reviewedBy.nationalSafetyManager}
              onChange={e =>
                handleReviewedByChange('nationalSafetyManager', e.target.value === 'true')
              }
            >
              <FormControlLabel value={true} control={<Radio color="secondary" />} label="Yes" />
              <FormControlLabel value={false} control={<Radio color="secondary" />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl>
            <FormLabel color="secondary">Reviewed by Location Manager</FormLabel>
            <RadioGroup
              row
              name="radio-buttons-group"
              value={formState.reviewedBy.locationManager}
              onChange={e => handleReviewedByChange('locationManager', e.target.value === 'true')}
            >
              <FormControlLabel value={true} control={<Radio color="secondary" />} label="Yes" />
              <FormControlLabel value={false} control={<Radio color="secondary" />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl>
            <FormLabel color="secondary">Amendment to PDS HSE System required</FormLabel>
            <RadioGroup
              row
              name="radio-buttons-group"
              value={formState.reviewedBy.amendmentToHSESystem}
              onChange={e =>
                handleReviewedByChange('amendmentToHSESystem', e.target.value === 'true')
              }
            >
              <FormControlLabel value={true} control={<Radio color="secondary" />} label="Yes" />
              <FormControlLabel value={false} control={<Radio color="secondary" />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <UploadFile
            buttonProps={{
              size: 'small',
              sx: {
                fontSize: 10,
              },
            }}
            multiple
            values={formState.file ?? []}
            onChange={val => handleChange('file', val)}
            label={`Select investigation Report ${isUpdateForm ? '' : '*'}`}
            required={!isUpdateForm}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="end" pb={2}>
          <LoadingButton
            loading={creatingInvestingationReport || updatingInvestigationReport}
            type="submit"
            fullWidth={isMobileScreen}
            variant="contained"
            color="secondary"
          >
            {isUpdateForm ? 'Update' : 'Create'}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default InvestigationReportForm;

import { NetworkStatus, useQuery } from '@apollo/client';
import {
  EHSChecklistQueryResponse,
  EHSChecklistQueryVariables,
  EHS_CHECKLIST_QUERY,
} from 'graphql/query/ehs';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import EHSCheckListTemplate from 'components/EHS/EHSChecklistTemplate';
import StandardEHSDocumentsList from 'components/EHS/StandardEHSChecklist';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

const EHSListPage = () => {
  const [enableStandardEHSChecklistTable, toggleStandardEHSChecklistTable] = useState(false);

  const { projectId = '' } = useParams<{ projectId: string }>();
  const [searchParams] = useSearchParams();

  const { data: ehs, networkStatus } = useQuery<
    EHSChecklistQueryResponse,
    EHSChecklistQueryVariables
  >(EHS_CHECKLIST_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      project: projectId,
    },
    fetchPolicy: 'network-only',
  });

  const loadingEHS =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!ehs;

  return (
    <Navbar
      goBackButtonConfig={{
        title:
          ehs?.getEhsChecklist?.length === 0 || enableStandardEHSChecklistTable
            ? 'Add EHS Items'
            : searchParams.get('projectName') ?? '',
        onClick:
          ehs?.getEhsChecklist?.length === 0
            ? undefined
            : enableStandardEHSChecklistTable
            ? () => toggleStandardEHSChecklistTable(false)
            : undefined,
      }}
    >
      {loadingEHS ? (
        <LoadingIndicator size="1.6rem" />
      ) : ehs.getEhsChecklist.length === 0 || enableStandardEHSChecklistTable ? (
        <StandardEHSDocumentsList toggleFormView={() => toggleStandardEHSChecklistTable(false)} />
      ) : (
        <EHSCheckListTemplate
          ehs={ehs.getEhsChecklist}
          toggleStandardChecklistTable={toggleStandardEHSChecklistTable}
        />
      )}
    </Navbar>
  );
};

export default EHSListPage;

import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { Project } from 'types';

import Filters from 'components/Filters';
import { ComplianceSort } from 'components/Leads/Sort/ComplianceSort';
import { Sort } from 'components/Leads/Sort/Sort';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import ProjectCard from 'components/Projects/Cards/ProjectCard';

import { transformFilters } from 'utils/transformFn';

const GET_COMPLIANCES_QUERY = gql`
  query GetCompliances($filter: ComplianceFilter) {
    getCompliances(filter: $filter) {
      _id
      name
      referenceId
      lead {
        _id
        name
        segment
        scope
        areaOrPower
        areaOrPowerUOM
        approxValue
        leadStatus
      }
      status
    }
  }
`;

type GetCompliancesQueryResponse = {
  getCompliances: Project[];
};

const NewComplianceProjectsPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});

  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
  };
  const { data, networkStatus } = useQuery<GetCompliancesQueryResponse, any>(
    GET_COMPLIANCES_QUERY,
    {
      variables: { filter: filters },
      notifyOnNetworkStatusChange: true,
    }
  );

  const loadingCompliances =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!data;

  return (
    <Navbar
      title="Compliances"
      searchInputConfig={{
        enable: true,
        name: 'search',
        placeholder: 'Name',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
    >
      <Grid
        container
        justifyContent="flex-end"
        columnSpacing={2}
        mb={2}
        pr={isMobileScreen ? 0 : 2}
        pt={isMobileScreen ? 0.5 : 0}
      >
        <Grid item xs={6} md={1.8} xl={1}>
          <Filters
            type="new-compliances"
            refetch={data => {
              applyFilters(transformFilters(data, ['city', 'company']));
            }}
          />
        </Grid>
        <Grid item xs={6} md={1.5} xl={1}>
          <ComplianceSort
            sort={filters.sortOption}
            setSort={newSortVal =>
              applyFilters({
                ...filters,
                sortOption: newSortVal,
              })
            }
          />
        </Grid>
      </Grid>
      {loadingCompliances ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid
          container
          columns={3}
          rowSpacing={3}
          columnSpacing={5}
          alignItems="center"
          mb={5}
          pr={2}
        >
          {Array.isArray(data?.getCompliances) &&
            data?.getCompliances.length > 0 &&
            data?.getCompliances.map(project => (
              <ProjectCard project={project} key={project._id} />
            ))}
        </Grid>
      )}

      {Array.isArray(data?.getCompliances) && data?.getCompliances.length === 0 && (
        <Grid
          container
          style={{ height: '50vh', width: '80vw' }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Typography variant="subtitle2" align="center">
              No compliances found
            </Typography>
          </Grid>
        </Grid>
      )}
    </Navbar>
  );
};

export default NewComplianceProjectsPage;

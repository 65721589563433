import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { FormControl, Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import {
  ADD_COMPLIANCE_SUB_CONTRACTOR_MUTATION,
  UPDATE_COMPLIANCE_SUB_CONTRACTOR_INFO_MUTATION,
} from 'graphql/mutation/_compliance';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DynamicTextField } from 'components/Inputs/TextField';

export enum COMPLIANCE_PEOPLE_STATUS_ENUM {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export const SubcontractorsComplianceForm: React.FC<{
  formTitle: any;
  subContractorInfo?: any;
  setToggleForm: any;
  complianceMilestoneId: string;
  refetch: any;
}> = ({ formTitle, subContractorInfo, setToggleForm, complianceMilestoneId, refetch }) => {
  const [formState, setFormState] = useState<Record<string, any>>({});

  const { projectId = '' } = useParams<{
    projectId: string;
  }>();

  const [addComplianceSubContractorInfo, { loading: creatingSubcontractor }] = useMutation(
    ADD_COMPLIANCE_SUB_CONTRACTOR_MUTATION
  );
  const [updateComplianceSubContractorInfo, { loading: updatingSubcontractor }] = useMutation(
    UPDATE_COMPLIANCE_SUB_CONTRACTOR_INFO_MUTATION
  );

  const showStatusField = formTitle.btnText === 'Update';

  const handleChanges = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    if (showStatusField) {
      updateComplianceSubContractorInfo({
        variables: {
          input: {
            complianceMilestoneId,
            subContractorInfo: {
              _id: subContractorInfo?._id,
              workEndDate: dayjs(formState.workEndDate).format('YYYY-MM-DD'),
            },
          },
        },
        onCompleted: _ => {
          setToggleForm(false);
          refetch();
        },
      });
    } else {
      addComplianceSubContractorInfo({
        variables: {
          input: {
            project: projectId,
            subContractorInfo: {
              name: formState.name,
              workStartDate: dayjs(formState.workStartDate)?.format('YYYY-MM-DD'),
              workEndDate: dayjs(formState.workEndDate)?.format('YYYY-MM-DD'),
            },
          },
        },
        onCompleted: _ => {
          setToggleForm(false);
          refetch();
        },
      });
    }
  };

  useEffect(() => {
    if (showStatusField && subContractorInfo) {
      setFormState({
        name: subContractorInfo?.name,
        workStartDate: dayjs(subContractorInfo?.workStartDate),
        workEndDate: dayjs(subContractorInfo?.workEndDate),
      });
    }
  }, [showStatusField, subContractorInfo]);

  return (
    <form
      style={{ maxHeight: '50vh', padding: '15px 20px' }}
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columns={12} columnSpacing={2} rowGap={2}>
        <Grid item xs={12}>
          <DynamicTextField
            value={formState.name ?? ''}
            onChange={e => handleChanges('name', e.target.value)}
            label="Name"
            disabled={formTitle.btnText === 'Update'}
            required={!showStatusField}
            fieldKey="name"
          />
        </Grid>
      </Grid>
      <Grid container columns={12} columnSpacing={2} rowGap={2} my={2}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disabled={formTitle.btnText === 'Update'}
                value={formState?.workStartDate ?? null}
                format="DD/MM/YYYY"
                onChange={(val: Dayjs | null) => handleChanges('workStartDate', val)}
                label="Select start date"
                disableFuture
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    fullWidth: true,
                    required: !showStatusField,
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={formState?.workEndDate ?? null}
                format="DD/MM/YYYY"
                onChange={(val: Dayjs | null) => handleChanges('workEndDate', val)}
                label="Select end date"
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    fullWidth: true,
                    required: !showStatusField,
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
      </Grid>

      <Grid item xs={12} alignSelf="flex-end" textAlign="right">
        <LoadingButton
          type="submit"
          loading={creatingSubcontractor || updatingSubcontractor}
          variant="contained"
          color="secondary"
        >
          {formTitle.btnText}
        </LoadingButton>
      </Grid>
    </form>
  );
};

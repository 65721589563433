import { gql } from '@apollo/client';
import {
  DocumentFile,
  EHSChecklist,
  EHSDocument,
  EHSIncidentReportType,
  EHSInvestigationReportType,
  FrequencyEnum,
} from 'types';

export const CREATE_EHS_CHECKLIST_MUTATION = gql`
  mutation CreateEhsChecklist($input: [CreateEhsChecklistInput]!) {
    createEhsChecklist(input: $input) {
      _id
    }
  }
`;

export type CreateEHSChecklistMutationResponse = {
  createEhsChecklist: EHSChecklist[];
};

export type CreateEHSChecklistMutationVariables = {
  input: {
    docName: string;
    docType: string;
    frequency: FrequencyEnum;
    assignTo: string[];
    project: string;
    scheduleStarts: string;
    scheduleEnds: string;
  }[];
};

export const MARK_EHS_DOCUMENTS_AS_NO_DUE = gql`
  mutation MarkEhsDocumentAsNoDue($id: ID!, $remarks: String!) {
    markEhsDocumentAsNoDue(_id: $id, remarks: $remarks)
  }
`;

export type EhsDocumentAsNoDueVariables = {
  id: string;
  remarks: string;
};

export const UPDATE_EHS_CHECKLIST_MUTATION = gql`
  mutation UpdateEhsChecklist($input: UpdateEhsChecklistInput!) {
    updateEhsChecklist(input: $input) {
      _id
      referenceId
      docName
      docType {
        _id
        name
      }
      frequency
      notificationLeadDays
      assignTo {
        _id
        empId
        firstName
        lastName
      }
      project {
        _id
        name
      }
      scheduleStarts
      scheduleEnds
      status
    }
  }
`;

export type UpdateEhsChecklistResponse = {
  updateEhsChecklist: EHSChecklist;
};

export type UpdateEhsChecklistVariables = {
  input: {
    _id: string;
    docName?: string;
    docType?: string;
    frequency?: FrequencyEnum;
    notificationLeadDays?: number;
    assignTo?: string[];
    project?: string;
    scheduleStarts?: string;
    scheduleEnds?: string;
    status: string;
  };
};

export const UPLOAD_EHS_DOCUMENT_MUTATION = gql`
  mutation UploadEhsDocument($input: UploadDocumentInput!) {
    uploadEhsDocument(input: $input) {
      success
      message
      file {
        _id
        name
      }
      document {
        _id
        referenceId
      }
    }
  }
`;

export type UploadEhsDocumentResponse = {
  uploadEhsDocument: {
    success: boolean;
    message: string;
    file: DocumentFile;
    document: EHSDocument;
  };
};

export type UploadEhsDocumentVariables = {
  input: {
    _id: string;
    file: File;
    metadata?: string;
  };
};

export const UPLOAD_EHS_INCIDENT_REPORT_DOCUMENT_MUTATION = gql`
  mutation UploadEhsIncidentReport($input: UploadReportInput!) {
    uploadEhsIncidentReport(input: $input) {
      success
      message
      file {
        _id
        name
      }
      document {
        _id
        referenceId
      }
    }
  }
`;
export type UploadEhsIncidentReportResponse = {
  uploadEhsIncidentReport: {
    success: boolean;
    message: string;
    file: DocumentFile;
    document: EHSDocument;
  };
};

export type UploadEhsIncidentReportVariables = {
  input: {
    _id: string;
    dueDate: string;
    file: File;
    metadata: string;
  };
};

export const CREATE_INCIDENT_REPORT = gql`
  mutation CreateIncidentReport($input: IncidentReportInput) {
    createIncidentReport(input: $input) {
      _id
    }
  }
`;

export type CreateIncidentReportResponse = {
  createIncidentReport: EHSIncidentReportType;
};

export type CreateIncidentReportVariables = {
  input: Record<string, any>;
};

export const CREATE_INVESTIGATION_REPORT_MUTATION = gql`
  mutation CreateIncidentInvestigationReport($input: IncidentInvestigationReportInput) {
    createIncidentInvestigationReport(input: $input) {
      _id
    }
  }
`;

export type CreateInvestigationReportResponse = {
  createIncidentInvestigationReport: {
    _id: string;
  };
};

export type CreateInvestigationReportVariables = {
  input: Record<string, any>;
};

export const UPDATE_EHS_INVESTIGATION_REPORT = gql`
  mutation UpdateIncidentInvestigationReport($input: UpdateIncidentInvestigationReportInput) {
    updateIncidentInvestigationReport(input: $input) {
      _id
    }
  }
`;

export type UpdateEHSInvestigationReportResponse = {
  updateIncidentInvestigationReport: EHSInvestigationReportType;
};

export type UpdateEHSInvestigationReportVariables = {
  input: Record<string, any>;
};

export const UPDATE_EHS_INCIDENT_REPORT = gql`
  mutation UpdateIncidentReport($input: UpdateIncidentReportInput) {
    updateIncidentReport(input: $input) {
      _id
    }
  }
`;

export type UpdateIncidentReportResponse = {
  updateIncidentReport: EHSIncidentReportType;
};

export type UpdateIncidentReportVariables = {
  input: Record<string, any>;
};

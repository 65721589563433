import { client } from 'graphql/client';
import { GET_WOODKRAFT_MONTHLY_COMPLIANCE_DOC_DETAILS_QUERY } from 'graphql/query/compliance';

export const deleteDocumentCache = (complianceId: string) => {
  const oldData = client.readQuery({
    query: GET_WOODKRAFT_MONTHLY_COMPLIANCE_DOC_DETAILS_QUERY,
    variables: {
      id: complianceId,
    },
  }) ?? { getMonthlyComplianceDocById: {} };

  client.writeQuery({
    query: GET_WOODKRAFT_MONTHLY_COMPLIANCE_DOC_DETAILS_QUERY,
    variables: {
      id: complianceId,
    },
    data: {
      getMonthlyComplianceDocById: {
        ...oldData,
      },
    },
  });
};

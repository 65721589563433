import { NetworkStatus, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { GET_ONE_TIME_COMPLIANCE_DOCS_DETAILS_QUERY } from 'graphql/query/compliance';
import { useParams } from 'react-router-dom';
import { ComplianceCurrentStatusEnum } from 'types';

import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import OneTimeComplianceDetailsSection from 'components/_Compliances/FileSections/OneTime/OneTimeComplianceDetailsSection';
import PendingDocumentsSection from 'components/_Compliances/FileSections/OneTime/PendingDocumentsSection';
import UploadedComplianceDocumentsSection from 'components/_Compliances/FileSections/OneTime/UploadedComplianceDocuments';

function OneTimeComplianceFileDetailsPage() {
  const { complianceId = '' } = useParams<{ complianceId: string }>();

  const { data, networkStatus } = useQuery(GET_ONE_TIME_COMPLIANCE_DOCS_DETAILS_QUERY, {
    variables: {
      id: complianceId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const loadingComplianceDetails =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!data;

  return (
    <Navbar goBackButtonConfig={{ title: 'Compliance Details' }}>
      {loadingComplianceDetails ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid item container direction={'column'} xs={12} md={11} lg={9} xl={7} mb={5} rowGap={2.5}>
          <OneTimeComplianceDetailsSection complianceDetails={data?.getOneTimeComplianceDocById} />

          <PendingDocumentsSection
            complianceChecklist={data?.getOneTimeComplianceDocById}
            licenseDetails={null}
            currentStatus={data?.getOneTimeComplianceDocById?.currentStatus}
          />

          {/* Task History */}
          {data?.getOneTimeComplianceDocById?.currentStatus ===
          ComplianceCurrentStatusEnum.CREATED ? undefined : (
            <UploadedComplianceDocumentsSection
              complianceChecklist={data?.getOneTimeComplianceDocById}
            />
          )}
        </Grid>
      )}
    </Navbar>
  );
}

export default OneTimeComplianceFileDetailsPage;

import dayjs from 'dayjs';
import React, { ReactNode, createContext, useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// Define types for the context data and provider props
interface FormContextType {
  formState: { startDate: dayjs.Dayjs | null; endDate: dayjs.Dayjs | null };
  setFormState: React.Dispatch<
    React.SetStateAction<{ startDate: dayjs.Dayjs | null; endDate: dayjs.Dayjs | null }>
  >;
}

interface FormProviderProps {
  children: ReactNode;
}

// Create the context
const FormContext = createContext<FormContextType | undefined>(undefined);

// Context provider component
export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const workStartDate = searchParams.get('workStartDate') || '';
  const workEndDate = searchParams.get('workEndDate') || '';

  const [formState, setFormState] = useState<{
    startDate: dayjs.Dayjs | null;
    endDate: dayjs.Dayjs | null;
  }>({
    startDate: dayjs().subtract(1, 'month'),
    endDate: dayjs().subtract(1, 'month'),
  });

  return (
    <FormContext.Provider value={{ formState, setFormState }}>{children}</FormContext.Provider>
  );
};

// Custom hook to use the form context
export const useFormContext = (): FormContextType => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context;
};

import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid';
import React, { useState } from 'react';

import DateInput from 'components/Inputs/Date';
import DataGridTable from 'components/Table';

import { formatDate } from 'utils/transformFn';

type EHSIncidentAnalysisRowType = {
  _id: number;
  proposedCorrectiveActions?: string;
  responsiblePerson?: string;
  date?: any;
};

const emptyRow: EHSIncidentAnalysisRowType = {
  _id: -1,
  proposedCorrectiveActions: '',
  responsiblePerson: '',
  date: '',
};

const arrayOf4 = [emptyRow, emptyRow, emptyRow];

const EHSIncidentAnalysisTable: React.FC<{
  initialRows?: Omit<EHSIncidentAnalysisRowType, '_id'>[];
  onTableUpdate: (rows: EHSIncidentAnalysisRowType[]) => void;
}> = ({ onTableUpdate, initialRows = arrayOf4 }) => {
  const [rows, setRows] = useState<EHSIncidentAnalysisRowType[]>(
    initialRows.map((r, idx) => ({ ...r, _id: idx }))
  );

  const handleProcessRowUpdate = (newRow: GridRowModel) => {
    const newRows = rows.map(r => {
      if (r._id === newRow._id) return newRow;
      else return r;
    }) as EHSIncidentAnalysisRowType[];

    setRows(newRows);
    onTableUpdate(newRows);
    return newRow;
  };

  const handleAddRow = () => {
    const newRow: EHSIncidentAnalysisRowType = {
      ...emptyRow,
      _id: rows.length,
    };

    const newRows = [...rows, newRow];

    setRows(newRows);
    onTableUpdate(newRows);
  };

  const handleDateChange = (rowId: number, val: any) => {
    const newRows = rows.map(r => {
      if (r._id === rowId) {
        return {
          ...r,
          date: formatDate(val),
        };
      } else {
        return r;
      }
    });

    setRows(newRows);
    onTableUpdate(newRows);
  };

  return (
    <>
      <DataGridTable
        sx={{
          height: '32.5vh',
          overflowY: 'auto',
        }}
        columns={[
          {
            field: '_id',
            headerName: 'No.',
            valueGetter: (row: any) => row + 1,
            width: 5,
          },
          {
            field: 'proposedCorrectiveActions',
            headerName: 'Proposed Corrective Actions',
            editable: true,
            flex: 1,
          },
          {
            field: 'responsiblePerson',
            headerName: 'Responsible Person',
            editable: true,
            minWidth: 160,
          },
          {
            field: 'date',
            headerName: 'Date',
            minWidth: 170,
            renderCell: params => (
              <DateInput
                value={rows[params.row._id].date ?? null}
                onChange={val => handleDateChange(params.row._id, val)}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    fullWidth: true,
                    size: 'small',
                  },
                }}
                sx={{
                  mt: 1.2,
                }}
              />
            ),
          },
        ]}
        getRowId={row => row._id}
        rows={rows}
        hideFooterPagination
        disableColumnMenu
        hideFooterSelectedRowCount
        processRowUpdate={handleProcessRowUpdate}
        disableColumnSorting
      />
      <Grid container position="absolute" bottom={10} right={10}>
        <Grid item sx={{ ml: 'auto' }} container justifyContent="flex-end" columnGap={1}>
          <Button
            onClick={handleAddRow}
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<AddIcon color="secondary" />}
          >
            New Row
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default EHSIncidentAnalysisTable;

import { gql } from '@apollo/client';
import { ComplianceDocument, DocumentFile } from 'types';

export const UPLOAD_COMPLIANCE_DOCUMENT_MUTATION = gql`
  mutation UploadComplianceDocument($input: UploadDocumentInput!) {
    uploadComplianceDocument(input: $input) {
      success
      message
      file {
        _id
        name
      }
      document {
        _id
        referenceId
      }
    }
  }
`;

export type UploadComplianceDocumentResponse = {
  uploadComplianceDocument: {
    success: boolean;
    message: string;
    file: DocumentFile;
    document: ComplianceDocument;
  };
};

export type UploadComplianceDocumentVariables = {
  input: {
    _id: string;
    file: File;
    metadata?: string;
    docName?: string;
  };
};

export const MARK_COMPLIANCE_DOCUMENTS_AS_NO_DUE = gql`
  mutation MarkComplianceDocumentAsNoDue($id: ID!, $remarks: String!) {
    markComplianceDocumentAsNoDue(_id: $id, remarks: $remarks)
  }
`;

export type ComplianceDocumentAsNoDueVariables = {
  id: string;
  remarks: string;
};

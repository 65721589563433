import { Divider, Grid, Typography } from '@mui/material';

import SubcontractorsMonthlyTasks from './Subcontractors';
import WoodkraftMonthlyTasks from './Woodkraft';

const MonthlyTaskSection = ({
  showSubContractorInfo,
  showWoodkraftInfo,
  subContractorInfo,
  loading,
  woodkraftInfo,
}) => {
  return (
    <>
      {showWoodkraftInfo && (
        <>
          <Grid item>
            <Divider sx={{ my: 0.5 }} />
            <Typography fontSize={18} sx={{ fontWeight: 600 }} mt={2}>
              Monthly Scheduled Tasks
            </Typography>
          </Grid>
          <Grid item container direction="column" mt={2} rowGap={2.5}>
            <WoodkraftMonthlyTasks woodkraftInfo={woodkraftInfo} />
            {showSubContractorInfo && (
              <SubcontractorsMonthlyTasks subContractorInfo={subContractorInfo} loading={loading} />
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default MonthlyTaskSection;

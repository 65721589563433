import { ApolloQueryResult, gql, useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, IconButton, Typography } from '@mui/material';
import { client } from 'graphql/client';
import {
  GET_EHS_CHECKLIST_BY_ID,
  GetEhsChecklistByIdResponse,
  GetEhsChecklistByIdVariables,
} from 'graphql/query/ehs';
import { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DocStatusEnum, DocumentFile, EHSChecklist, EHSDocument } from 'types';

import { ConfirmationPopup, SimplePopup } from 'components/Popup';
import Section from 'components/Section';
import DataGridTable from 'components/Table';

import { getEnumKeyFromValue } from 'utils/transformFn';

import UploadIncidentFileForm from '../Forms/UploadIncidentFileForm';

const DELETE_FILE_MUTATION = gql`
  mutation DeleteEhsDocument($id: ID!) {
    deleteEhsDocument(_id: $id)
  }
`;

const DELETE_INCIDENT_FILE_MUTATION = gql`
  mutation DeleteIncidentReportFile($input: DeleteIncidentReportFileInput) {
    deleteIncidentReportFile(input: $input) {
      success
      message
    }
  }
`;

type DeleteIncidentFileResponse = {
  deleteIncidentReportFile: {
    success: boolean;
    message: string;
  };
};

type DeleteIncidentFileVariables = {
  input: {
    incidentReportId: string;
    fileId: string;
  };
};

const DELETE_INVESTIGATION_FILE_MUTATION = gql`
  mutation DeleteIncidentInvestigationReportFile(
    $input: DeleteIncidentInvestigationReportFileInput
  ) {
    deleteIncidentInvestigationReportFile(input: $input) {
      success
      message
    }
  }
`;

type DeleteInvestigationFileResponse = {
  deleteIncidentInvestigationReportFile: {
    success: boolean;
    message: string;
  };
};

type DeleteInvestigationFileVariables = {
  input: {
    investigationReportId: string;
    fileId: string;
  };
};

const UploadedEhsDocumentsSection: React.FC<{
  uploadedDocuments: EHSDocument[];
  ehsChecklist: EHSChecklist;
  refetchChecklist: (
    variables?: Partial<GetEhsChecklistByIdVariables> | undefined
  ) => Promise<ApolloQueryResult<GetEhsChecklistByIdResponse>>;
}> = ({ uploadedDocuments, ehsChecklist, refetchChecklist }) => {
  const [showUploadFile, toggleUploadFile] = useState<boolean>(false);
  const [deletePopup, toggleDeletePopup] = useState<{
    ehsDocumentId?: string;
    fileId?: string;
    show: boolean;
  }>({
    show: false,
  });

  const { projectId = '' } = useParams<{ projectId: string }>();

  const isIncidentReport = ehsChecklist.incidentReports !== null;
  const isInvestigationReport = ehsChecklist.investigationReports !== null;

  const [deleteFile, { loading: deletingFile }] = useMutation<{}, { id: string }>(
    DELETE_FILE_MUTATION
  );

  const [deleteIncidentFile] = useMutation<DeleteIncidentFileResponse, DeleteIncidentFileVariables>(
    DELETE_INCIDENT_FILE_MUTATION
  );

  const [deleteInvestigationFile] = useMutation<
    DeleteInvestigationFileResponse,
    DeleteInvestigationFileVariables
  >(DELETE_INVESTIGATION_FILE_MUTATION);

  const files = useMemo(() => {
    const dFiles: (DocumentFile & {
      ehsDocument: {
        _id: string;
        docStatus: DocStatusEnum;
      };
    })[] = [];

    uploadedDocuments.forEach(ud => {
      dFiles.push(
        ...ud.files.map(f => ({
          ...f,
          ehsDocument: {
            _id: ud._id,
            docStatus: ud.docStatus,
          },
        }))
      );
    });

    return dFiles;
  }, [uploadedDocuments]);

  const handleFileDelete = () => {
    const onCompleted = () => {
      toast.success('File Deleted Successfully');
      deleteCachedListItem({ id: ehsChecklist._id }, deletePopup.ehsDocumentId as string);
      toggleDeletePopup({ ehsDocumentId: undefined, fileId: undefined, show: false });
    };

    if (!!ehsChecklist.incidentReports) {
      deleteIncidentFile({
        variables: {
          input: {
            incidentReportId: ehsChecklist.incidentReports._id,
            fileId: deletePopup.fileId as string,
          },
        },
        onCompleted,
      });
    } else if (!!ehsChecklist.investigationReports) {
      deleteInvestigationFile({
        variables: {
          input: {
            investigationReportId: ehsChecklist.investigationReports._id,
            fileId: deletePopup.fileId as string,
          },
        },
        onCompleted,
      });
    } else
      deleteFile({
        variables: {
          id: deletePopup.ehsDocumentId as string,
        },
        onCompleted,
      });
    toggleDeletePopup({ ehsDocumentId: undefined, show: false });
  };

  return (
    <Section
      title="Updated Tasks"
      collapsible
      action={
        isIncidentReport || isInvestigationReport ? (
          <AddIcon onClick={() => toggleUploadFile(true)} sx={{ cursor: 'pointer' }} />
        ) : undefined
      }
    >
      <DataGridTable
        columns={[
          {
            field: 'referenceId',
            headerName: 'ID',
            minWidth: 100,
            renderCell: params =>
              params.row.docStatus === DocStatusEnum['NO DUE'] ? (
                <Typography>Submission Skipped</Typography>
              ) : (
                <Link
                  to={`/documents/PROJECT/${projectId}/${ehsChecklist.docType.parentFolder?._id}/${ehsChecklist.docType?._id}/${params.row?._id}`}
                >
                  {params.value}
                </Link>
              ),
          },
          {
            field: 'ehsDocument',
            headerName: 'Doc Status',
            valueGetter: (val: any) => getEnumKeyFromValue(DocStatusEnum, val.docStatus),
            minWidth: 150,
          },
          {
            field: 'name',
            headerName: 'Name',
            minWidth: 200,
            flex: 1,
          },
          {
            field: 'actions',
            headerName: 'Actions',
            minWidth: 100,
            renderCell: params => (
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', pt: 1 }}>
                <IconButton
                  color="error"
                  size="small"
                  onClick={() =>
                    toggleDeletePopup({
                      show: true,
                      ehsDocumentId: params.row.ehsDocument._id,
                      fileId: params.row._id,
                    })
                  }
                >
                  <DeleteForeverIcon />
                </IconButton>
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => window.open(params.row?.path, '_blank')}
                >
                  <FileDownloadIcon />
                </IconButton>
              </Box>
            ),
          },
        ]}
        rows={files}
        getRowId={row => row._id}
        hideFooterPagination
        sx={{
          height: '40vh',
          maxWidth: '80vw',
        }}
      />

      <ConfirmationPopup
        onClose={() =>
          toggleDeletePopup({ ehsDocumentId: undefined, fileId: undefined, show: false })
        }
        onConfirmation={handleFileDelete}
        open={deletePopup.show}
        title="Do you want to delete this document?"
        confirmationLabel="Yes"
        loading={deletingFile}
      />
      {(isIncidentReport || isInvestigationReport) && (
        <SimplePopup
          title="Upload New File"
          fullWidth
          open={showUploadFile}
          onClose={() => toggleUploadFile(false)}
        >
          <UploadIncidentFileForm
            ehsChecklist={ehsChecklist}
            isIncidentReport={isIncidentReport}
            cb={() => {
              toggleUploadFile(false);
              refetchChecklist();
            }}
          />
        </SimplePopup>
      )}
    </Section>
  );
};

const deleteCachedListItem = (variables: Record<string, any>, ehsDocumentId: string) => {
  const currEhsDocuments = client.readQuery({
    query: GET_EHS_CHECKLIST_BY_ID,
    variables,
  }) ?? {
    getEhsChecklistDetailsById: {
      uploadedDocuments: [],
    },
  };

  client.writeQuery({
    query: GET_EHS_CHECKLIST_BY_ID,
    variables,
    data: {
      getEhsChecklistDetailsById: {
        ...(currEhsDocuments ?? {}),
        uploadedDocuments: (
          currEhsDocuments?.getEhsChecklistDetailsById?.uploadedDocuments ?? []
        ).filter((item: EHSDocument) => item._id !== ehsDocumentId),
      },
    },
  });
};

export default UploadedEhsDocumentsSection;

import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { Box, Button, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { ComplianceCurrentStatusEnum } from 'types';

import Fieldset from 'components/Fieldset';
import Section from 'components/Section';

import UpdatePaymentStatus from './Forms/UpdatePaymentStatus';
import UploadComplianceDocumentForm from './Forms/UploadComplianceDocumentForm';
import theme from './theme.module.scss';

const PendingDocumentsSection: React.FC<{
  monthlyComplianceDetails: any;
}> = ({ monthlyComplianceDetails }) => {
  const isFinancePerson =
    monthlyComplianceDetails && monthlyComplianceDetails?.paymentBy === 'Finance';

  return (
    <Section title="Pending Tasks" collapsible>
      {monthlyComplianceDetails?.currentStatus === ComplianceCurrentStatusEnum.COMPLETED && (
        <Grid item minHeight="4vh" container justifyContent="center" alignItems="center" xs={12}>
          <Typography variant="subtitle2">No Pending Tasks</Typography>
        </Grid>
      )}

      <Grid container direction="column" rowGap={1} mb={5} pr={2}>
        {isFinancePerson ? (
          <>
            {monthlyComplianceDetails?.currentStatus === ComplianceCurrentStatusEnum.STARTED && (
              <UploadItem
                complianceDocument={monthlyComplianceDetails}
                targetDate={monthlyComplianceDetails?.targetDate}
                btnTitle={'Application Document'}
              />
            )}

            {monthlyComplianceDetails?.currentStatus ===
              ComplianceCurrentStatusEnum['SENT TO FINANCE FOR PAYMENT'] && (
              <UpdatePaymentStatusItem complianceDocument={monthlyComplianceDetails} />
            )}

            {monthlyComplianceDetails?.currentStatus ===
              ComplianceCurrentStatusEnum['PAYMENT DONE'] && (
              <UploadItem
                complianceDocument={monthlyComplianceDetails}
                targetDate={monthlyComplianceDetails?.targetDate}
                btnTitle={'Final Document'}
              />
            )}
          </>
        ) : (
          <>
            {monthlyComplianceDetails?.currentStatus === ComplianceCurrentStatusEnum?.STARTED && (
              <UploadItem
                complianceDocument={monthlyComplianceDetails}
                targetDate={monthlyComplianceDetails?.targetDate}
                btnTitle={'Final Document'}
              />
            )}
          </>
        )}
      </Grid>
    </Section>
  );
};

const UploadItem: React.FC<{ complianceDocument: any; btnTitle: string; targetDate: any }> = ({
  complianceDocument,
  btnTitle,
  targetDate,
}) => {
  const [showUploadForm, toggleUploadForm] = useState(false);

  if (showUploadForm)
    return (
      <Grid pt={1.3} md={10} lg={8}>
        <div className={theme.div1}>
          <Fieldset
            label={`Wrap up the task before ${
              targetDate ? dayjs(targetDate).format('DD/MM/YYYY') : 'N/A'
            }`}
            variant="small"
          >
            {showUploadForm && (
              <Box px={0.6} py={0.5}>
                <UploadComplianceDocumentForm
                  complianceDocument={complianceDocument}
                  onCancel={() => toggleUploadForm(false)}
                  btnTitle={btnTitle}
                />
              </Box>
            )}
          </Fieldset>
        </div>
      </Grid>
    );

  return (
    <Grid
      container
      md={10}
      lg={8}
      direction="column"
      rowGap={1}
      sx={{
        border: '1px solid lightgrey',
        borderRadius: '4px',
        px: 1,
        py: 1.3,
      }}
    >
      <Grid item container xs={12} fontSize={15} alignItems="center" columnGap={0.5}>
        <WatchLaterIcon fontSize="inherit" color="error" />
        <Typography fontSize={12} fontWeight={600} letterSpacing={1}>
          Wrap up the task before : {targetDate ? dayjs(targetDate).format('DD/MM/YYYY') : 'N/A'}
        </Typography>
      </Grid>
      <Grid item xs={12} container columnGap={1}>
        <Button
          size="small"
          color="secondary"
          sx={{
            fontSize: 10,
            fontWeight: 600,
          }}
          variant="contained"
          onClick={() => toggleUploadForm(true)}
        >
          Upload {btnTitle}
        </Button>
      </Grid>
    </Grid>
  );
};

const UpdatePaymentStatusItem: React.FC<{ complianceDocument: any }> = ({ complianceDocument }) => {
  const [showUploadForm, toggleUploadForm] = useState(false);

  if (showUploadForm)
    return (
      <Grid pt={1.3} md={10} lg={8}>
        <div className={theme.div1}>
          <Fieldset
            label={`For a due date ${dayjs(complianceDocument?.targetDate).format('DD/MM/YYYY')}`}
            variant="small"
          >
            {showUploadForm && (
              <Box px={0.6} py={0.5}>
                <UpdatePaymentStatus
                  complianceDocument={complianceDocument}
                  onCancel={() => toggleUploadForm(false)}
                />
              </Box>
            )}
          </Fieldset>
        </div>
      </Grid>
    );

  return (
    <Grid
      container
      md={10}
      lg={8}
      direction="column"
      rowGap={1}
      sx={{
        border: '1px solid lightgrey',
        borderRadius: '4px',
        px: 1,
        py: 1.3,
      }}
    >
      <Grid item container xs={12} fontSize={15} alignItems="center" columnGap={0.5}>
        <WatchLaterIcon fontSize="inherit" color="error" />
        <Typography fontSize={12} fontWeight={600} letterSpacing={1}>
          Payment requested on:
          {dayjs(complianceDocument?.paymentRequestDate).format('DD/MM/YYYY')}
        </Typography>
      </Grid>
      <Grid item xs={12} container columnGap={1}>
        <Button
          size="small"
          color="secondary"
          sx={{
            fontSize: 10,
            fontWeight: 600,
          }}
          variant="contained"
          onClick={() => toggleUploadForm(true)}
        >
          Update
        </Button>
      </Grid>
    </Grid>
  );
};

export default PendingDocumentsSection;

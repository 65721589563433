import { gql } from '@apollo/client';
import { ComplianceChecklist, ComplianceChecklistStatus, FrequencyEnum } from 'types';

export const COMPLIANCES_CHECKLIST_QUERY = gql`
  query GetComplianceChecklist($project: ID!, $filter: ComplianceChecklistFilter) {
    getComplianceChecklist(project: $project, filter: $filter) {
      _id
      referenceId
      docName
      frequency
      notificationLeadDays
      scheduleStarts
      scheduleEnds
      status
      docType {
        _id
        name
        isStandardFolder
        fileCount
        type
      }
      assignTo {
        _id
        empId
        firstName
        lastName
      }
      project {
        _id
        name
      }
      pendingDocuments {
        _id
        referenceId
        docStatus
        status
        remarks
        dueDate
      }
    }
  }
`;

export type CompliancesChecklistQueryResponse = {
  getComplianceChecklist: ComplianceChecklist[];
};

export type CompliancesChecklistQueryVariables = {
  project: string;
  filter?: {
    status?: ComplianceChecklistStatus[];
    frequency?: FrequencyEnum[];
    searchTerm?: string;
  };
};

export const GET_COMPLIANCE_CHECKLIST_BY_ID = gql`
  query GetComplianceChecklistDetailsById($id: ID!) {
    getComplianceChecklistDetailsById(_id: $id) {
      _id
      referenceId
      docName
      docType {
        _id
        name
        parentFolder {
          _id
        }
      }
      frequency
      notificationLeadDays
      assignTo {
        _id
        firstName
        empId
        lastName
      }
      project {
        _id
        name
      }
      scheduleStarts
      scheduleEnds
      status
      pendingDocuments {
        _id
        referenceId
        docStatus
        status
        remarks
        dueDate
      }
      uploadedDocuments {
        _id
        referenceId
        files {
          _id
          referenceId
          name
          path
          metadata
        }
        docStatus
        status
        createdAt
      }
    }
  }
`;

export type GetComplianceChecklistByIdResponse = {
  getComplianceChecklistDetailsById: ComplianceChecklist;
};

export type GetComplianceChecklistByIdVariables = {
  id: string;
};

import { NetworkStatus, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import {
  GET_EHS_CHECKLIST_BY_ID,
  GetEhsChecklistByIdResponse,
  GetEhsChecklistByIdVariables,
} from 'graphql/query/ehs';
import { useParams } from 'react-router-dom';

import EHSChecklistIncidentSection from 'components/EHS/Sections/EHSChecklistIncident';
import EhsChecklistDetailsSection from 'components/EHS/Sections/EhsDetails';
import EhsPendingDocumentsSection from 'components/EHS/Sections/EhsPendingDocuments';
import UploadedEhsDocumentsSection from 'components/EHS/Sections/UploadedEHSDocuments';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

const EhsChecklistDetailsPage = () => {
  const { ehsChecklistId = '' } = useParams<{ ehsChecklistId: string }>();

  const {
    data: ehsChecklist,
    networkStatus,
    refetch: refetchChecklist,
  } = useQuery<GetEhsChecklistByIdResponse, GetEhsChecklistByIdVariables>(GET_EHS_CHECKLIST_BY_ID, {
    variables: {
      id: ehsChecklistId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const loadingEhsChecklist =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!ehsChecklist;

  const shouldShowEHSUploadIncidentSection =
    ehsChecklist && ehsChecklist.getEhsChecklistDetailsById.docType.name === 'Incident Report';

  return (
    <Navbar goBackButtonConfig={{ title: 'EHS Details' }}>
      {loadingEhsChecklist ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid item container direction={'column'} xs={12} md={11} lg={9} xl={7} mb={5} rowGap={2.5}>
          <EhsChecklistDetailsSection ehsChecklist={ehsChecklist.getEhsChecklistDetailsById} />
          {shouldShowEHSUploadIncidentSection ? (
            <EHSChecklistIncidentSection ehsChecklist={ehsChecklist.getEhsChecklistDetailsById} />
          ) : (
            <EhsPendingDocumentsSection ehsChecklist={ehsChecklist.getEhsChecklistDetailsById} />
          )}
          <UploadedEhsDocumentsSection
            uploadedDocuments={ehsChecklist.getEhsChecklistDetailsById.uploadedDocuments}
            ehsChecklist={ehsChecklist.getEhsChecklistDetailsById}
            refetchChecklist={refetchChecklist}
          />
        </Grid>
      )}
    </Navbar>
  );
};

export default EhsChecklistDetailsPage;

import Section from 'components/Section';

import OneTimeComplianceChecklistTable from './OneTimeComplianceChecklistTable';

const OneTimeComplianceSection = ({ oneTimeComplianceDocs, loading }) => {
  return (
    <Section title="One Time Compliance Checklist" collapsible>
      <OneTimeComplianceChecklistTable
        oneTimeComplianceDocs={oneTimeComplianceDocs}
        loading={loading}
      />
    </Section>
  );
};

export default OneTimeComplianceSection;

import { NetworkStatus, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import {
  GET_COMPLIANCE_CHECKLIST_BY_ID,
  GetComplianceChecklistByIdResponse,
  GetComplianceChecklistByIdVariables,
} from 'graphql/query/complianceChecklist';
import { useParams } from 'react-router-dom';

import ComplianceChecklistDetailsSection from 'components/Compliances/Sections/ComplianceChecklistDetails';
import PendingDocumentsSection from 'components/Compliances/Sections/PendingDocumentsSection';
import UploadedComplianceDocumentsSection from 'components/Compliances/Sections/UploadedComplianceDocuments';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

const ComplianceChecklistDetailsPage = () => {
  const { complianceId = '' } = useParams<{ complianceId: string }>();

  const { data: complianceChecklist, networkStatus } = useQuery<
    GetComplianceChecklistByIdResponse,
    GetComplianceChecklistByIdVariables
  >(GET_COMPLIANCE_CHECKLIST_BY_ID, {
    variables: {
      id: complianceId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const loadingComplianceChecklist =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!complianceChecklist;

  return (
    <Navbar
      goBackButtonConfig={{
        title: ` ${complianceChecklist?.getComplianceChecklistDetailsById.project.name} | Compliance `,
        hideSeparator:true
      }}
    >
      {loadingComplianceChecklist ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid item container direction={'column'} xs={12} md={11} lg={9} xl={7} mb={5} rowGap={2.5}>
          <ComplianceChecklistDetailsSection
            complianceChecklist={complianceChecklist.getComplianceChecklistDetailsById}
          />
          <PendingDocumentsSection
            complianceChecklist={complianceChecklist.getComplianceChecklistDetailsById}
          />
          <UploadedComplianceDocumentsSection
            uploadedDocuments={
              complianceChecklist.getComplianceChecklistDetailsById.uploadedDocuments
            }
            complianceChecklist={complianceChecklist.getComplianceChecklistDetailsById}
          />
        </Grid>
      )}
    </Navbar>
  );
};

export default ComplianceChecklistDetailsPage;

import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { EhsDocumentAsNoDueVariables, MARK_EHS_DOCUMENTS_AS_NO_DUE } from 'graphql/mutation/ehs';
import React, { useState } from 'react';
import { EHSDocument } from 'types';

import TextField from 'components/Inputs/TextField';

const EhsMarkAsNoOverdueForm: React.FC<{
  ehsDocument: EHSDocument;
  onCancel: () => void;
  cb: () => void;
}> = ({ ehsDocument, onCancel, cb }) => {
  const [formState, setFormState] = useState<Record<string, any>>({});

  const [ehsDocumentsAsNoDue, { loading: loadingDocAsNoDue }] = useMutation<
    undefined,
    EhsDocumentAsNoDueVariables
  >(MARK_EHS_DOCUMENTS_AS_NO_DUE);

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    ehsDocumentsAsNoDue({
      variables: {
        id: ehsDocument._id,
        remarks: formState.remarks,
      },
      onCompleted: _ => cb(),
    });
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columnSpacing={1.5} rowGap={2}>
        <Grid item xs={12}>
          <TextField
            size="small"
            value={formState.remarks ?? ''}
            onChange={e => handleChange('remarks', e.target.value)}
            label="Remarks"
            required
          />
        </Grid>
        <Grid item xs={12} container columnGap={1}>
          <Button
            sx={{
              fontSize: 10,
              fontWeight: 600,
            }}
            size="small"
            variant="text"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <LoadingButton
            sx={{
              fontSize: 10,
              fontWeight: 600,
            }}
            variant="contained"
            type="submit"
            size="small"
            loading={loadingDocAsNoDue}
          >
            Submit
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default EhsMarkAsNoOverdueForm;

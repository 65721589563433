import { Grid } from '@mui/material';
import { useFormContext } from 'contexts/useDateRange';
import { useNavigate, useParams } from 'react-router-dom';

import Navbar from 'components/Navbar';

import SubcontractorsDetailsSection from './DetailsSection';
import SubcontractorsTableSection from './TableSection';

function SubcontractorsComplianceDetails() {
  const { subcontractorId = '' } = useParams<{
    subcontractorId: string;
  }>();

  const navigate = useNavigate();
  const { setFormState } = useFormContext();

  return (
    <Navbar
      goBackButtonConfig={{
        title: `${subcontractorId} Compliance`,
        onClick: () => {
          setFormState({ startDate: null, endDate: null });
          navigate(-1);
        },
      }}
    >
      <Grid item container direction={'column'} xs={12} md={11} lg={9} xl={7} mb={5} rowGap={2.5}>
        <Grid item>
          <SubcontractorsDetailsSection subcontractorId={subcontractorId} />
        </Grid>

        <Grid item>
          <SubcontractorsTableSection />
        </Grid>
      </Grid>
    </Navbar>
  );
}

export default SubcontractorsComplianceDetails;

import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { UPDATE_WK_SITE_INFO_MUTATION } from 'graphql/mutation/_compliance';
import { useEffect, useState } from 'react';

import { Select } from 'components/Inputs/Select';
import { DynamicTextField } from 'components/Inputs/TextField';

import { emailRegex } from 'utils/regexes';

import { COMPLIANCE_PEOPLE_STATUS_ENUM } from '../Subcontractors/SubcontractorsComplianceForm';

export const UpdateWKSiteComplianceForm = ({
  people,
  complianceMilestoneId,
  setToggleForm,
  refetch,
}) => {
  const [formState, setFormState] = useState<Record<string, any>>({});

  const [updateWKSiteComplianceContact, { loading }] = useMutation(UPDATE_WK_SITE_INFO_MUTATION);

  const handleChanges = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    updateWKSiteComplianceContact({
      variables: {
        input: {
          complianceMilestoneId,
          wksiteContact: {
            _id: people?._id,
            ...formState,
          },
        },
      },
      onCompleted: _ => {
        setToggleForm(false);
        refetch();
      },
    });
  };

  useEffect(() => {
    setFormState({
      name: people?.name,
      email: people?.email,
      status: people?.status,
    });
  }, [people]);

  return (
    <form
      style={{ maxHeight: '50vh', padding: '0px 20px 20px 20px' }}
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid item container columns={12} columnSpacing={2} rowGap={2} xs={12} my={2}>
        {people?.status === 'INACTIVE' ? (
          <Grid item xs={12}>
            <Select
              label="Status"
              value={formState?.status ?? ''}
              onChange={val => handleChanges('status', val)}
              options={Object.keys(COMPLIANCE_PEOPLE_STATUS_ENUM)?.map(o => ({
                label: o,
                value: COMPLIANCE_PEOPLE_STATUS_ENUM[o],
              }))}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={6}>
              <DynamicTextField
                value={formState.name ?? ''}
                onChange={e => handleChanges('name', e.target.value)}
                label="Name"
                type="text"
                fieldKey="name"
              />
            </Grid>
            <Grid item xs={6}>
              <DynamicTextField
                value={formState.email ?? ''}
                onChange={e => handleChanges('email', e.target.value)}
                label="Email"
                fieldKey="email"
              />
            </Grid>
          </>
        )}
      </Grid>

      <Grid item xs={12} mt={2} alignSelf={'flex-end'} textAlign={'right'}>
        <LoadingButton
          loading={loading}
          variant="contained"
          type="submit"
          disabled={!emailRegex.test(formState.email)}
        >
          Update
        </LoadingButton>
      </Grid>
    </form>
  );
};

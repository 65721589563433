import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { FormControl, Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LicenseProviderEnum } from 'types';

import { Select } from 'components/Inputs/Select';

const CREATE_MILESTONE_MUTATION = gql`
  mutation CreateComplianceMilestoneDetails($input: CreateComplianceMilestoneDetailsInput!) {
    createComplianceMilestoneDetails(input: $input) {
      _id
      bocwLicenceProvidedBy
      workStartDate
      workEndDate
    }
  }
`;

const UPDATE_MILESTONE_MUTATION = gql`
  mutation UpdateComplianceMilestoneDetails($input: UpdateComplianceMilestoneDetailsInput!) {
    updateComplianceMilestoneDetails(input: $input) {
      _id
      bocwLicenceProvidedBy
      workEndDate
    }
  }
`;

interface FormState {
  workStartDate: Dayjs | null;
  workEndDate: Dayjs | null;
  bocwLicenceProvidedBy: string;
  project: any;
}

interface MilestoneFormProps {
  formTitle: { btnText: string };
  milestoneDetails?: FormState | null;
  onSuccess: () => void;
  complianceMilestoneId?: string;
}

const MilestoneForm: React.FC<MilestoneFormProps> = ({
  formTitle,
  milestoneDetails = null,
  onSuccess,
  complianceMilestoneId,
}) => {
  const { projectId } = useParams<{ projectId: string }>();

  const [formState, setFormState] = useState<FormState>({
    workStartDate: null,
    workEndDate: null,
    bocwLicenceProvidedBy: '',
    project: null,
  });
  const [error, setError] = useState<string | null>(null);

  const [createMilestone, { loading: createLoading }] = useMutation(CREATE_MILESTONE_MUTATION);
  const [updateMilestone, { loading: updateLoading }] = useMutation(UPDATE_MILESTONE_MUTATION);

  useEffect(() => {
    if (milestoneDetails) {
      setFormState({
        workStartDate: milestoneDetails.workStartDate
          ? dayjs(milestoneDetails.workStartDate)
          : null,
        workEndDate: milestoneDetails.workEndDate ? dayjs(milestoneDetails.workEndDate) : null,
        bocwLicenceProvidedBy: milestoneDetails.bocwLicenceProvidedBy || '',
        project: milestoneDetails.project || null,
      });
    }
  }, [milestoneDetails, complianceMilestoneId]);

  const handleChanges = (fieldName: keyof FormState, value: any) => {
    if (fieldName === 'bocwLicenceProvidedBy') {
      setFormState(prev => ({
        ...prev,
        [fieldName]: value,
      }));
    } else {
      const dayjsValue = value ? dayjs(value) : null;
      setFormState(prev => ({
        ...prev,
        [fieldName]: dayjsValue,
      }));
    }
  };

  const handleSubmit = async () => {
    if (!formState.workStartDate || !formState.workEndDate) {
      setError('Both start date and end date are required.');
      return;
    }

    if (formState.workStartDate.isAfter(formState.workEndDate)) {
      setError('End date must be after start date.');
      return;
    }

    if (!formState.bocwLicenceProvidedBy) {
      setError('License provider is required.');
      return;
    }

    try {
      if (complianceMilestoneId) {
        const updateVariables = {
          input: {
            complianceMilestoneId,
            workEndDate: formState.workEndDate?.toISOString(),
            bocwLicenceProvidedBy: formState.bocwLicenceProvidedBy,
          },
        };
        await updateMilestone({ variables: updateVariables });
      } else {
        const createVariables = {
          input: {
            project: projectId,
            workStartDate: formState.workStartDate?.toISOString(),
            workEndDate: formState.workEndDate?.toISOString(),
            bocwLicenceProvidedBy: formState.bocwLicenceProvidedBy,
          },
        };

        await createMilestone({ variables: createVariables });
      }

      onSuccess();
    } catch (error) {
      console.error('Submission Error:', error);
      setError('An error occurred while submitting the form. Please try again.');
    }
  };

  return (
    <form
      style={{ maxHeight: '50vh', padding: '15px 20px' }}
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columns={12} columnSpacing={2} rowGap={2}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={formState.workStartDate}
                format="DD/MM/YYYY"
                onChange={val => handleChanges('workStartDate', val)}
                label="Select start date"
                disabled={!!complianceMilestoneId}
                disableFuture
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={formState.workEndDate}
                format="DD/MM/YYYY"
                onChange={val => handleChanges('workEndDate', val)}
                label="Select end date"
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
      </Grid>
      {error && (
        <Grid item xs={12} mt={1}>
          <span style={{ color: 'red' }}>{error}</span>
        </Grid>
      )}
      <Grid item xs={12} mt={2}>
        <Select
          disabled={!!complianceMilestoneId}
          label="BOCW License provided by"
          value={formState.bocwLicenceProvidedBy}
          onChange={val => handleChanges('bocwLicenceProvidedBy', val)}
          required
          options={Object.keys(LicenseProviderEnum).map(o => ({
            label: o,
            value: LicenseProviderEnum[o],
          }))}
        />
      </Grid>
      <Grid item xs={12} mt={2} alignSelf="flex-end" textAlign="right">
        <LoadingButton loading={createLoading || updateLoading} variant="contained" type="submit">
          {formTitle.btnText}
        </LoadingButton>
      </Grid>
    </form>
  );
};

export default MilestoneForm;

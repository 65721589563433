import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, Typography } from '@mui/material';
import { UPDATE_MONTHLY_COMPLIANCE_PAYMENT_STATUS } from 'graphql/mutation/_compliance';
import React from 'react';
import { ComplianceDocument } from 'types';

import { deleteDocumentCache } from './deleteDocumentCache';

const UpdatePaymentStatus: React.FC<{
  complianceDocument: ComplianceDocument;
  onCancel: () => void;
}> = ({ complianceDocument, onCancel }) => {
  const [updateMonthlyComplianceDocPaymentStatus, { loading }] = useMutation(
    UPDATE_MONTHLY_COMPLIANCE_PAYMENT_STATUS
  );

  const handleSubmit = () => {
    updateMonthlyComplianceDocPaymentStatus({
      variables: {
        id: complianceDocument?._id,
      },
      onCompleted: _ => {
        onCancel();
        deleteDocumentCache(complianceDocument?._id);
      },
    });
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columnSpacing={1.5} rowGap={2}>
        <Grid item xs={12}>
          <Typography variant="caption" fontWeight={600}>
            Do you want to Update Payment Status ?
          </Typography>
        </Grid>
        <Grid item xs={12} container columnGap={1}>
          <Button
            sx={{
              fontSize: 10,
              fontWeight: 600,
            }}
            size="small"
            variant="text"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <LoadingButton
            sx={{
              fontSize: 10,
              fontWeight: 600,
            }}
            variant="contained"
            type="submit"
            size="small"
            loading={loading}
          >
            Submit
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default UpdatePaymentStatus;

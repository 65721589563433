import AddIcon from '@mui/icons-material/Add';
import EditNoteIcon from '@mui/icons-material/EditNote';
import WarningIcon from '@mui/icons-material/Warning';
import { Typography } from '@mui/material';
import { useState } from 'react';

import LoadingIndicator from 'components/LoadingIndicator';
import { SimplePopup } from 'components/Popup';
import Section, { SectionDataProps } from 'components/Section';

import MilestoneForm from './MilestoneForm';

const MilestonesSection: React.FC<{
  milestoneDetails: any;
  refetch: () => void;
  loading: boolean;
}> = ({ milestoneDetails, loading, refetch }) => {
  const [toggleForm, setToggleForm] = useState(false);
  const [formTitle, setFormTitle] = useState({
    title: '',
    btnText: '',
  });

  const sectionData: SectionDataProps[] = [
    {
      label: 'Start Date',
      value: milestoneDetails?.workStartDate,
      type: 'DATE',
    },
    {
      label: 'End Date',
      value: milestoneDetails?.workEndDate,
      type: 'DATE',
    },
    {
      label: 'BOCW License Provider',
      value: milestoneDetails?.bocwLicenceProvidedBy,
      type: 'STRING',
    },
  ];
  const showDefaultInfo =
    milestoneDetails === null ||
    milestoneDetails?.workStartDate === null ||
    milestoneDetails?.workEndDate === null;
  return (
    <>
      {!loading ? (
        <Section
          endAdornment={
            showDefaultInfo && (
              <>
                <WarningIcon
                  fontSize="small"
                  sx={{
                    color: 'yellow',
                  }}
                />
                <Typography fontWeight={800}>No Information found</Typography>
              </>
            )
          }
          title="Milestones Information"
          collapsible
          defaultCollapsed
          data={showDefaultInfo ? [] : sectionData}
          action={
            <>
              {showDefaultInfo ? (
                <AddIcon
                  onClick={() => {
                    setToggleForm(true);
                    setFormTitle({
                      title: 'Add Milestone',
                      btnText: 'Add',
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: 'pointer' }}
                />
              ) : (
                <EditNoteIcon
                  onClick={() => {
                    setToggleForm(true);
                    setFormTitle({
                      title: 'Update Milestone',
                      btnText: 'Update',
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: 'pointer' }}
                />
              )}
            </>
          }
        >
          {showDefaultInfo && (
            <Typography variant="body2" sx={{ width: '100%', textAlign: 'center' }}>
              No Milestones added yet!.
            </Typography>
          )}
        </Section>
      ) : (
        <Section title="Milestones Information" collapsible defaultCollapsed>
          <LoadingIndicator />
        </Section>
      )}
      <SimplePopup
        onClose={() => {
          setToggleForm(false);
        }}
        open={toggleForm}
        title={formTitle.title}
        fullWidth
      >
        <MilestoneForm
          formTitle={formTitle}
          milestoneDetails={milestoneDetails}
          onSuccess={() => {
            setToggleForm(false);
            refetch();
          }}
          complianceMilestoneId={milestoneDetails?._id}
        />
      </SimplePopup>
    </>
  );
};

export default MilestonesSection;

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button, Divider, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import TextField from '../TextField';

type AddMetadataProps = {
  metadata: MetadataField[];
  setMetadata: (metadata: MetadataField[]) => void;
  heading?: string;
};

export type MetadataField = {
  key: string;
  value: string;
};

const Metadata: React.FC<AddMetadataProps> = ({
  metadata,
  setMetadata,
  heading = 'Other Details',
}) => {
  const [localData, setLocalData] = useState<MetadataField[]>([]);

  useEffect(() => {
    setLocalData(metadata);
  }, [metadata]);

  const addKeyPair = () => {
    setMetadata([...metadata, { key: ``, value: `` }]);
  };

  const onKeyChange = (index: number) => {
    return (key: string) => {
      const newFields = metadata;
      newFields[index] = { key: key, value: newFields[index].value };
      setMetadata(newFields);
    };
  };

  const onValueChange = (index: number) => {
    return (value: any) => {
      const newFields = metadata;
      newFields[index] = {
        key: newFields[index].key,
        value: value,
      };
      setMetadata(newFields);
    };
  };

  const deleteKeyPair = (index: number) => {
    return () => {
      setMetadata(metadata.filter((_, i) => i !== index));
    };
  };

  return (
    <Grid container direction="column" rowGap={1}>
      <Grid item xs={12}>
        <Divider>
          <Typography variant="caption" fontWeight={600}>
            {heading}
          </Typography>
        </Divider>
      </Grid>
      {localData.map((field, index) => {
        return (
          <DeletableElement key={index} onDelete={deleteKeyPair(index)}>
            <Field
              field={field}
              updateKey={onKeyChange(index)}
              updateValue={onValueChange(index)}
            />
          </DeletableElement>
        );
      })}
      <div>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          type="button"
          sx={{
            fontSize: 10,
          }}
          fullWidth
          onClick={addKeyPair}
        >
          <AddIcon fontSize="small" sx={{ mr: 0.4 }} />
          Add More
        </Button>
      </div>
    </Grid>
  );
};

type FieldProps = {
  field: MetadataField;
  updateKey: (key: string) => void;
  updateValue: (value: string) => void;
};

const Field: React.FC<FieldProps> = ({ field, updateKey, updateValue }) => {
  return (
    <Grid container alignItems="center" spacing={1.5} sx={{ width: '100%' }}>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Key"
          placeholder="Some key"
          size="small"
          required={!!field.value}
          defaultValue={field.key}
          fullWidth
          onChange={e => {
            updateKey(e.currentTarget.value);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          label="Value"
          placeholder="Some value"
          size="small"
          required={!!field.key}
          defaultValue={field.value}
          fullWidth
          onChange={e => {
            updateValue(e.currentTarget.value);
          }}
        />
      </Grid>
    </Grid>
  );
};

type DeletableElementProps = {
  onDelete: () => void;
  children?: React.ReactNode;
};

const DeletableElement: React.FC<DeletableElementProps> = ({ onDelete, children }) => {
  return (
    <Grid container alignItems="center">
      <Grid item xs>
        {children}
      </Grid>
      <Grid item>
        <IconButton type="button" onClick={onDelete} color="error">
          <DeleteOutlineIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Metadata;

import { Grid } from '@mui/material';
import { useFormContext } from 'contexts/useDateRange';
import { useNavigate } from 'react-router-dom';

import Navbar from 'components/Navbar';

import WoodkraftDetailsSection from './DetailsSection';
import WoodkraftTableSection from './TableSection';

function WoodkraftComplianceDetails() {
  const navigate = useNavigate();
  const { setFormState } = useFormContext();
  return (
    <Navbar
      goBackButtonConfig={{
        title: 'Woodkraft Compliance',
        onClick: () => {
          setFormState({ startDate: null, endDate: null });
          navigate(-1);
        },
      }}
    >
      <Grid item container direction={'column'} xs={12} md={11} lg={9} xl={7} mb={5} rowGap={2.5}>
        <Grid item>
          <WoodkraftDetailsSection />
        </Grid>

        <Grid item container direction="column" mt={3} xs={12}>
          <WoodkraftTableSection />
        </Grid>
      </Grid>
    </Navbar>
  );
}

export default WoodkraftComplianceDetails;

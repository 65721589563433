import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Add, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Radio, Typography } from '@mui/material';
import { ADD_WK_SITE_INFO_MUTATION } from 'graphql/mutation/_compliance';
import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MultiSelect } from 'components/Inputs/Select';
import TextField, { DynamicTextField } from 'components/Inputs/TextField';

const GET_ACTIVE_USERS_QUERY = gql`
  query GetUsers($filter: UserFilter) {
    getUsers(filter: $filter) {
      _id
      firstName
      lastName
      department
      email
    }
  }
`;

export const WKSiteComplianceForm = ({ formTitle, setToggleForm, refetch }) => {
  const [formState, setFormState] = useState<Record<string, any>>({
    fieldType: 'existing_officer',
  });

  const { projectId = '' } = useParams<{ projectId: string }>();

  const [getUsers, { data, loading: loadingActiveUsers }] =
    useLazyQuery<any>(GET_ACTIVE_USERS_QUERY);

  const handleChanges = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const [newRowItems, setNewRowItems] = useState([
    {
      name: '',
      email: '',
    },
  ]);

  const handleFormChanges = (index: number, key: string, value: any) => {
    const updatedSiteCompliance = newRowItems.map((siteCompliance, i) =>
      i === index ? { ...siteCompliance, [key]: value } : siteCompliance
    );
    setNewRowItems(updatedSiteCompliance);
  };

  const handleAdd = () => {
    setNewRowItems([...newRowItems, { name: '', email: '' }]);
  };

  const handleDelete = (index: number) => {
    const updatedSiteCompliance = newRowItems.filter((_, i) => i !== index);
    setNewRowItems(updatedSiteCompliance);
  };

  const selectedData = formState?.followupContact || [];
  const apiData = data?.getUsers || [];

  // Filter apiData based on selectedData
  const updatedArray = apiData?.filter(item => selectedData.includes(item?._id));

  // API
  const [addWKSiteComplianceContact, { loading }] = useMutation(ADD_WK_SITE_INFO_MUTATION);

  const handleSubmit = () => {
    if (formState.fieldType === 'existing_officer') {
      const transformedArray = updatedArray?.map(arr => ({
        email: arr.email,
        name: arr.firstName + ' ' + (arr?.lastName ?? ''),
      }));

      addWKSiteComplianceContact({
        variables: {
          input: {
            project: projectId,
            wksiteContacts: [...transformedArray],
          },
        },
        onCompleted: _ => {
          setToggleForm(false);
          refetch();
        },
      });
    }

    if (formState.fieldType === 'new_siteCompliance') {
      addWKSiteComplianceContact({
        variables: {
          input: {
            project: projectId,
            wksiteContacts: [...newRowItems],
          },
        },
        onCompleted: _ => {
          setToggleForm(false);
          refetch();
        },
      });
    }
  };

  return (
    <form
      style={{ maxHeight: '50vh', padding: '0px 20px 20px 20px' }}
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid item container columns={12} columnSpacing={2} rowGap={2} xs={12}>
        <Grid item container xs={12}>
          <Grid item xs={6}>
            <label
              htmlFor="existing_officer"
              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
              <Radio
                checked={formState.fieldType === 'existing_officer'}
                onChange={e => handleChanges('fieldType', e.target.value)}
                value="existing_officer"
                size="small"
                id="existing_officer"
              />
              <Typography fontWeight={600} id="existing_officer">
                Select existing officer
              </Typography>
            </label>
          </Grid>
          <Grid item xs={6}>
            <label
              htmlFor="new_siteCompliance"
              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
              <Radio
                checked={formState.fieldType === 'new_siteCompliance'}
                onChange={e => handleChanges('fieldType', e.target.value)}
                value="new_siteCompliance"
                id="new_siteCompliance"
                size="small"
              />
              <Typography fontWeight={600}>Add New </Typography>
            </label>
          </Grid>
        </Grid>

        {formState.fieldType === 'existing_officer' && (
          <>
            <Grid item container xs={12}>
              <MultiSelect
                label="Select contact persons"
                value={formState.followupContact ?? []}
                handleChange={val => handleChanges('followupContact', val)}
                // disabled={!!!formState.lead?._id}
                options={
                  data?.getUsers.map(lp => ({
                    label:
                      lp.firstName +
                      ' ' +
                      (lp?.lastName ?? '') +
                      ' ' +
                      `( ${lp?.department ?? 'N/A'} )`,
                    value: lp._id,
                  })) ?? []
                }
                fetchConfig={{
                  fetchFn: getUsers,
                  loading: loadingActiveUsers,
                  variables: {
                    filter: {
                      status: 'ACTIVE',
                    },
                  },
                }}
              />
            </Grid>
            {updatedArray.length > 0 && (
              <Typography mx={3} fontWeight={600}>
                Selected contacts
              </Typography>
            )}
            <Grid
              item
              xs={12}
              sx={{
                maxHeight: '50px',
                height: updatedArray.length > 0 ? '50px' : 0,
                width: '100%',
                overflowY: 'auto',
                // backgroundColor: 'red',
              }}
            >
              {updatedArray.length > 0 &&
                updatedArray?.map(item => (
                  <Grid
                    item
                    container
                    xs={12}
                    key={item._id}
                    justifyContent={'space-between'}
                    rowGap={2}
                    mx={3}
                  >
                    <Grid item xs={6}>
                      <Typography>
                        {item?.firstName +
                          ' ' +
                          (item?.lastName ?? '') +
                          ' ' +
                          `( ${item?.department ?? 'N/A'} )`}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{item?.email}</Typography>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </>
        )}

        {formState.fieldType === 'new_siteCompliance' &&
          newRowItems.map((item, index) => (
            <Fragment key={index}>
              <Grid item container xs={12} justifyContent={'space-between'}>
                <Typography variant="body2" color="initial">
                  Person {index + 1}
                </Typography>
                {newRowItems.length > 1 ? (
                  <Grid item>
                    <Add
                      fontSize="small"
                      key={index}
                      onClick={handleAdd}
                      sx={{ cursor: 'pointer' }}
                    />
                    <Delete
                      fontSize="small"
                      key={index}
                      onClick={() => handleDelete(index)}
                      sx={{ cursor: 'pointer' }}
                    />
                  </Grid>
                ) : (
                  <Grid item>
                    <Add
                      fontSize="small"
                      key={index}
                      onClick={handleAdd}
                      sx={{ cursor: 'pointer' }}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item container xs={12} columnSpacing={2}>
                <Grid item xs={6}>
                  <Box sx={{ mb: 1, minHeight: '70px' }}>
                    {' '}
                    {/* Ensures consistent height */}
                    <DynamicTextField
                      value={item.name ?? ''}
                      onChange={e => handleFormChanges(index, 'name', e.target.value)}
                      label="Name"
                      required
                      fieldKey="name"
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ mb: 1, minHeight: '70px' }}>
                    {' '}
                    <DynamicTextField
                      value={item.email ?? ''}
                      onChange={e => handleFormChanges(index, 'email', e.target.value)}
                      label="Email"
                      required
                      fieldKey="email"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Fragment>
          ))}
      </Grid>

      <Box sx={{ textAlign: 'right' }}>
        <LoadingButton loading={loading} variant="contained" type="submit">
          {formTitle.btnText}
        </LoadingButton>
      </Box>
    </form>
  );
};

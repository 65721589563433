import { gql } from '@apollo/client';
import { DOC_SCAN_FRAGMENT } from 'graphql/fragments/compliance';

export const GET_COMPLIANCE_DETAILS_QUERY = gql`
  query GetComplianceDetails($project: ID!) {
    getComplianceDetails(project: $project) {
      complianceMilestoneContact {
        _id
        referenceId
        workStartDate
        workEndDate
        bocwLicenceProvidedBy
        customerContacts {
          _id
          name
          email
          status
        }
        wkSiteComplianceContacts {
          _id
          name
          email
          status
        }
        subContractorInfo {
          _id
          name
          workStartDate
          workEndDate
          status
        }
        project {
          referenceId
          _id
          name
          status
          startDate
          endDate
          isLeadOrProject
          lead {
            name
            referenceId
            _id
            company {
              name
              referenceId
              type
              status
              _id
            }
          }
        }
      }
      oneTimeComplianceDocs {
        _id
        referenceId
        docName
        docType
        docDescription
        startDate
        appliedDate
        endDate
        finalDocScan {
          ...DocScanFragment
        }
        applicationDocScan {
          ...DocScanFragment
        }
        currentStatus
      }
    }
  }
  ${DOC_SCAN_FRAGMENT}
`;

// export const GET_ONE_TIME_COMPLIANCE_DOCS_DETAILS_QUERY = gql`
//   query GetOneTimeComplianceDocById($id: ID!) {
//     getOneTimeComplianceDocById(_id: $id) {
//       _id
//       referenceId
//       docName
//       docType
//       docDescription
//       startDate
//       appliedDate
//       endDate
//       currentStatus
//       licEffectiveDate
//       licExpiryDate
//       dateLicSentForCustApproval
//       dateLicApprovedByCust
//       licApprovalStatus
//       finalDocScan {
//         ...DocScanFragment
//         createdAt
//       }
//       applicationDocScan {
//         ...DocScanFragment
//         createdAt
//       }
//       licApprovalDoc {
//         ...DocScanFragment
//         createdAt
//       }
//       revalidations {
//         startDate
//         appliedDate
//         endDate
//         revalidationStatus
//         licEffectiveDate
//         licExpiryDate
//         dateLicSentForCustApproval
//         dateLicApprovedByCust
//         licApprovalStatus
//       }
//       project {
//         _id
//         name
//       }
//     }
//   }
//   ${DOC_SCAN_FRAGMENT}
// `;

export const GET_ONE_TIME_COMPLIANCE_DOCS_DETAILS_QUERY = gql`
  query GetOneTimeComplianceDocById($id: ID!) {
    getOneTimeComplianceDocById(_id: $id) {
      _id
      referenceId
      docName
      docType
      docDescription
      startDate
      appliedDate
      endDate
      currentStatus
      licEffectiveDate
      licExpiryDate
      dateLicSentForCustApproval
      dateLicApprovedByCust
      licApprovalStatus
      createdAt
      updatedAt
      revalidations {
        startDate
        appliedDate
        endDate
        revalidationStatus
        licEffectiveDate
        licExpiryDate
        dateLicSentForCustApproval
        dateLicApprovedByCust
        licApprovalStatus
        createdAt
        updatedAt
        applicationDocScan {
          _id
          referenceId
          name
          path
          metadata
          isStaticFile
          createdAt
          shortPath
          folder {
            _id
            referenceId
            name
            type
            parentFolder {
              _id
            }
          }
        }
        finalDocScan {
          _id
          referenceId
          name
          path
          metadata
          isStaticFile
          createdAt
          shortPath
          folder {
            _id
            referenceId
            name
            type
            parentFolder {
              _id
            }
          }
        }
        licApprovalDoc {
          _id
          referenceId
          name
          path
          metadata
          isStaticFile
          createdAt
          shortPath
          folder {
            _id
            referenceId
            name
            type
            parentFolder {
              _id
            }
          }
        }
      }
      finalDocScan {
        _id
        referenceId
        name
        path
        metadata
        isStaticFile
        createdAt
        shortPath
        folder {
          _id
          referenceId
          name
          type
          parentFolder {
            _id
          }
        }
      }
      applicationDocScan {
        _id
        referenceId
        name
        path
        metadata
        isStaticFile
        createdAt
        shortPath
        folder {
          _id
          referenceId
          name
          type
          parentFolder {
            _id
          }
        }
      }
      latestCompletedRevalidation {
        startDate
        appliedDate
        endDate
        revalidationStatus
        licEffectiveDate
        licExpiryDate
        dateLicSentForCustApproval
        dateLicApprovedByCust
        licApprovalStatus
        createdAt
        applicationDocScan {
          _id
          referenceId
          name
          path
          metadata
          isStaticFile
          createdAt
          shortPath
          folder {
            _id
            referenceId
            name
            type
            parentFolder {
              _id
            }
          }
        }
        finalDocScan {
          _id
          referenceId
          name
          path
          metadata
          isStaticFile
          createdAt
          shortPath
          folder {
            _id
            referenceId
            name
            type
            parentFolder {
              _id
            }
          }
        }
        licApprovalDoc {
          _id
          referenceId
          name
          path
          metadata
          isStaticFile
          createdAt
          shortPath
          folder {
            _id
            referenceId
            name
            type
            parentFolder {
              _id
            }
          }
        }
        _id
      }
      licApprovalDoc {
        _id
        referenceId
        name
        path
        metadata
        isStaticFile
        createdAt
        shortPath
        folder {
          _id
          referenceId
          name
          type
          parentFolder {
            _id
          }
        }
      }
      latestPendingRevalidation {
        startDate
        appliedDate
        endDate
        revalidationStatus
        licEffectiveDate
        licExpiryDate
        dateLicSentForCustApproval
        dateLicApprovedByCust
        licApprovalStatus
        createdAt
        applicationDocScan {
          _id
          referenceId
          name
          path
          metadata
          isStaticFile
          createdAt
          updatedAt
          shortPath
          folder {
            _id
            referenceId
            name
            type
            parentFolder {
              _id
            }
          }
        }
        finalDocScan {
          _id
          referenceId
          name
          path
          metadata
          isStaticFile
          createdAt
          updatedAt
          shortPath
          folder {
            _id
            referenceId
            name
            type
            parentFolder {
              _id
            }
          }
        }
        licApprovalDoc {
          _id
          referenceId
          name
          path
          metadata
          isStaticFile
          createdAt
          shortPath
          folder {
            _id
            referenceId
            name
            type
            parentFolder {
              _id
            }
          }
        }
        _id
      }
      project {
        _id
        name
      }
    }
  }
`;

export const GET_WOODKRAFT_MONTHLY_COMPLIANCE_DETAILS_QUERY = gql`
  query GetMonthlyComplianceDocs($project: ID!, $monthYearRange: [String]) {
    getMonthlyComplianceDocs(project: $project, monthYearRange: $monthYearRange) {
      _id
      referenceId
      monthYear
      docName
      docType
      docDescription
      startDate
      paymentRequestDate
      paymentCompletedDate
      endDate
      targetDate
      currentStatus
      providedBy
      paymentBy
      finalDocScan {
        ...DocScanFragment
      }
      applicationDocScan {
        ...DocScanFragment
      }
      project {
        _id
        name
        referenceId
      }
    }
  }
  ${DOC_SCAN_FRAGMENT}
`;

export const GET_WOODKRAFT_MONTHLY_COMPLIANCE_DOC_DETAILS_QUERY = gql`
  query GetMonthlyComplianceDocById($id: ID!) {
    getMonthlyComplianceDocById(_id: $id) {
      _id
      referenceId
      monthYear
      docName
      docType
      docDescription
      startDate
      paymentRequestDate
      paymentCompletedDate
      endDate
      targetDate
      finalDocScan {
        ...DocScanFragment
      }
      applicationDocScan {
        ...DocScanFragment
      }
      currentStatus
      providedBy
      paymentBy
      project {
        _id
        name
      }
    }
  }
  ${DOC_SCAN_FRAGMENT}
`;

export const GET_SUBCONTRACTOR_MONTHLY_COMPLIANCE_DETAILS_QUERY = gql`
  query GetSubcontractorMonthlyComplianceDocs(
    $subcontractor: String!
    $project: ID!
    $monthYearRange: [String]
  ) {
    getSubcontractorMonthlyComplianceDocs(
      subcontractor: $subcontractor
      project: $project
      monthYearRange: $monthYearRange
    ) {
      _id
      referenceId
      subcontractor
      monthYear
      docName
      docType
      docDescription
      startDate
      endDate
      targetDate
      currentStatus
      finalDocScan {
        ...DocScanFragment
      }
      project {
        _id
        name
        referenceId
      }
    }
  }
  ${DOC_SCAN_FRAGMENT}
`;

export const GET_SUBCONTRACTOR_MONTHLY_COMPLIANCE_DOC_DETAILS_QUERY = gql`
  query GetSubcontractorMonthlyComplianceDocById($id: ID!) {
    getSubcontractorMonthlyComplianceDocById(_id: $id) {
      _id
      referenceId
      subcontractor
      monthYear
      docName
      docType
      docDescription
      startDate
      endDate
      targetDate
      project {
        _id
      }
      finalDocScan {
        _id
        name
        path
        referenceId
        folder {
          _id
          name
          parentFolder {
            _id
          }
        }
      }
      currentStatus
    }
  }
`;

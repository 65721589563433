import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, IconButton } from '@mui/material';
import dayjs from 'dayjs';
import { Link, useParams } from 'react-router-dom';
import { ComplianceCurrentStatusEnum } from 'types';

import Section from 'components/Section';
import DataGridTable from 'components/Table';

const UploadedComplianceDocumentsSection: React.FC<{
  complianceChecklist: any;
}> = ({ complianceChecklist }) => {
  const { projectId = '' } = useParams<{ projectId: string }>();
  if (complianceChecklist?.currentStatus === ComplianceCurrentStatusEnum.STARTED) {
    return null;
  }
  const files = [complianceChecklist?.finalDocScan]?.filter(item => item !== null);

  return (
    <Section title="Task History" collapsible>
      <DataGridTable
        columns={[
          {
            field: 'referenceId',
            headerName: 'ID',
            minWidth: 100,
            renderCell: params => (
              <Link
                to={`/documents/PROJECT/${projectId}/${complianceChecklist.finalDocScan?.folder?.parentFolder?._id}/${complianceChecklist.finalDocScan.folder._id}/${params.row?._id}`}
              >
                {params.value}
              </Link>
            ),
          },
          {
            field: 'name',
            headerName: 'Name',
            minWidth: 200,
            flex: 1,
          },
          {
            field: 'createdAt',
            headerName: 'Uploaded At',
            minWidth: 200,
            flex: 1,
            valueFormatter: (val: any) =>
              dayjs(val?.createdAt).isValid() ? dayjs(val?.createdAt).format('DD MMM YYYY') : 'N/A',
          },
          {
            field: 'actions',
            headerName: 'Actions',
            minWidth: 100,
            renderCell: params => (
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', pt: 1 }}>
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => window.open(params.row?.path, '_blank')}
                >
                  <FileDownloadIcon />
                </IconButton>
              </Box>
            ),
          },
        ]}
        rows={files}
        getRowId={row => row?._id || row?.folder?._id || `${row?.__typename}-${Math.random()}`}
        hideFooterPagination
        sx={{
          height: '40vh',
          maxWidth: '80vw',
        }}
      />
    </Section>
  );
};

export default UploadedComplianceDocumentsSection;

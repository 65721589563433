import { Link } from 'react-router-dom';
import { COMPLIANCE_DOC_NAMES, ComplianceCurrentStatusEnum } from 'types';

import DataGridTable from 'components/Table';

import { getEnumKeyFromValue } from 'utils/transformFn';

import theme from './theme.module.scss';

const SubcontractorComplianceChecklistTable: React.FC<{
  complianceDetails?: any;
  loading: boolean;
  startDate: any;
  endDate: any;
}> = ({ complianceDetails, loading, startDate, endDate }) => {
  return (
    <DataGridTable
      sx={{
        maxWidth: '60vw',
        height: '450px',
        minHeight: '400px',
        mx: 'auto',
        mt: 0.5,
      }}
      columns={[
        {
          field: 'referenceId',
          headerName: 'Reference Id',
          minWidth: 120,
          renderCell: params =>
            params.row.currentStatus === ComplianceCurrentStatusEnum.CREATED ? (
              params.value
            ) : (
              <Link to={`${params.row._id}?startDate=${startDate}&endDate=${endDate}`}>
                {params.value}
              </Link>
            ),
        },
        {
          field: 'docType',
          headerName: 'Acts (Subfolder)',
          minWidth: 150,
        },
        {
          field: 'docName',
          headerName: 'Doc Name',
          valueFormatter: (val: any) => getEnumKeyFromValue(COMPLIANCE_DOC_NAMES, val),
          minWidth: 170,
        },
        {
          field: 'monthYear',
          headerName: 'Month',
          minWidth: 150,
        },
        { field: 'currentStatus', headerName: 'Status', minWidth: 150 },
      ]}
      getRowId={row => row._id}
      rows={complianceDetails}
      loading={loading}
      hideFooterPagination
      getRowClassName={params => {
        switch (params.row.currentStatus) {
          case ComplianceCurrentStatusEnum.CREATED:
            return theme['row-created'];
          case ComplianceCurrentStatusEnum.STARTED:
            return theme['row-started'];
          case ComplianceCurrentStatusEnum.APPLIED:
            return theme['row-applied'];
          case ComplianceCurrentStatusEnum.COMPLETED:
            return theme['row-completed'];
          default:
            return theme['row-default'];
        }
      }}
    />
  );
};

export default SubcontractorComplianceChecklistTable;

import { gql } from '@apollo/client';

export const DOC_SCAN_FRAGMENT = gql`
  fragment DocScanFragment on File {
    _id
    referenceId
    name
    path
    metadata
    folder {
      _id
      referenceId
      name
      type
      parentFolder {
        _id
      }
    }
  }
`;

import { NetworkStatus, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { GET_COMPLIANCE_DETAILS_QUERY } from 'graphql/query/compliance';
import { useParams } from 'react-router-dom';

import OneTimeComplianceDetailsSection from '../FileSections/OneTime/OneTimeComplianceDetailsSection';
import ContactsDetailsSections from './ContactsSection';
import MonthlyTaskSection from './MonthlyTaskSection';
import OneTimeComplianceSection from './OneTimeCompliance';

const ComplianceDetailsSections = () => {
  const { projectId = '' } = useParams<{ projectId: string }>();

  const {
    data,
    networkStatus: complianceNetworkStatus,
    refetch,
  } = useQuery<any>(GET_COMPLIANCE_DETAILS_QUERY, {
    variables: { project: projectId },
    notifyOnNetworkStatusChange: true,
  });

  const {
    getComplianceDetails: { oneTimeComplianceDocs = null, complianceMilestoneContact = {} } = {},
  } = data || {};

  const { workStartDate, workEndDate, subContractorInfo } = complianceMilestoneContact || {};

  const showSubContractorInfo = Boolean(
    subContractorInfo?.length > 0 &&
      subContractorInfo?.some((item: any) => item.status === 'ACTIVE')
  );

  const showWoodkraftInfo =
    complianceMilestoneContact != null && workStartDate != null && workEndDate != null;

  const loading =
    complianceNetworkStatus === NetworkStatus.loading ||
    complianceNetworkStatus === NetworkStatus.setVariables ||
    !!!data;

  return (
    <Grid item container direction="column" xs={12} md={11} lg={9} xl={6} rowGap={2.5}>
      <Grid item>
        <OneTimeComplianceSection oneTimeComplianceDocs={oneTimeComplianceDocs} loading={loading} />
      </Grid>
      <Grid item>
        <ContactsDetailsSections
          complianceMilestoneContactDetails={complianceMilestoneContact}
          loading={loading}
          refetch={refetch}
        />
      </Grid>
      <Grid item>
        <MonthlyTaskSection
          woodkraftInfo={complianceMilestoneContact}
          showSubContractorInfo={showSubContractorInfo}
          showWoodkraftInfo={showWoodkraftInfo}
          subContractorInfo={subContractorInfo}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default ComplianceDetailsSections;
